@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints";

.c-article-card {
  &--big {
    display: flex;
    border: 2px solid #ececec;
    height: 397px;
    // max-height: 397px;
    background-color: #fff;
    overflow: hidden;
    .c-article-card {
      &__content {
        flex: 0 0 43%;

        @media (min-width: 767px) {
          max-width: 43%;
        }

        padding: 25px;
        padding-bottom: 100px;
        position: relative;
        .bttn {
          left: 25px;
          bottom: 35px;
        }
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "SansCond", Arial, sans-serif;
        font-weight: bold;
        font-size: 20px;
        color: $brand-color-green;
        margin-bottom: 50px;
        .category--basic {
          margin-bottom: 0;
        }
      }
      &__img {
        flex: 0 0 57%;
        @media (min-width: 767px) {
          max-width: 57%;
        }

        max-height: 397px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    @include respond-below(md) {
      flex-wrap: wrap;
      height: auto;
      .c-article-card {
        &__content,
        &__img {
          flex: 0 0 100%;
        }
        &__img {
          order: -1;
        }
      }
    }
  }
}

.slider-slide {
  @include respond-below(lg) {
    .c-article-card {
      &--big {
        .c-article-card {
          &__img {
            flex: 0 0 40%;
          }
          &__content {
            flex: 0 0 60%;
          }
        }
      }
    }
  }
}

.c-article__date {
  color: #808080;
}

.c-article__category {
  margin-right: -30px;

  .category-label--68 {
    span {
      background-color: #e92b5c;
    }
  }
  span {
    background-color: #00965e;
    border-radius: 2px 0 0 2px;
    font-weight: 300;
  }
}
