.prf-page-template {
  overflow-x: hidden;

  .prf-go-back-btn {
    z-index: 10;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0.875rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s opacity;
    align-items: center;
    font-weight: 300;

    &:hover {
      opacity: 0.6;
    }

    svg {
      color: #00965e;
      margin-right: 0.5rem;
      margin-top: -4px;
    }

    @media (max-width: 767px) {
      bottom: 0;
      top: unset;
      left: 0;
      right: 0;
      justify-content: center;
    }
  }

  .list-prf {
    @media (max-width: 767px) {
      button {
        margin: 0 auto;
      }
    }

    &.list-prf--isReverse {
      @media (max-width: 767px) {
        .row {
          flex-direction: column-reverse;
        }
      }
    }
  }
}