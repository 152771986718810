.agroraport {
  &__logo {
    margin-bottom: 53px;
    svg {
      max-width: 410px;
      display: block;
      margin: 0 auto 30px;
    }
  }
}
