.cft-henhouses-header {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;

  & > div {
    display: inline-flex;
    flex: 1;
    width: 100%;
    border-bottom: 8px solid #abacac;
    justify-content: center;
    color: #abacac;
    margin: 0 0.1rem;

    &.isActive {
      color: #00965e;
      border-color: #00965e;
      cursor: pointer;
    }
  }
}
