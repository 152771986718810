.cft-unit-field {
  width: 100%;
  &__input {
    width: 100%;
    position: relative;
    border: 2px solid #00965e;
    input {
      border: none;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #2e3033;
      outline: none;
      line-height: 1.5rem;
      font-weight: 300;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__units {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    padding: 3px;

    span {
      transition: background-color 0.3s;
      height: 30px;
      min-width: 30px;
      display: inline-flex;
      background-color: #d7d7d7;
      font-size: 0.813rem;
      border-radius: 3px;
      justify-content: center;
      align-items: center;
      margin: 2px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &.active {
        color: #fff;
        background-color: #00965e;
      }

      &:hover {
        cursor: pointer;
        transition: opacity 0.3s;
        opacity: 0.6;
      }
    }
  }

  &.cft-unit-field--isAlternative {
    .cft-unit-field {
      &__input {
        border-color: #d6d6d6;
        border-radius: 3px;

        input {
          padding: 1rem 1rem;
          line-height: 1rem;
          font-weight: 400;
        }
      }

      &__units {
        display: flex;
        align-items: center;

        span {
          background-color: #eae9eb;
          color: #2e3033;
          margin: 4px;

          &.active {
            background-color: #cbe9df;
          }
        }
      }
    }
  }

  &.cft-unit-field--isInvalid {
    .cft-unit-field__input {
      border-color: #fd0059;
      border-width: 3px;
    }
  }
}
