@import "src/styles/_colors.scss";

.credit-container {
  .credit-completed-summary-container {
    text-align: center;

    .credit-summary-grid {
      display: flex;
      justify-content: space-between;

      > div {
        text-align: left;
        width: 50%;
        min-width: 142px;
      }

      > div:nth-child(2) {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
          align-self: baseline;
        }
      }

      @media (max-width: 576px) {
        flex-wrap: wrap;

        > div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .summary-button-container {
      margin-top: 30px;
    }
  }
}
