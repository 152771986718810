.cft-select-field {
  width: 100%;

  &__input {
    width: 100%;
    position: relative;
    border: 2px solid #00965e;

    input {
      border: none;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #2e3033;
      outline: none;
      line-height: 1.5rem;
      font-weight: 300;
    }

    & > div:nth-child(3) {
      z-index: 10;
    }
  }

  &.cft-select-field--isInvalid {
    .cft-select-field__input {
      border-color: #fd0059;
      border-width: 3px;
    }
  }

  div[class*="-MenuList"] {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid white;
      background-color: #00965e;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  &.cft-select-field--alternative {
    .cft-select-field {
      &__input {
        border-color: #d6d6d6;
        padding: 0.137rem 0.25rem;

        input {
          font-weight: 400;
        }
      }
    }
  }

  &.cft-select-field--rounded {
    .cft-select-field {
      border-radius: 3px;
      &__input {
        border-radius: 3px;
        input {
          border-radius: 3px;
        }
      }
    }
  }
}
