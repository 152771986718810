.ReadMoreLess {
  width: 100%;
  button {
    color: #00965e;
    border: none;

    svg {
      color: #00965e;
      margin-left: 5px;
      width: 18px;
      height: auto;
      path {
        stroke: #00965e;
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
