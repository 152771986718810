.range-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
  margin-top: 5px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & {
      padding: 0 15px !important;
    }
  }

  @mixin ie-edge-range-input-hacks() {
    & {
      height: auto;
      background: transparent;
      margin: -15px 0;
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background: #d3d3d3;
      height: 2px;
    }

    &::-ms-track {
      height: 17px;
      background: transparent;
      border-color: transparent;
      border-width: 7px 0;
      color: transparent;
    }

    &::-ms-thumb {
      cursor: pointer;
      width: 30px;
      height: 17px;
      background: url("../../../assets/icons/slider.svg");
    }
  }

  .range-input--input {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 1;

    // Range input Edge fix
    @supports (-ms-ime-align: auto) {
      @include ie-edge-range-input-hacks();
    }

    // Range input IE fix
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @include ie-edge-range-input-hacks();
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      width: 30px;
      height: 17px;
      background: url("../../../assets/icons/slider.svg");
    }

    &::-moz-range-thumb {
      cursor: pointer;
      width: 30px;
      height: 17px;
      background: url("../../../assets/icons/slider.svg");
    }
  }
}
