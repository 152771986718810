.collapser-button {
  display: block;
  background: transparent;
  border: 0;
  padding: 0;

  cursor: pointer;
  font-family: Sans;
  font-size: 13px;
  color: #00965e;
  letter-spacing: 0;
}
