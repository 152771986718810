@import "src/styles/_colors.scss";

$containerWidth: 586px;
$verticalPadding: 8px;
$horizontalPadding: 40px;
$verticalPaddingAdditionalInfo: 114px;
$horizontalPaddingAdditionalInfo: 30px;

.calculator-page {
  height: inherit;
  padding: 0;

  .breadcrumbs {
    margin-top: 2rem;
  }

  label {
    white-space: nowrap;
  }

  .typography--h1 {
    color: $brand-color-green;
    margin-bottom: 32px;
    width: 100%;
    max-width: $containerWidth;
    text-align: center;
  }

  .calculator-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $horizontalPadding $verticalPadding;
  }

  &.calculator-page__is-separate {
    h1.typography--h1 {
      display: none;
    }
    .next-step-button-container {
      background-color: transparent;
    }

    .investment-container {
      padding-bottom: 0;
    }

    .offer-disclaimer {
      display: none;
    }
  }
}

.additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $containerWidth;
  min-height: 200px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-top: 0px;
  margin: 0 auto;
  position: relative;
  top: -50px;

  .offer-disclaimer {
    padding: $horizontalPaddingAdditionalInfo $verticalPaddingAdditionalInfo;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 300;
    margin-top: -20px;
    text-align: left;

    &--pink {
      font-family: "Sans", Arial, sans-serif;
      color: #e92b5c;
    }
  }

  .next-step-button-container {
    background-color: $brand-color-green;
    max-width: $containerWidth;
    width: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    a,
    div {
      min-width: 146px;
    }

    a:last-child {
      margin-left: 10px;
    }

    @media (max-width: 576px) {
      flex-wrap: wrap;

      a,
      div,
      a:last-child {
        margin: 5px;
      }
    }
  }
}
