.ArticlesContainerGrid {
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
}

.ArticlesLoadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0;
}

.ArticlesLoader {
  width: 100%;
  height: 100px;
  position: relative;
}

.EmptyArticlesState {
  padding: 40px;
  text-align: center;
  width: 100%;
}
