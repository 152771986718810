.intro-page {
  max-width: 570px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .cft-button {
    @media (min-width: 768px) {
      min-width: 273px;
    }
  }
}


.water-source-change-btn {
  color: #00965e;
  border: none;
  text-decoration: underline;
  position: absolute;
  left: 10px;
  top: 100px;

  &:focus {
    outline: none;
  }
}

.water-footprint-week-scope {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
