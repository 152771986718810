.cft-tank {
  align-self: center;
}

@media (max-width: 767px) {
  .cft-tank {
    display: flex;
    flex-direction: column;
  }
}
