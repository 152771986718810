.cft-form-control {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    &.cft-form-control--full {
      width: 100%;
    }
    &.cft-form-control--medium {
      width: 690px;
    }
    &.cft-form-control--small {
      width: 370px;
    }

    margin-bottom: 2.5rem;
  }

  &.cft-form-control--withoutMarginBottom {
    margin-bottom: 0;
  }
  &.cft-form-control--withoutMarginTop {
    margin-top: -1rem;
  }
  &.cft-form-control--alignLeft {
    text-align: left;
  }

  &.cft-form-control--isAlternative {
    margin-bottom: 1.5rem;
  }
}
