@import "src/styles/_colors.scss";

.registration-benefits-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pros-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: $black;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 0 10px;
    }
  }

  // .row {
  //     margin: 8px 0;
  // }
  img {
    height: 200px;
    width: 70%;
    object-fit: cover;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
}

.weather-content__provider {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: unset;
    height: auto;
    width: 250px;
    margin: 0 0 0.5rem 1rem;
  }
  p {
    font-size: 1.2rem;
  }
}
