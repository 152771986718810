.progress-dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 1rem 0 0 0;
    }

    .cft-progress-circle {
      margin: 1rem auto 1.5rem auto;
    }

  }

  &.water-footprint {
    @media (min-width: 768px) and (max-width: 990px) {
      justify-content: space-around;
    }
  }

  @media (max-width: 767px) {
    &__box {
      flex-direction: row;
      flex-grow: 1;
      width: 100%;

      p {
        font-size: 0.96rem;
        margin: 0 1rem 0 0;
      }
    }

    &__box :nth-child(1) {
      // progress circle
      order: 2;
    }

    &__box :nth-child(2) {
      // svg
      order: 3;
    }

    &__box :nth-child(3) {
      // paragraph
      order: 1;
      width: 33.333%;
    }
  }
}

.progress-dashboard-header {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 !important;

  @media (min-width: 768px) {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }

  @media (max-width: 767px) {
    padding-left: 1rem;

  }
}
