@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints";

.weather-widget {
  font-family: "SansCond", Arial, sans-serif;
  display: inline-flex;
  flex-direction: column;
  // margin: 50px 0;
  background-color: white;
  overflow-y: hidden;
  // min-width: 580px;
  // margin-bottom: 20px;
  min-width: 580px;

  @media (max-width: 767px) {
    width: 100%;
    min-width: unset;
  }
  &__heading__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .weather-widget_single {
      background-color: rgba(0, 0, 0, 0.1);
      min-width: 30%;
      padding-left: 8px;
      margin-left: -8px;
      text-transform: capitalize;
    }
  }

  &__provider {
    background-color: #ececec;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    p {
      margin: 0 4px 0 0;
      font-size: 0.7rem;
      font-weight: 300;
      text-transform: none;
      font-family: "Sans", Arial, sans-serif;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 0.8rem;
      }
      img {
        margin-left: 0;
      }
    }
  }

  &__heading {
    position: relative;
    background-color: $brand-color-green;
    color: white;
    text-transform: uppercase;
    padding: 16px 20px;
    font-size: 28px;
    min-height: 112px;
    @media (max-width: 767px) {
      min-height: 132px;
    }
    small {
      @media (max-width: 767px) {
        font-size: 100%;
        color: $brand-color-yellow;
      }
    }

    &__text {
      @media (max-width: 767px) {
        line-height: 30px;
        padding-right: 36px;
      }
      font-weight: 700;

      small {
        font-weight: 300;
        margin-left: 12px;
        position: relative;
        bottom: 2px;
      }
    }

    .arrowIcon {
      position: absolute;
      left: 0;
      top: 12px;
      cursor: pointer;
      height: 28px;
      width: 28px;
      align-self: center;

      svg {
        height: 28px;
        width: 28px;
        transform: rotate(90deg);
      }
    }

    .button--small {
      .settingsIcon {
        display: inline;
      }
      p {
        padding: 0 12px 0 2px;
        @media (max-width: 767px) {
          padding: 0;
        }
      }

      @media (max-width: 767px) {
        top: 15px;
        min-width: unset;
        width: 42px;
        right: 15px;
        span {
          display: none;
        }
      }
    }

    &--bttm {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .weather-widget {
        &__heading__text {
          flex: 1;
          p {
            display: block;
            font-family: "Sans", Arial, sans-serif;
            font-size: 13px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
        &__heading__bttn {
          flex: 0 0 130px;
          text-align: right;
          .button--small {
            position: relative;
            padding: 7px 13px;
            top: auto;
            right: auto;
            text-align: center;
            p {
              padding: 0;
            }
            span {
              display: block;
              // width: 200px;
            }
          }
        }

        @include respond-below(sm) {
          &__heading__bttn {
            flex: 0 0 100%;
            text-align: left;
            .button--small {
              width: 100px;
            }
          }
        }
      }
    }
  }

  &__properties-list {
    position: relative;
    width: 580px;
    overflow-y: auto;
    @media (max-width: 767px) {
      width: 100%;
      min-height: 137px;
      height: auto;
      padding: 8px 15px 8px 30px;
    }
  }

  &__property {
    width: 580px;
    overflow-x: hidden;
    //height: 410px;

    &__days {
      @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__next {
      display: flex;
      justify-content: space-between;
      background-color: #ececec;
      padding: 1rem;

      .button--small {
        position: relative;
        min-width: 120px;
        p {
          padding: 2px 8px;
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        .arrowIcon {
          position: absolute;

          svg {
            width: 1rem;
            height: auto;
          }
        }
      }

      .next {
        .arrowIcon {
          right: 0px;
          top: 2px;
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .previous {
        .arrowIcon {
          left: 0px;
          top: 2px;
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__current_day {
      padding: 1rem 1rem 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        font-family: "SansCond", Arial, sans-serif;
        color: #00965e;
        font-size: 40px;
        font-weight: 600;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .weather-icon {
        margin-top: -2rem;
        margin-bottom: -2rem;
      }

      .temperature {
        display: flex;
        font-family: "Sans", Arial, sans-serif;
        align-items: flex-end;

        p {
          font-weight: 300;
          margin: 0 8px;
          font-size: 14px;
        }
      }

      .temperature__wrapper {
        display: flex;
      }
    }

    &__temp-night {
      color: $side-color-darkgrey;
    }

    &__selected-temperature {
      color: $brand-color-green;
      display: flex;
      width: 60%;
      font-size: 60px;
      small {
        display: block;
        margin-top: -20px;
      }

      & > div:first-child {
        width: 55px;
      }
    }
    &__selected-weather {
      width: 40%;
      font-family: "Sans", Arial, sans-serif;
      font-size: 14px;
      span {
        display: flex;
        margin-bottom: 14px;
      }

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    &__week-day {
      text-transform: uppercase;
      color: $side-color-darkgrey;
    }

    &__day {
      width: 240px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      padding: 33.5px 16px;
      background-color: #f8f8f8;
      font-family: "Sans", Arial, sans-serif;

      &:nth-child(even) {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: $brand-color-yellow;
        &::after {
          position: absolute;
          content: "";
          right: -5px;
          width: 0;
          height: 5px;
          border-top: 5px solid transparent;
          border-left: 5px solid $brand-color-yellow;
          border-bottom: 5px solid transparent;
          top: 50%;
          transform: translateY(-50%);
        }

        .weather-widget__property__temp-night {
          color: white;
        }
        .weather-widget__property__week-day {
          color: black;
        }
      }

      &:hover {
        opacity: 0.6;
        @media (max-width: 767px) {
          opacity: 1;
        }
      }

      & > div {
        &:first-child {
          width: 120px;
          @media (max-width: 767px) {
            width: 80px;
          }
        }
      }
      @media (max-width: 767px) {
        padding: 10px 16px;
        width: 50%;
      }
    }

    &__forecast {
      width: 100%;
      margin-bottom: 2rem;
      padding: 0 1rem;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);

      &__selected {
        height: 220px;

        & > div {
          display: flex;
        }

        @media (max-width: 767px) {
          height: 150px;
        }
      }

      .day {
        font-family: "Sans", Arial, sans-serif;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        //height: 97px;
        border-bottom: 2px solid #f2f2f2;
        //padding-bottom: 16px;
        margin: 0 2px;
        font-size: 0.7rem;

        &.current {
          color: $brand-color-yellow;
          border-bottom: 2px solid $brand-color-yellow;
        }

        .weather-icon {
          margin-bottom: -8px;
        }

        &:focus {
          outline: none;
        }
      }

      .slider-control-centerleft {
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none;
        margin-left: -24px;
        .arrowIcon {
          cursor: pointer;
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .slider-control-centerright {
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none;
        margin-right: -24px;
        .arrowIcon {
          cursor: pointer;
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      flex-direction: column;
    }

    &__info {
      font-family: "Sans", Arial, sans-serif;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem 0 1rem;

      & > div {
        width: 50%;
        h6 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        p {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }

    &__back {
      padding: 0 1rem 1rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  .slider {
    padding: 0;
  }

  &__empty-pois {
    text-align: center;
    padding: 50px;
    font-size: 18px;
  }

  main {
    height: 0;
    transition: height 0.25s;
    overflow: hidden;

    &.weather-widget__weatherConditions {
      height: 294px;

      @media (max-width: 767px) {
        height: 246px;
      }

      @media (max-width: 480px) {
        height: 318px;
      }
    }
    &.weather-widget__propertyBox {
      height: 508px;

      @media (max-width: 767px) {
        height: 600px;
      }
    }
  }
}

.weather-widget__select__wrapper {
  min-width: 270px;
  color: black;
  font-family: "Sans", Arial, sans-serif;

  svg {
    fill: black !important;
  }

  .weather-widget__select__control {
    font-size: 14px;
    border-radius: 0;
    background-color: white;
    border-color: white;
  }

  .weather-widget__select__value-container {
    color: black !important;
    padding: 0 8px;

    div {
      color: black !important;
      text-transform: none;
      font-size: 1rem;
      font-weight: 300;
    }
  }

  .weather-widget__select__indicator-separator {
    display: none;
  }

  .weather-widget__select__single-value {
    color: white;
  }

  .weather-widget__select__menu {
    font-size: 14px;
    color: black !important;
  }
}
