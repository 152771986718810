.VideosPreview {
  padding: 1rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }

  &__playback {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 1rem;
    background-color: #00965e;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }
}
