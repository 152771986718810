@import "src/styles/_colors.scss";
@import "src/components/blocks/Section/Section.scss";
@import "src/components/blocks/Articles/Articles.scss";
@import "src/components/blocks/Articles/ArticlesCategories.scss";
@import "src/components/blocks/Articles/PopularArticles.scss";
@import "src/components/blocks/Events/Events.scss";
@import "src/components/blocks/EventsCalendar/EventsCalendar.scss";
@import "src/components/blocks/Gallery/Gallery.scss";
@import "src/components/blocks/PollSlider/PollSlider.scss";
@import "src/components/blocks/RestrictedArticleWindow/RestrictedArticleWindow.scss";
@import "src/components/blocks/SynopticComment/styles.scss";
@import "src/components/blocks/Videos/VideoCover.scss";
@import "src/components/blocks/Videos/VideosList.scss";
@import "src/components/blocks/Videos/VideosPreview.scss";
@import "src/components/blocks/WeatherCalendar/WeatherCalendar.scss";
@import "src/components/blocks/WeatherWidget/styles.scss";
@import "src/components/views/RealEstate/components/RealEstateResults/components/SingleProperty/SingleProperty.scss";
@import "src/components/views/RealEstate/components/SuggestedProperties/SuggestedProperties.scss";
@import "src/components/views/GalleryPage/GalleryPage.scss";
@import "src/components/views/RegistrationBenefits/WeatherContent/WeatherContent.scss";
@import "src/components/views/RegistrationBenefits/ManageAgroContent/ManageAgroContent.scss";
@import "src/components/views/UserSupportPage/LogIn/LogIn.scss";

.u-header--inline {
  display: inline-flex;
  align-items: center;
}

.u-font-v-middle {
  line-height: 1em;
  padding-top: 0.15em;
}

.u-font-green {
  color: $brand-color-green !important;
}

.u-font-yellow {
  color: $brand-color-yellow !important;
}

.small-description {
  font-size: 13px !important;
  line-height: 20px !important;
  font-weight: 300;
}

.c-link {
  cursor: pointer;
  color: $brand-color-green;
  font-weight: 400;
  text-decoration: underline;
  transition: all 200ms ease !important;
  &:hover {
    color: $brand-color-yellow;
    text-decoration-color: $brand-color-yellow;
  }
  &--blank {
    color: inherit !important;
  }
  &--underline {
    text-decoration: underline !important;
  }
}

.c-btn-link {
  border: 2px solid #ffa203;
  background-color: #ffa203;
  text-transform: uppercase;
  padding: 12px;
  color: #2e3033;
  font-size: 14px;
  font-weight: 300;

  &:hover {
    background-color: white;
    color: #ffa203;
  }

  &.c-btn-link--green {
    color: white;
    border: 2px solid #00965e;
    background-color: #00965e;
    &:hover {
      color: #00965e;
      background-color: white;
    }
  }
}

.c-radio-group {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.c-radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.c-radio-group .checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #00965e;
}

.c-radio-group:hover input ~ .checkmark {
  opacity: 0.6;
}

.c-radio-group input:checked ~ .checkmark {
  background-color: white;
}

.c-radio-group .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #2e3033;
}

.c-radio-group input:checked ~ .checkmark:after {
  display: block;
}

.c-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: #00965e;
    text-decoration: underline;
  }
}

.c-checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 2px solid #00965e;
  border-radius: 2px;
}

.c-checkbox input:checked ~ .checkmark {
  background-color: #00965e;
}

.c-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.b-aside {
  margin-top: 30px;

  &>div {
    margin-bottom: 30px;

    &>img {
      width: 100%;
      height: auto;
    }
  }

  .EventsCalendar--small {
    .event-card .event-details h3 {
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
    }

    .event-card .event-details p {
      font-size: 10px;
      line-height: 12px;
    }
  }

}

.container-fluid {
  padding: 0;
  margin: 0;
}

.tools-page__filters {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #cfcccc;
}

.events-page-header {
  background-image: url(assets/img/poledopopisu.jpg);
  padding: 16px 0;
  color: white;
  background-size: cover;
  background-position: center center;

  a,
  .separator::after {
    color: #fff;
    opacity: 0.5;
  }

  a:hover {
    opacity: 1;
  }

  h1 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    margin: 23px 0;
  }

  h3 {
    font-size: 16px;
  }
}

.tools-page .tools-page__additional .tools-page__titile {
  text-align: center!important;
}