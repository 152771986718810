@import "src/styles/_colors.scss";

.user-logIn-page {

  &__container {
    max-width: 960px;
  }

  &__boxes {
    padding: 0;
    @media (min-width: 992px) {
    }
  }

  &__column {
    padding: 2.5rem 1.25rem 1.25rem 1.25rem;
    border-radius: 6px 0px 0px 6px;
    @media (min-width: 992px) {
      padding: 2.5rem;
    }
    
    &--left {
      background: white;
      margin-bottom: 1.25rem;
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
    &--right {
      background: $brand-color-green-light;
      @media (min-width: 992px) {
        border-radius: 0px 6px 6px 0px;
      }
    }
  }

  &__submit {
    margin-top: 23px;
  }

  &__main-wrapper {
    background: #ececec;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media (min-width: 992px) {
      padding-top: 65px;
      padding-bottom: 65px;
      flex-direction: row;
    }

    .breadcrumbs {
      top: 2rem;
    }
  }

  .logIn-description {
    width: 100%;
    display: flex;
    flex-direction: column;

    .forgot-password-link {
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      text-decoration: underline;
      color: $brand-color-green;
      transition: all 200ms ease;
      &:hover {
        color: $brand-color-yellow !important;
        text-decoration-color: $brand-color-yellow !important;
      }
    }

    .typography--h1 {
      color: $brand-color-green;
      margin: 0 0 2.25rem 0;
      width: 100%;
    }

    p:nth-of-type(2) {
      font-family: "Sans", Arial, sans-serif;
      font-size: 18px;
      color: $black;
      letter-spacing: 0;
    }

    form {
      display: flex;
      flex-direction: column;
      .button--yellow,
      .bttn {
        margin-top: 16px;
        width: 150px;
        align-self: center;
      }

      .input {
        margin-bottom: 15px;

        input {
          border-radius: 3px;
          border: 1px solid #D6D6D6;
          box-shadow: none;
          &:focus, &:active {
            border-color: $brand-color-green;
            outline-color: $brand-color-green;
          }
        }
      }

      .link--follow {
        align-self: flex-end;
      }
    }

    .input-error--big {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: left;
    }
  }

  .logIn-socialMedia {
    padding: 24px 10px 10px 10px;
    max-width: 100%;

    p {
      width: 100%;
      text-align: center;
    }

    .socialMedia-buttons-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      img {
        margin: 5px;
        cursor: pointer;
        height: 32px;
      }
    }
  }
}

.user-register {

  .register-description {
    .typography--h1 {
      color: $brand-color-green;
      width: 100%;
      margin-bottom: 2.5rem;
    }

    > * {
      // display: block;
      width: 100%;
    }

    width: 100%;
    // display: flex;
    // flex-direction: column;

    p:nth-of-type(2) {
      font-family: "Sans", Arial, sans-serif;
      font-size: 18px;
      color: $black;
      letter-spacing: 0;
      margin-bottom: 1rem;
    }

    .props-container {
      display: flex;
      align-items: flex-start;

      .checkedIcon {
        margin-top: -2px;
        margin-right: 14px;
        position: relative;
        svg {
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background: $brand-color-green;
        }
      }
    }
  }

  &__register-button-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;
  }
}
