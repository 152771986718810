.additional-tool {
  width: 270px;
  background-color: white;
  padding: 2rem;
  text-align: center;
  position: relative;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .c-widget__cta.tools-page__cta {
    max-width: 100%;
    width: 100%;
    position: static;
    margin: auto 0 0;
  }

  p {
    font-size: 14px;
    color: #2e3033;
    margin-bottom: 2rem;
  }
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    color: #2e3033;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &__cover {
    margin: -2rem -2rem 2rem -2rem;
    height: 170px;
    background-color: #2e3033;
    position: relative;
    background-size: cover;
  }

  &__restricted {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    background-color: #00965e;
    color: #fff;
    font-size: .8125rem;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0.2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
}
