@import "src/styles/_colors.scss";

.events-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 24px;
  position: relative;
  user-select: none;

  .all-events-calendar {
    width: 100%;
    border-top: 2px solid $brand-color-green;
    border-bottom: 2px solid $brand-color-green;
    padding-top: -24px;
    height: 850px;
    overflow: hidden;
    margin-bottom: 32px;
    padding-bottom: 60px;

    & > div:nth-child(3) {
      filter: grayscale(100%);
      opacity: 0.3;
      background: linear-gradient(
        180deg,
        rgba(246, 246, 246, 0) 0%,
        #f9f9f9 100%
      );

      .event-all-dates {
        background-color: $side-color-lightgrey;
        margin: 0;
        padding: 25px;
      }
    }
  }

  .basic-event-calendar {
    width: 100%;
    padding-top: -24px;
    max-height: 580px;
    // min-height: 480px;
    overflow: hidden;
    margin-bottom: 32px;
    padding-bottom: 60px;

    & > div:nth-child(4) {
      filter: grayscale(100%);
      opacity: 0.3;
      background: linear-gradient(
        180deg,
        rgba(246, 246, 246, 0) 0%,
        #f9f9f9 100%
      );

      .event-all-dates {
        background-color: $side-color-lightgrey;
        margin: 0;
        padding: 25px;
      }
    }
  }
}

.single-event-calendar {
  .buttonAdditional--yellow,
  .buttonAdditional--grey {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .arrowIcon {
      width: 32px;
      height: 32px;
    }
  }
}

.events-row,
.all-events-row {
  .loading {
    background: transparent;
  }
}

.c-events {
  &-container {
    border-top: 2px solid #00965e;
    border-bottom: 2px solid #00965e;
    flex: 0 0 100%;
    width: 100%;
    max-height: 782px;
    overflow: hidden;
    height: 100vh;
    scrollbar-width: none;
    position: relative;
    .loading {
      background: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &--hide {
      opacity: 0;
    }
    &--small {
      height: 560px;
      max-height: 560px;
      border: none;
      & + .buttonAdditional--grey,
      & + .buttonAdditional--yellow {
        margin-top: -32px;
        position: relative;
        z-index: 30;
      }
    }
    &--2 {
      height: 300px;
      max-height: 300px;
    }
    @media (max-width: 767px) {
      max-height: calc(100vh - 300px);
    }
  }
  &-card {
    display: flex;
    padding-bottom: 32px;
    height: 350px;
  }
}

.events-slider {
  &--small {
    .c-events {
      &-card {
        display: flex;
        padding-bottom: 32px;
        height: 144px;
      }
    }
  }
  .slider-frame {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .slider-control-topcenter,
  .slider-control-bottomcenter {
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0 !important;
    transform: none !important;
    z-index: 2;
    user-select: none;
    .arrowIcon--selected {
      margin-bottom: 0;
    }
    p {
      display: none;
    }
  }
  .slider-control-topcenter {
    background-color: #ececec;
  }
  .slider-control-bottomcenter {
    &:after {
      content: " ";
      position: absolute;
      // bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      z-index: -1;
      bottom: 0px;
      pointer-events: none;
    }
  }
  + .loading {
    top: 52px;
    bottom: 0;
    height: auto;
    background: rgb(236, 236, 236);
    opacity: 0.9;
    visibility: visible;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.events-calendar--widget {
  background-color: #00965e;
  &__title {
    font-family: "SansCond", Arial, sans-serif;
    color: white;
    display: flex;
    padding: 14px 14px 0;
    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 22px;
      text-transform: uppercase;
    }

    & > span {
      margin-right: 12px;
    }
  }

  &__content {
    overflow: hidden;
    padding-top: 30px;
  }

  &__cta {
    text-align: center;
    padding-bottom: 12px;
    a {
      display: inline-block;
      font-weight: 300;
      background-color: #ffa203;
      padding: 12px;
      text-transform: uppercase;
      font-size: 14px;
      border: 2px solid #ffa203;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }

  .slider-control-topcenter {
    background-color: #00965e;
    top: -32px !important;
    min-height: 32px;
    .buttonAdditional--yellow {
      transform: rotate(180deg);
    }
    .buttonAdditional--grey {
      margin: 0 auto;
      justify-content: center;
    }
  }

  .slider-control-bottomcenter {
    background-color: #00965e;
    z-index: unset;

    .buttonAdditional--grey {
      margin: 0 auto;
      justify-content: center;
      cursor: default !important;

      .arrowIcon,
      svg,
      g,
      polygon,
      path {
        cursor: default !important;
      }
    }
  }

  .slider-control-bottomcenter {
    position: relative !important;
  }
  .buttonAdditional--yellow {
    cursor: pointer;
    polygon {
      fill: #ffa203;
    }
  }

  .events-slider--small .c-events-card {
    padding-bottom: 0;
    height: auto;
    border-bottom: 2px solid #e9e9e9;
  }

  .slider-frame {
    border: 2px solid #e9e9e9;
  }

  .event-dates {
    background-color: #f7f7f7;
  }

  .slider-list > li {
    max-height: 82px;
  }
}
