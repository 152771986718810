@import "src/styles/_colors.scss";

$containerWidth: 586px;
$verticalPadding: 114px;
$horizontalPadding: 40px;

.calculator-container {
  input {
    width: 100%;
  }

  .input .input-error {
    margin: 0;
  }

  .investment-container {
    width: 100%;
    max-width: $containerWidth;
    min-height: 200px;
    background-color: $side-color-lightgrey;
    padding: $horizontalPadding $verticalPadding $horizontalPadding $verticalPadding;

    @media (max-width: 576px) {
      padding: $horizontalPadding / 3 $verticalPadding / 3;
    }

    .typography--h2 {
      width: 100%;
      color: $black;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      margin: 5px 0 30px;
    }

    .has-subvention-question,
    .investment-type-question,
    .subvention-receiving-date-question,
    .subvention-amount-container {
      margin-top: 30px;
    }

    .subvention-receiving-date-question {
      margin-bottom: 0;
    }

    .subvention-receiving-date-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      @media screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >div {
          width: 48%;
        }
      }

      label {
        display: none;
      }

      input {
        min-width: unset;
      }

      .select__input-error {
        order: 3;
        text-align: left;
        grid-column: span 2 / 3;
      }
    }

    @media (max-width: 576px) {
      .subvention-receiving-date-container {
        grid-template-columns: 1fr;

        .select__input-error {
          grid-column: unset;
        }
      }
    }

    .investment-type-description {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 300;
    }

    .subvention-fields-container {
      display: flex;
      justify-content: space-between;

      .radioButton {
        width: 50%;

        span {
          font-family: "Sans", Arial, sans-serif;
          font-weight: 300;
          margin-left: 10px;
        }
      }
    }

    .input-adornment-text {
      font-size: 18px;
      color: $brand-color-green;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      // for better center aligment
      padding-top: 2px;
      // margin-top: 24px;
    }

    .input-with-slider {
      margin-top: 30px;

      &.custom-margin {
        margin-top: 10px;
      }
    }

    .investment-type-fields-container {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }

    @media (max-width: 576px) {
      .investment-type-fields-container {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }

  .investment-computed-summary-container {
    max-width: $containerWidth;
    width: 100%;
    border: 1px solid #f2f2f2;
    padding: 16px 120px 24px;
    text-align: center;

    .computed-value-description {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .computed-value {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $brand-color-green;
    }

    .minimum-credit-disclaimer {
      font-size: 13px;
      color: $black;
      letter-spacing: 0;
      line-height: 20px;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 300;

      .minimum-credit-disclaimer--bold {
        font-weight: 600;
      }

      .minimum-credit-disclaimer--link {
        color: $brand-color-green;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }

  .next-step-button-container {
    background-color: $brand-color-green;
    max-width: $containerWidth;
    width: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    // IE hacks
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      height: 90px;
    }

    >div {
      min-width: 160px;
    }
  }

  .credit-description {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 300;
    text-align: justify;
  }

}
