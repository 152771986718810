@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints.scss";

$trans: all 0.3s ease-in-out;
// $content_bar_height: 35px

.navigation {
  &-buttons {
    display: flex;
    position: relative;
    @include respond-below('md'){
      gap: 1.25rem;
    }

    &__button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: 700;
      font-size: .875rem;
      line-height: 1em;
      width: 1.5rem;
      height: 1.5rem;
      @include respond-above('md') {
        width: 64px;
        height: 64px;
        border-left: 1px solid #EAE9EB;
      }

      &:hover {
        color: $brand-color-green;
        svg {
          path {
            stroke: $brand-color-green;
          }
        }
      }

      &--no-border {
        border-left: none;
      }
      &--current-lang {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: .25rem;

        svg {
          transition: all 0.2s ease;
          transform: rotate(0deg);
        }
        @include respond-below('md'){
          &.is-open {
            color: $brand-color-green;
            svg {
              transform: rotate(180deg) !important;
              path {
                stroke: $brand-color-green;
              }
            }

            & + ul {
              display: flex !important;
            }
          }
        }
      }

      &--languages {
        color: black;
        svg {
          transition: all 0.2s ease;
        }
        &:hover {
          color: $brand-color-green;
          svg {
            transform: rotate(180deg);
          }
        }

        ul {
          li {
            color: black;
            &:hover {
              color: $brand-color-green;
            }
          }
        }

        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    &__langs {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      white-space: nowrap;
      background-color: white;
      padding: 0;
      flex-direction: column;
      text-transform: none;

      &.is-showed {
        display: block;
      }

      & > li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: .875rem;
        border: 1px solid rgba(128, 128, 128, 0.2);
        padding: 1rem;
        gap: 0.5rem;

        & * {
          pointer-events: none;
        }

        & > span {
          display: block;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 1;
        }
      }
    }
  }

  &-container {
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: max-content;
    height: 64px;

    &--searching {
      justify-content: space-between;
    }

    &-wrapper {
      border-bottom: 1px solid #EAE9EB;

      &.fixed {
        position: sticky;
        z-index: 1000;
        background: white;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
      }
      & > .container {
        padding: 11px 0;
      }
      & > div:first-child {
        &.c-content-bar {
          @include respond-below('xs'){
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.5em;
          }
          & + .container {
            padding: 0 1rem;
          }
        }
      }
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0;
      height: 100%;
      transition: $trans;
      @include respond-below('md'){
        justify-content: space-between;
      }
    }

    .appLogo {
      display: block;
      max-width: 157px;
      min-height: 28px;
      cursor: pointer;
      margin: 0;
      @include respond-above('md'){
        max-width: 136px;
        min-height: 24px;
        margin: 0 0 0 3rem;
      }
    }

    .searchIcon {
      margin: 0 20px 0 0;
      cursor: pointer;
      display: none;
    }

    .navigation-list,
    .navigation-list-mobile {
      @include respond-below('md'){
        display: none;
        opacity: 0;
        height: 0;
        transition: $trans;
      }
    }

    .navigation-list-mobile--visible {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 64px;
      justify-content: flex-start;
      padding-bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      background-color: white;
      color: #000000;
      overflow-y: auto;

      &.in {
        opacity: 1;
        min-height: calc(100vh - 64px);
        padding-bottom: 3rem;
      }

      .navigation-item {
        height: 60px;
        cursor: pointer;

        .header--menu {
          color: #000000;
          p {
            font-size: 2.5rem;
          }
        }

        &--selected {
          .header--menu {
            color: #000000;
            p {
              font-size: 2.5rem;
              color: #000000;
            }
          }
        }
      }

      li {
        margin: 1rem 0;

        .button--yellow {
          display: block;
          width: 30%;
          min-width: 150px;
          height: 42px;

          p {
            padding: 0;
            line-height: 36px;
          }
        }
      }
    }

    .openMenu {
      display: flex;
      flex-direction: row;
    }

    .button--yellow {
      display: none;
    }

    .profile-link {
      white-space: nowrap;
    }

    @include respond-below('md'){
      padding-top: 0;
      justify-content: space-between;
      padding: 0 20px;
      &__row {
        display: flex;
        gap: 1.25rem;
        .profile-link {
          display: none;
        }
      }
      &--open {
        .navigation {
          &-logo {
            max-width: max-content;
          }
          &-profile {
            width: 120px;
          }
          &-hamb {
            span {
              background-color: rgba(233, 43, 92, 1);
              &:first-child {
                transform: translateY(6px) rotate(45deg);
              }
              &:nth-child(2) {
                opacity: 0;
              }
              &:last-child {
                transform: translateY(-6px) rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }
  &-profile {
    width: 32px;
    height: 32px;
    background: $brand-color-yellow;
    margin-right: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    &__txt {
      // flex: 0 0 100%;
      white-space: nowrap;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
      text-transform: uppercase;
      padding-right: 15px;
      // max-width: 0px;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
  &-logo {
    @media (max-width: 992px) {
      max-width: 160px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }
  }
  &-hamb {
    align-items: center;
    padding: 5px 0;
    &__toggle {
      padding-top: 0 !important;
      height: 22px;
      margin-left: 5px;
    }
    &__txt {
      margin: 0;
      font-size: 20px;
      line-height: 1;
      font-family: "SansCond", Arial, sans-serif;
      padding-top: 4px;
    }
    span {
      width: 18px;
      height: 2px;
      background-color: $black;
      display: block;
      margin: 4px 0px;
      transition: all 0.3s ease-in-out;
      // @include transition(transform);
    }
    // &.open {
    //   justify-content: center;
    //   span {

    //   }
    // }
  }
}

@media (min-width: 992px) {
  .navigation-container {
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    .appLogo {
      display: block;
      min-width: 136px;
      max-width: none;
      min-height: 24px;
    }

    .searchIcon {
      margin: 0 20px 0 0;
      cursor: pointer;
      display: block;
    }

    .navigation-list-mobile,
    .navigation-list-mobile--visible {
      display: none;
    }

    .navigation-list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      // width: calc(70% - 160px);

      .navigation-item {
        height: auto;
        margin-right: 20px;
        cursor: pointer;

        .header--menu {
          color: $black;
        }

        a {
          position: relative;
        }
      }

      .navigation-item--selected {
        height: auto;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .openMenu {
      display: none;
    }

    .button--yellow {
      display: block;
      .navigation-container .navigation-list .navigation-item p {
        white-space: nowrap;
      }

      p {
        padding: 9px 10px;
      }
    }

    @media (max-width: 992px) {
      .navigation-list {
        transform: scale(0.9);
      }
    }
  }
}

.nav-langs {
  position: relative;
  // margin-left: 20px;
  margin-right: -40px;
  margin-bottom: 0;
  width: 110px;
  max-height: 52px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  z-index: 200;
  &:hover {
    border-color: $side-color-lightgrey;
  }
  &-menu {
    padding-left: 0;
    margin-left: -2px;
    margin-right: -2px;
    transition: all 0.3s ease-in-out;
  }
  // &-menu {
  &.is {
    &-hide {
      .nav-langs-menu {
        max-height: 0px;
        overflow: hidden;
      }
    }
    &-showed {
      .nav-langs-menu {
        max-height: 120px;
        // overflow: visible;
      }
    }
  }
  // }
  &:after {
    content: " ";
    position: absolute;
    top: (48 - 20)/2 * 1px;
    right: 15px;
    width: 26px;
    height: 20px;
    background: url("../../../assets/icons/arrow-gray.svg") center center /
      contain no-repeat;
  }
  &__el {
    display: flex;
    align-items: center;
    min-height: 48px;
    font-size: 11px;
    background-color: $side-color-lightgrey;
    border: 2px solid $side-color-lightgrey;
    transition: all 0.3s ease-in-out;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    &:hover {
      background-color: #fff;
    }
    &--curr {
      background-color: transparent;
      border: none;
    }
    * {
      pointer-events: none;
    }
    img {
      flex: 0 0 32px;
    }
    span {
      flex: 1;
      padding-left: 10px;
    }
    &.is-active {
      display: none;
    }
  }
  @media (max-width: 992px) {
    margin: 0;
    width: 80px;
    max-width: 0px;
    overflow: hidden;
    &:after {
      right: -2px;
    }
    &__el {
      padding: 0 5px;
      img {
        flex: 0 0 25px;
        width: 25px;
      }
      span {
        display: none;
      }
    }
  }
}

.navigation-container--open {
  .nav-langs {
    // width: 60px;
    max-width: 60px;
    overflow: visible;
  }
}

.search-bar {
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);

  label {
    margin: 0;
  }

  input {
    position: static;
    width: 480px;
    height: 44px;
    padding: 1rem 1.5rem;
    line-height: 1;
    padding-left: 50px;
    font-weight: 400;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjg3NSAxNy4yNUMxNC4zOTU4IDE3LjI1IDE3LjI1IDE0LjM5NTggMTcuMjUgMTAuODc1QzE3LjI1IDcuMzU0MTggMTQuMzk1OCA0LjUgMTAuODc1IDQuNUM3LjM1NDE4IDQuNSA0LjUgNy4zNTQxOCA0LjUgMTAuODc1QzQuNSAxNC4zOTU4IDcuMzU0MTggMTcuMjUgMTAuODc1IDE3LjI1WiIgc3Ryb2tlPSIjMkUzMDMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTUuNzUgMTUuNzVMMjEgMjEiIHN0cm9rZT0iIzJFMzAzMyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 24px;

    &, &:focus, &:focus-visible {
      border: 2px solid $brand-color-green !important;
      border-radius: 3px;
    }

    &::placeholder {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #585A5C;
    }

    @include respond-below(md) {
      bottom: unset;
      width: unset;
      padding: 0.5rem 1rem;
      height: 42px;
    }
  }
}

@include respond-below('md'){
  div[data-searchbar-open="true"]{
    .navigation-container__row,
    .navigation-buttons__button:not(:first-child) {
      display: none;
    }
    .search-bar {
      position: static;
      transform: none;
      margin: 0;
      display: flex;
      flex-grow: 1;
      width: 100%;
      margin-right: 1.25rem;

      label, label input {
        display: flex;
        flex-grow: 1;
      }

      input {
        padding-left: 50px;
      }

    }
  }

  div[data-mobile-nav-open="true"]{
    .navigation-buttons,
    .appLogo,
    .navigation-buttons__button:not(:first-child) {
      display: none;
    }
  }
}
