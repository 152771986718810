@import "src/styles/_colors.scss";

.articles-block {
  padding-bottom: 70px;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  position: relative;
  align-items: center;

  @media (min-width: 1200px) {
    // align-items: flex-start;
  }

  .button--small {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px);
  }

  .typography--h1 {
    width: 100%;
    margin-top: 64px;
    color: $brand-color-green;
    text-transform: uppercase;
  }
}

.articles-row {
  // display: block;
  width: 100%;
  margin-top: 30px;

  > * {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .articles-block {
    border-right: 1px solid $side-color-lightgrey;
  }
}
