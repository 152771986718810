.cft-export {
  transition: opacity 0.3s ease-in-out;
  transform: translateY(-20rem);
  opacity: 0;
  height: 0;
  z-index: -1;
  position: absolute;

  &.cft-export-isVisible {
    z-index: 1001;
    opacity: 1;
    transition: height 0.6s ease-in-out;
    transform: translateY(-20rem);
    .cft-export-overlay {
      position: fixed;
      min-height: 200vh;
      width: 200vw;
      transform: translateX(-100vw);
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      //transform: translateY(18rem);
    }
  }

  .cft-export-container {
    height: 0;
    width: 760px;
    display: flex;
    position: relative;
    //margin-top: 5rem;
    z-index: -1;
    //margin-left: 6.1rem;
    justify-self: center;
    flex-direction: column;
    background-color: #fff;
    opacity: 0;
    color: #079a62;

    transition: height 0.6s ease-in-out;
    //transition: opacity 0.6s ease-in-out;

    &.cft-export-container-isVisible {
      transition: height 0.6s ease-in-out;
      //transition: opacity 0.6s ease-in-out;
      transform: translateY(20rem);

      opacity: 1;
      z-index: 1001;
      height: 300px;
    }

    .cft-export-header {
      height: 20%;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      color: #079a62;

      h4 {
        font-weight: bold;
        font-size: 20px;
        margin-top: 1rem;
      }
    }

    .cft-export-buttons {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      color: #079a62;

      .cft-export-button {
        flex-grow: 3;
        padding-top: 2rem;
        max-width: 33.33%;
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &:hover,
        &:active {
          transition: all 0.3s ease-in-out;
          background-color: #00965e;
          color: white;

          .cft-export-button-header,
          .cft-export-button-body .cft-export-button-format,
          svg {
            color: white;
          }
        }

        .cft-export-button-header {
          color: #079a62;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            height: 35px;
            width: 35px;
          }
          h6 {
            margin-top: 0.5rem;
          }
        }

        .cft-export-button-body {
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          width: 100%;

          .cft-export-button-format {
            color: #000;
            font-weight: bold;
            font-size: 13px;
            margin-top: 1rem;
          }

          p {
            font-size: 13px;
            justify-content: center;
            display: flex;
            width: 90%;
            text-align: center;
            margin: 0.5rem;
            padding: 0 1rem;
          }
        }

        .cft-export-line {
          width: 1px;
          height: 100%;
          background-color: rgba(123, 123, 123, 0.3);
        }

        &.cft-export-button--disabled {
          opacity: 0.6;
          cursor: not-allowed;
          background-color: #fff;
          color: #000;

          .cft-export-button-header,
          .cft-export-button-body .cft-export-button-format,
          svg {
            color: #000;
          }

          &:hover,
          &:active {
            background-color: #fff;
            color: #000;

            .cft-export-button-header,
            .cft-export-button-body .cft-export-button-format,
            svg {
              color: #000;
            }
          }
        }
      }
    }

    .cft-export-arrow {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      transform: translateY(-9px);

      //position: absolute;
      //top: -9px;
      //right: 45px;
      z-index: 1002;
    }
  }
  .cft-export-isDashboard {
    margin-bottom: 1.5rem;
  }
  .cft-export-container-isDashboard {
    transform: translate(141px, 148px);
  }

  @media (max-width: 1200px) {
    .cft-export-container {
      margin-left: -8.9rem;
    }
  }
  @media (max-width: 990px) {
    .cft-export-container {
      .cft-export-arrow {
        right: 9.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    transition: all 0.3s ease-in-out;
    height: 0;
    position: fixed;
    left: 0;
    //position: absolute;
    //top:1rem;
    //width:110vw;
    //position: fi;

    &.cft-export-isVisible {
      transition: all 0.3s ease-in-out;
      width: 100vw;

      //width: 100%;
    }

    .cft-export-container {
      width: 110%;
      margin: 0.85rem 0 0 0;
      height: 0;
      transition: all 0.3s ease-in-out;

      &.cft-export-container-isVisible {
        transition: all 0.3s ease-in-out;
        height: 375px;
        min-height: 375px;
      }

      .cft-export-buttons {
        flex-direction: column;
        width: 100%;

        .cft-export-line {
          width: 100%;
          height: 1px;
          padding: 0.05rem;
        }

        .cft-export-button {
          padding: 0;
          height: 33.33%;
          flex-direction: row;
          max-width: 100%;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .cft-export-button-header {
            margin: 0 0.5rem;
            text-align: center;
            min-width: 100px;
          }

          .cft-export-button-body {
            align-items: flex-start;

            .cft-export-button-format {
              text-align: left;
            }

            p {
              padding: 0;
              margin: 0.5rem 0;
              font-size: 13px;
              text-align: left;
            }
          }
        }
      }

      .cft-export-arrow {
        //right: 80px;
        right: 7rem;
        position: absolute;
      }
      .cft-export-arrow-isRaport {
      }
    }
    .cft-export-isDashboard {
      margin-bottom: 1.5rem;
    }
    .cft-export-container-isDashboard {
      transform: translateY(4rem);
    }
  }
}

.CftExportReport {
  border-left: 1px solid #eae9eb;
  border-right: 1px solid #eae9eb;
  .cft-button.cft-button--isExport {
    background-color: transparent;
    border: none;
    color: #585a5c;

    svg {
      fill: #585a5c;
    }
  }

  @media (max-width: 990px) {
    padding: 0 0.6rem 0 1rem;
  }
}
