.single-property {
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media (min-width: 992px) {
    flex-direction: row;
  }

  &__thumbnail {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #e3e3e3;
    background-size: cover;
    background-position: center center;
    @media (min-width: 992px) {
      width: 362px;
      height: auto;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    padding: 28px;

    &__address {
      span {
        font-weight: 300;
      }
      svg {
        height: 21px;
        width: auto;
        margin-right: 15px;
        path {
          fill: #2e3033;
        }
      }
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin: 28px 0 20px 0;
    }

    &__area {
      color: #00965e;
      margin-bottom: 20px;
      svg {
        height: 13px;
        width: auto;
        margin-right: 8px;
        path {
          fill: #00965e;
        }
      }

      span {
        display: inline-block;
        &:first-of-type {
          margin-right: 24px;
          display: none;
          @media (min-width: 992px) {
            display: inline-block;
          }
        }
      }
    }

    &__properties {
      font-size: 14px;
      color: #2e3033;
      padding: 0;

      span {
        position: relative;
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: black;
        border-radius: 50%;
        margin: 0 15px;
        bottom: 3px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &__date {
      display: none;
      font-weight: 300;
      color: #00965e;

      @media (min-width: 992px) {
        display: block;
      }
    }

    &__price {
      font-size: 23px;
      font-weight: bold;
      text-align: right;
      color: #00965e;
    }

    &__cta {
      a {
        background-color: #ffa203;
        border: 2px solid #ffa203;
        padding: 13px 21px 11px 21px;
        &:hover {
          background-color: white;
          color: #ffa203;
        }

        @media (max-width: 400px) {
          width: 100%;
          display: block;
          text-align: center;
          margin-top: 1rem;
        }
      }

      @media (min-width: 992px) {
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
    }

    &--price {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      @media (min-width: 992px) {
        margin-top: 0;
        display: block;
      }

      @media (max-width: 400px) {
        flex-direction: column;
      }
    }
  }

  &__promoted {
    display: block;
    background-color: #ffa203;
    text-align: center;
    color: #2e3033;
    font-size: 14px;
    padding: 12px 20px 10px 20px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 10px;
  }
}
