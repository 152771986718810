.suggested-properties {
  margin-bottom: 2rem;

  &.suggested-properties--articles {
    margin-top: 3rem;

    padding: 0 1rem;

    @media (min-width: 992px) {
      padding: unset;
    }

    .CardArticle {
      margin-top: 1rem;
      @media (min-width: 992px) {
        margin-top: unset;
      }
    }
  }

  .suggested-properties__single-link {
    &:hover {
      color: unset;
    }
  }

  &__header {
    h3 {
      width: 100%;
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      font-stretch: condensed;
      text-transform: uppercase;
      line-height: 1;
      display: block;
      justify-content: space-between;
      border-bottom: 2px solid;
      padding-bottom: 2rem;
      margin-bottom: 1.5rem;
      align-items: flex-end;

      @media (min-width: 992px) {
        display: flex;
      }

      a {
        font-family: "Sans", Arial, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-decoration: underline;
        text-transform: none;
        color: #00965e;
        display: block;

        @media (min-width: 992px) {
          display: unset;
        }
      }
    }
  }

  &__single {
    border: solid 2px #e9e9e9;

    .single-property__thumbnail {
      background-color: #707070;
      width: 100%;
      padding-top: 75%;
      position: relative;
    }
  }

  .suggested-properties__single.single-property {
    flex-direction: column;
    .single-property__content__title {
      margin-top: 0;
    }
    .single-property__content__address {
      margin-bottom: 1.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .single-property__content__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
