@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints";

.c-categories {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 25px 0 27px;
  justify-content: space-between;
  &__el {
    display: block;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 14px 18px 10px;
    // background: $brand-color-green;
    position: relative;
    transition: all 0.3s ease-in-out;

    &--agro {
      .c-categories__el-name {
        background-color: unset;
      }
    }

    &-name {
      position: relative;
      z-index: 2;
    }
    &-line {
      position: absolute;
      bottom: 0;
      height: 4px;
      left: 0;
      right: 0;
      z-index: 0;
      transition: all 0.3s ease-in-out;
    }
    &--all {
      font-family: "Sans", Arial, sans-serif;
      font-size: 15px;
      line-height: 23px;
      .c-categories__el-line {
        background-color: $brand-color-green;
      }
    }
    &--small {
      display: inline-block;
      font-family: "Sans", Arial, sans-serif;
      font-size: 12px;
      line-height: 1;
      padding: 7px 8px 5px;
      background-color: #2e3033;
      color: #fff;
      border-radius: 3px;
      overflow: hidden;

      .c-categories__el-line {
        height: 3px;
      }

      &:hover {
        background-color: #fff;
        color: #2e3033;
      }
    }
    &:not(.c-categories__el--small):hover,
    &--active {
      color: #fff;
      .c-categories__el-line {
        height: 100%;
      }
    }
    &--select {
      display: none;
      @include respond-below(lg) {
        display: block;
        max-width: 446px;
        min-height: 47px;
        width: 100%;
        position: relative;
        z-index: 92;
        text-align: center;
        cursor: pointer;
        &:after {
          content: " ";
          position: absolute;
          // top: 50%;
          top: 0;
          right: 12px;
          width: 30px;
          height: 100%;
          // transform: tra
          background: url("../../../assets/icons/arrow.svg") center center /
            contain no-repeat;
        }
        .c-categories {
          &__el {
            pointer-events: none;
          }
        }
      }
    }
  }
  &-li {
    flex: 0 1 auto;
  }
  &-select {
    width: 100%;
  }
  @include respond-below(lg) {
    position: absolute;
    top: 0;
    max-width: 446px;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    flex-wrap: wrap;
    max-height: 0px;
    visibility: hidden;
    overflow: hidden;
    z-index: 90;
    transition: all 0.1s ease-in-out;
    background-color: #fff;

    &.isOpen {
      max-height: 470px;
      visibility: visible;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

      // IE fix
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: flex;
        width: 100%;
        position: absolute;
      }

      &:before {
        content: " ";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-select {
      display: block;
      max-width: 446px;
      margin: 25px auto;
      position: relative;
      z-index: 91;
      min-height: 47px;
      // width: 100%;
    }
    &-li {
      flex: 0 0 100%;
      text-align: center;
      // margin-top: -47px;
      &--active {
        order: -1;
      }
    }
    .c-categories__el {
      border-bottom: 2px solid $side-color-lightgrey;
      .c-categories__el-line {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}
