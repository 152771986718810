@import "src/styles/_colors.scss";

.c-event-registration {
  position: absolute;
  font-size: 14px;
  top: 0px;
  padding: 10px;
  background: #fff;
  border: 2px solid $side-color-darkgrey;
  margin: 5px 0px;
  max-width: 290px;
  opacity: 0;
  max-height: 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 100;
  visibility: hidden;
  &--open {
    opacity: 1;
    max-height: 500px;
    visibility: visible;
  }
  .input {
    min-height: 1px;
    label {
      font-size: 14px;
      input {
        padding: 5px 7px;
      }
    }
  }
}
