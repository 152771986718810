.cft-onboarding {
  position: fixed;
  z-index: 999998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &__item {
    position: fixed;
    box-shadow: 0px 0px 50px 5000px rgba(55, 58, 60, 0.9);
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 999997;
  }

  &__content {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
      max-width: 735px;
      margin: 0 auto;
      text-align: center;
      padding: 1rem;

      &.cft-onboarding__content__step3 {
        padding-right: 300px;
      }
    }

    .cft-onboarding__item__title {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    .cft-onboarding__item__subtitle {
      color: #fff;
      margin-bottom: 4rem;
    }
  }

  @media (max-width: 767px) {
    &__content {
      .cft-onboarding__item__title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }

      .cft-onboarding__item__subtitle {
        margin-bottom: 1rem;
      }

      .cft-onboarding__buttons__skip {
        margin-top: 1rem;
      }

      & > div {
        &.cft-onboarding__content__step3 {
          padding: 1rem;
        }
      }
    }
  }
}
