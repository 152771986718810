@import "src/styles/_colors.scss";

%basic-span-style {
  color: $black;
  font-family: "Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-left: 5px;
}

%basic-input-style {
  height: 24px;
  width: 24px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  outline: 2px solid $brand-color-green;
}

.radioButton {
  display: flex;
  flex-direction: row;
  align-content: center;

  label {
    position: relative;
    margin: 0;
    cursor: pointer;

    input {
      margin: 0;
      appearance: none;
      height: 24px;
      width: 24px;
      border-radius: 16px;
      border: 2px solid $brand-color-green;
      background-color: #ffffff;
      cursor: pointer;
    }

    .radioButton--active {
      height: 12px;
      width: 12px;
      background-color: $black;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 6px;
    }
  }

  span {
    @extend %basic-span-style;
  }

  .has-error {
    border: 2px solid $side-color-red;
  }
}
