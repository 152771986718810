.cft-report-navigation {
  width: 100%;
  background-color: #979797;
  min-height: 75px;
  position: fixed;
  left: 0;
  right: 0;
  top: 89px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cta {
    display: inline-flex;
    align-items: center;
    color: #fff;
    span {
      display: inline-flex;
      font-size: 0.813rem;
      margin-left: 0.5rem;
      text-transform: uppercase;
    }
  }

  .cft-button--isExport {
    margin-right: 0;
  }

  .cft-report-navigation__cta {
    transition: all 0.6s;
    border: 1px solid #ffffff;
    border-radius: 24px;
    padding: 0.2rem 1.2rem 0.2rem 1rem;
    margin-right: 1rem;

    &:hover {
      cursor: pointer;
      background-color: #fff;
      color: #979797;
    }
  }

  @media (max-width: 767px) {
    top: 56px;
    overflow-y: auto;

    .cft-report-navigation__actions,
    .cft-report-navigation__sections {
      flex-direction: row;
    }
  }
}
