@import 'src/styles/_colors.scss';

.link {
    height: 16px;

    &--cancel > a {
        font-family: "Sans", Arial,sans-serif;	
        font-size: 13px;	
        line-height: 16px;
        color: $side-color-red;
        letter-spacing: 0;
        width: auto;
        
        &:hover {
            font-weight: bold;
        }
    }

    &--follow > a {
        font-family: "Sans", Arial,sans-serif;	
        font-size: 13px;	
        line-height: 16px;
        color: $brand-color-green;
        letter-spacing: 0;
        width: auto;

        &:hover {
            font-weight: bold;
        }
    }
}