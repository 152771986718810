@import "src/styles/_breakpoints";
@import "src/styles/_colors.scss";
.market-price-desc{
  margin-top: -16px;
  color: grey;
  text-align: right;
  font-size: 0.8rem;
}

.MarketWidget {
  margin: 0 0 20px 0;
  font-size: 0.8125rem;
  line-height: 1em;



  .show-more {
    font-size: 13px;
    line-height: 1em;
    text-transform: none;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 700;
    color: $brand-color-green;
    transition: all 200ms ease;
    text-decoration: underline;
    &:hover {
      color: $brand-color-yellow !important;
      text-decoration-color: $brand-color-yellow !important;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin: 13px 0 11px 0;
    color: black;

    .details {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      @include respond-below("md") {
        span {
          display: none;
        }
      }

      * {
        min-width: max-content;
      }
    }
  }

  .title {
    color: black;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0;
    font-weight: 300;
  }

  .whole-country {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0.3rem;
    background: transparent;
    color: black;
    font-weight: 300;
    font-size: 12px;
    background-color: #ffa203;
    border-radius: 2px;
  }

  .time-period {
    display: inline-flex;
    color: black;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  .footer {
    background-color: #00965e;
    padding: 15px;
    display: flex;
    justify-content: center;
  }
  .prices-bar {
    display: flex;
    gap: 32px;
    background: #f7f7f7;
    border: 1px solid #eae9eb;
    border-radius: 6px;
    padding: 1.1em 1em 0.9em;
    min-height: 69px;
    @media (max-width: 1200px){
      min-height: 82px;
    }

    &__no-data {
      display: block;
      margin: auto;
      text-align: center;
    }
    &__tile {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
    }
    &__empty {
      font-weight: 700;
    }
    &__data {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 700;
      @media (max-width: 1200px) {
        gap: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    &__name {
      margin-bottom: 0.375em;
      padding-top: 0.5rem;
    }
    &__price {
    }
    &__change {
      display: flex;
      align-items: center;

      &--rise {
        color: #00965e;
        svg {
          transform: rotate(180deg);
        }
      }
      &--decline {
        color: #f20d0d;
      }
    }
  }
  .swiper {
    display: flex;
    align-items: center;
    width: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1IDE5LjVMNy41IDEyTDE1IDQuNSIgc3Ryb2tlPSIjMDA5NjVFIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      cursor: pointer !important;
      z-index: 1;
    }

    .swiper-button-prev {
      order: 1;
    }

    &-wrapper {
      width: calc(100% - 68px);
      max-width: calc(100% - 68px);
      order: 2;
      padding: 0 10px;
    }

    &-slide {
      padding-right: 1rem;
    }

    .swiper-button-next {
      order: 3;
      transform: rotate(180deg);
    }
  }
}
