.c-video {
  &__overlay {
    cursor: pointer;
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 128px;
      height: 146px;
      opacity: 0.7;
      background-image: url("./assets/icons/triangle.svg");
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.3s ease-in-out;
      z-index: 3;
    }
  }
  &__iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    border: 0;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  &.is-open {
    &.c-video {
      &:after {
        opacity: 0;
        visibility: hidden;
      }
    }
    .c-video {
      &__iframe {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--simple {
    width: 100%;
    padding-top: 56.25%;
    &:after {
      display: none;
    }
  }
}
