.c-add-filed-image {
  border: none;
  margin: 36px 0;

  & > div {
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .button--yellow {
      display: inline-flex;
      height: 48px;
      width: 207px;
      text-transform: uppercase;
      font-weight: 300;
      align-items: center;
      justify-content: center;
    }
  }

  &__formats {
    color: #808080;
  }

  &__info {
    font-weight: 500;
    width: 120px;
    text-align: center;
  }

  &--preview {
    position: relative;
    margin: 36px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 800px;
    }

    button {
      background-color: #ececec;
      color: #e92b5c;
      font-size: 14px;
      border: none;
      height: 48px;
      width: 97px;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  &--hide {
    display: none;
  }

  &-error {
    margin-top: -24px;
    color: #e92b5c;
  }
}
