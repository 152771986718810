.cft-group {
  width: 100%;
  border-top: 2px solid #979797;
  padding-top: 2.5rem;
  position: relative;
  margin-bottom: 4rem;

  &::after,
  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-left: 2px solid #979797;
    height: 1rem;
  }

  &::after {
    left: unset;
    right: 0;
  }

  &__header {
    position: absolute;
    display: flex;
    height: 42px;
    min-width: 226px;
    background-color: #979797;
    color: #fff;
    border-radius: 21px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    justify-content: center;
  }
}
