@import "src/styles/_colors.scss";

.header {
  &--menu {
    color: $brand-color-green;
    display: inline-block;
    height: 24px;

    p {
      font-family: "SansCond", Arial, sans-serif;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
