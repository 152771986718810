.cft-layout {
  background-color: #ececec;
  min-height: 100vh;
  overflow-x: hidden;

  &.cft-layout--withHeader {
    margin-top: 94px;
    min-height: calc(100vh - 94px);
  }

  &.cft-layout--withFooter {
    margin-bottom: 86px;
    min-height: calc(100vh - 86px);

    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
  }

  &.cft-layout--withTrim {
    min-height: calc(100vh - 180px);
  }

  @media (max-width: 767px) {
    &.cft-layout--withHeader {
      margin-top: 60px;
      min-height: calc(100vh - 60px);
    }
  }

  .hr-section {
    width: calc(96px + 100%);
    margin-left: -48px;
  }
}

.cft-section header p {
  &.soil-page__article {
    margin: 1.5rem auto;

    a {
      display: block;
      color: #00965e;
      text-decoration: underline;
    }
  }
}

.cft-wrapper {
  max-width: 970px;
  margin: auto;
}

.cft-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cft-form__box {
    display: flex;
    flex-direction: column;
    min-width: 162px;
    align-items: center;
    justify-content: center;

    svg {
      margin: 2rem auto 3rem auto;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.cft-headline {
  margin-top: 1.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;

  small {
    display: block;
    font-weight: 300;
    margin-top: 1.625rem;
    font-size: 0.813rem;
  }
}
.report-export-button {
  //position: absolute;
  //top: 1%;
  //right: 1%;
  z-index: 10000;
  width: auto;
}
.cft-section__report {
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }

  @media (max-width: 767px) {
    .cft-group {
      margin-top: 2rem;
    }

    .cft-info-box {
      p {
        word-break: break-word;
        display: inline-block;
        white-space: unset;
      }

      .cft-info-box__icon {
        margin: 0 0.5rem;
        min-width: 24px;
        min-height: 24px;
      }
    }

    .cft-actions-buttons {
      &.cft-actions-buttons--goBackCalc {
        flex-direction: row;
        span {
          font-size: 1rem !important;
        }
      }
    }

    .report-hero {
      & > .cft-button {
        justify-content: flex-start;
        margin-bottom: 1rem;
      }
    }
  }
}

#pdf {
  .container {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .row {
    margin: 0;
  }

  #report {
    padding-top: 2rem;
    min-width: 1200px;
  }

  .cft-cost-structure-table {
    display: none;
  }

  .cft-card {
    box-shadow: none;
  }

  .cft-header {
    position: relative;
    border: none;
    margin-bottom: 2rem;
  }

  #specificInformation {
    height: 18in;
  }
  #gasEmission {
    height: 17in;
  }

  .hide-on-pdf {
    display: none;
  }
}

.cft-disclaimer {
  padding: 1rem 2rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
}

.cft-max-wrapper {
  width: 100%;
  max-width: 570px;
  min-width: 570px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 767px) {
    min-width: 90vw;
  }
}

.cft-paper {
  width: 100%;
  max-width: 570px;
  min-width: 570px;
  padding: 20px 48px;
  background-color: white;
  margin: 0 auto;
  border-radius: 3px;
  @media (max-width: 767px) {
    min-width: 90vw;
  }
}

.cft-section-title {
  font-family: "SansCond", Arial, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  color: #00965e;
  text-transform: uppercase;
}

.cft-section-subtitle {
  font-weight: 400;
  font-size: 1.125rem;
  text-align: center;
}

.cft-wrapper-cb {
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
}

.cft-badge {
  padding: 0.375rem 0.5rem;
  background-color: #e5f5f0;
  color: #33ab7e;
  border-radius: 4px;
  text-transform: uppercase;
}

.cft-close-badge {
  overflow: hidden;
  position: relative;
  border: none;
  padding: 0;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background: #eae9eb;
  color: #585a5c;
  font: inherit;
  text-indent: 100%;
  cursor: pointer;
  transition: all 0.3s;

  &:focus {
    outline: solid 0 transparent;
    box-shadow: 0 0 0 2px #8ed0f9;
  }

  &:hover {
    opacity: 0.6;
  }

  &:before,
  &:after {
    position: absolute;
    top: 7px;
    left: 11px;
    width: 2px;
    height: 11px;
    border-radius: 0.125em;
    transform: rotate(45deg);
    background: currentcolor;
    content: "";
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.cft-henhouses-report {
  &__title {
    font-weight: 700;
    font-size: 1.375rem;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 1rem;

    span {
      font-weight: 400;
    }
  }

  .cft-paper {
    max-width: 864px;
    width: 100%;
  }

  .cft-report__summary__value {
    font-weight: 700;
    small {
      display: block;
      font-weight: 700;
    }
  }

  .cft-table {
    width: 100%;

    td:first-child,
    th:first-child {
      padding-left: 1rem;
      text-transform: none;
    }

    td:last-child,
    th:last-child {
      padding-right: 1rem;
    }

    td {
      font-weight: 700;
      &:first-child {
        color: #2e3033;
      }
    }

    &.cft-table--alignRight {
      td:last-of-type,
      th:last-of-type {
        text-align: right;
      }
    }
  }

  .cft-table-wrapper {
    border-radius: 4px;
    border: 1px solid #eae9eb;
    overflow: hidden;
  }
}

.cft-chicken-broiler-report-chart__legend {
  max-width: 325px;
  margin: 0 auto 2rem auto;
  font-size: 0.875rem;
  width: 100%;

  & > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    position: relative;

    & > span {
      display: flex;
      align-items: center;
      background-color: #fff;
      z-index: 10;

      &:first-child {
        padding-right: 8px;
      }

      &:last-child {
        padding-left: 8px;
        min-width: 53px;
        text-align: right;
      }

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #eae9eb;
      position: absolute;
      left: 0;
      bottom: 6px;
    }
  }
}

.back-coop {
  border: none;
  text-align: right;
  text-decoration-line: underline;
  color: #828385;
  font-weight: 400;
  font-size: 13px;
  background-color: transparent;
}

.recharts-surface {
  overflow: visible;
}
