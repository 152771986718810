@import "src/styles/_colors.scss";

.synoptic-comment {
  width: 384px;
  background-color: #ffffff;
  padding: 16px;
  // margin-bottom: 50px;
  // margin: 50px 0;
  position: relative;

  &--green {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    // width: 100%;
    width: auto;
    // flex: 0 0 180px;
    background-color: $brand-color-green;
    margin-bottom: 50px;
    header,
    .synoptic-comment__overflow {
      color: #ffffff;
    }
  }

  &--hide {
    // visibility: hidden;
    display: none;
  }

  &__overflow {
    max-height: 306px;
    overflow: auto;
  }
  &--collapsed {
    .synoptic-comment__overflow {
      max-height: 518px;
    }
  }

  header {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.comment-news {
  background-color: #197a56;
  border-radius: 0 0 0 5px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: #ffa203;
  text-transform: uppercase;
  + header {
    padding-right: 90px;
  }
}
