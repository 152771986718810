@mixin activeBox {
  transition: all 0.3s;
  border-color: rgba(0, 150, 94, 0.2);
  background-color: rgba(0, 150, 94, 0.2);
}

.cft-choose-box-container{
  display: flex;
  flex-direction: column;
  justify-content: center;

.cft-choose-box-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .cft-box-right {
    border: 1px solid #979797;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    min-height: 100px;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &.cft-box--isActive,
    &:hover {
      @include activeBox;
    }

    @media (min-width: 768px) {
      max-width: 210px;
      margin: 0.938rem;
    }
  }

  .cft-box-left {
    border: 1px solid #979797;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    min-height: 100px;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &.cft-box--isActive,
    &:hover {
      @include activeBox;
    }

    @media (min-width: 768px) {
      max-width: 210px;
      margin: 0.938rem;
    }
  }
}
}
