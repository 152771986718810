.real-estate-search {
  background-color: #00965e;
  padding: 25px 20px;
  color: #ffffff;
  & > h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }

  & > p {
    display: none;
    font-size: 16px;
    margin: 0 0 35px 0;
    padding-right: 85px;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .real-estate-search__group {
    width: 100%;
  }

  &__group {
    display: flex;
    width: 100%;
    .real-estate-inputs__text {
      width: 50%;
      &:first-of-type {
        margin-right: 12px;
      }
    }

    @media (min-width: 992px) {
      width: calc(100% - 170px);
    }
  }

  &__param {
    margin: 25px 0 12px 0;

    small{
      font-weight: 300;
    }
  }

  @media (min-width: 992px) {
    padding: 54px 45px 65px 45px;
  }

  &__clear {
    margin-bottom: -1rem;
    text-align: center;
    margin-top: 2rem;
  }
}
