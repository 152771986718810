.cft-section {
  & > header {
    background: #fff;
    text-align: center;
    padding-top: 4.125rem;
    padding-bottom: 2rem;

    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      color: #00965e;
      font-weight: 600;
      text-transform: uppercase;
      //margin-bottom: 1.5rem;
    }

    h4 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 1.375rem;
      color: #00965e;
      font-weight: 600;
      text-transform: uppercase;
      //margin-bottom: 1.5rem;
    }

    p {
      margin: 0 auto;
      max-width: 570px;

      &.full-width {
        display: block;
        width: 100%;
        max-width: unset;
        text-align: left;
      }
    }
  }

  &.cft-section__report {
    header {
      padding-top: 1.25rem;

      .cft-button {
        margin-bottom: 2rem;
      }

      @media (max-width: 767px) {
        font-size: 2rem;
      }
    }
  }

  main {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;

    & > .container {
      position: relative;
    }
  }

  &__withNoSpace {
    header {
      padding: 0;
    }
  }

  @media (max-width: 767px) {
    header {
      padding: 1rem 0;

      h3 {
        margin: 0;
      }
    }
    p {
      font-size: 16px;
      &.cft-report__summary__value {
        font-size: 2.5rem;
        small {
          font-size: 1rem;
        }
      }
    }
    main {
      padding: 1rem 0;
    }
  }

  &.cft-section__report {
    padding-top: 70px;
  }
}
