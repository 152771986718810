.cft-question-group {
  transition: all 0.3s;
  position: relative;
  width: 100%;
  border-top: 1px solid #979797;
  margin-top: 1.75rem;
  margin-bottom: 4.375rem;

  &::after,
  &::before {
    transition: all 0.3s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 72px;
    background-color: #979797;
  }

  &::after {
    right: 0;
    left: unset;
  }

  &__label {
    position: relative;
    text-align: center;
    margin-top: -14px;
    margin-bottom: 72px;
    span {
      transition: all 0.3s;
      height: 28px;
      padding: 0 1.25rem;
      border-radius: 14px;
      background-color: #000000;
      color: #ffffff;
      font-size: 0.813rem;
      text-transform: uppercase;
      min-width: 170px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    button {
      height: 28px;
      padding: 0 1.25rem;
      min-width: 83px;
      border-radius: 14px;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      outline: none;

      &:hover {
        transition: all 0.3s;
      }

      &.cft-question-group__remove {
        background-color: #e92b5c;
        color: #ffffff;
        border: 1px solid #e92b5c;
        right: 1.25rem;
        font-size: 14px;
        .cft-question-group__remove__group {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0;
          }
        }

        &:hover {
          background-color: #ffffff;
          color: #e92b5c;
        }
      }

      &.cft-question-group__hide {
        background-color: #00965e;
        color: #ffffff;
        border: 1px solid #00965e;
        left: 1.25rem;
        padding: 0;
        min-width: 40px;

        svg {
          transition: transform 0.3s;
          transform: scaleY(-1);
          fill: #fff;
        }

        &:hover {
          background-color: #ffffff;
          svg {
            fill: #00965e;
          }
        }

        &.cft-question-group__hide--isCollapsed {
          svg {
            transform: scaleY(1);
          }
        }
      }
      @media (max-width: 767px) {
        &.cft-question-group__remove {
          padding: 0;
          margin: 0;
          min-width: 40px;
          right:0;
        }
        &.cft-question-group__hide{
          left:0;
        }
        .cft-question-group__remove__group {
          display: flex;
          flex-direction: row;
          .cft-question-group__remove__text {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    transition: max-height 0.6s ease-in;
    overflow: hidden;

    &.cft-question-group__content--isCollapsed {
      transition: max-height 0.6s ease-out;
      max-height: 0;
    }
  }

  &.cft-question-group--withoutMarginBottom {
    margin-bottom: 0;
  }
  &.cft-question-group--withoutMarginTop {
    margin-top: 0;
  }

  &.cft-question-group--toRemove {
    transition: opacity 0.3s;
    opacity: 0.1;
  }

  &.cft-question-group--hasError {
    border-color: #e92b5c;

    &::after,
    &::before {
      background-color: #e92b5c;
    }

    .cft-question-group__label {
      span {
        background-color: #e92b5c;
      }
    }
  }
}
