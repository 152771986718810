.e-input {
  width: 100%;
  border: 2px solid #e9e9e9;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 15px;
  font-weight: 300;

  &:focus {
    border-color: #00965e;
    outline-color: #00965e;
  }
}
