@import "src/styles/_colors.scss";

.registration-benefits-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .provider-img {
    width: auto;
    max-height: 80px;

    &--small {
      max-height: 40px;
      width: auto;
    }
  }

  .provider-title {
    margin-top: 64px;
    margin-bottom: 16px;
    color: #9ca4ab;
  }

  .content-title {
    margin: 32px 0 32px 0;
    color: #00965e;
    -webkit-align-self: center;
    align-self: center;
    display: block;

    display: inline-block;
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0;

    &--small {
      margin: 48px 0 16px 0;
      color: #00965e;
      -webkit-align-self: center;
      align-self: center;
      display: block;

      display: inline-block;
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0;
    }
  }

  .pros-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;

    p {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: $black;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 0 10px;
    }

    &--small {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0;
      p {
        font-family: "Sans", Arial,sans-serif;
        color: $black;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0 0 0 10px;
        font-size: 14px;
      }
    }
  }

  img {
    height: 200px;
    width: 70%;
    object-fit: cover;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);

    > :first-child {
      grid-row: 1;
    }
    > :nth-child(2) {
      grid-row: 4;
    }
    > :nth-child(3) {
      grid-row: 2;
    }
    > :nth-child(4) {
      grid-row: 5;
    }
    > :nth-child(5) {
      grid-row: 3;
    }
    > :nth-child(6) {
      grid-row: 6;
    }

    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto);

      > :first-child {
        padding-right: 15px;
        grid-row: 1;
      }
      > :nth-child(2) {
        grid-row: 1;
      }
      > :nth-child(3) {
        grid-row: 2;
      }
      > :nth-child(4) {
        grid-row: 2;
      }
      > :nth-child(5) {
        grid-row: 3;
      }
      > :nth-child(6) {
        grid-row: 3;
      }
    }
  }
}
