@import "src/styles/_colors.scss";

%basic-label-style {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  // flex-direction: column;
  color: $black;
  font-family: "Sans", Arial,sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

%basic-input-style {
  width: 100%;
  min-width: 200px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  color: $black;
  font-family: "Sans", Arial,sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  padding: 10px 15px;
}

.input {
  min-height: 70px;
  margin-bottom: 5px;
  label {
    @extend %basic-label-style;
    position: relative;

    .input-icon-container {
      position: absolute;
      right: 10px;
      // top: 15%;
      top: 50%;
      transform: translateY(-50%);
      align-self: center;
      cursor: pointer;
    }

    input {
      @extend %basic-input-style;
      border: 2px solid $brand-color-green;
      outline: none;
    }
    .input {
      &-label-text {
        margin-bottom: 5px;
      }
      &-field {
        width: 100%;
        flex: 0 0 100%;
        position: relative;
        &--icon {
          input {
            padding-right: 33px;
          }
        }
      }
    }

    .input-wrapper {
      @extend %basic-input-style;
      border: 2px solid $brand-color-green;
      display: flex;
      input {
        border: none;
        width: auto;
        max-width: none;
        padding: 0;
        box-shadow: none;
        outline: none;
        // background-color: #ffffff;
        // box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        // color: $black;
        // font-family: "Sans", Arial,sans-serif;
        // font-size: 14px;
        // font-weight: 300;
        // line-height: 20px;
        // padding: 10px 15px;
      }
    }
  }

  &--active {
    label {
      @extend %basic-label-style;

      input {
        @extend %basic-input-style;
        border: 2px solid $black;
      }
    }
  }

  &--error {
    label {
      @extend %basic-label-style;

      input {
        @extend %basic-input-style;
        border: 2px solid $side-color-red;
      }

      p.input-error {
        margin: 5px 0 0 0;
        color: $side-color-red;
        text-align: right;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}
