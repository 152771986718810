@import "~react-image-gallery/styles/scss/image-gallery.scss";

.real-estate {
  .breadcrumbs {
    padding: 21px 0;
  }

  &.real-estate--lp {
    overflow-x: hidden;
    width: 100%;
    .breadcrumbs {
      a {
        color: #ffffff;
      }
    }
  }

  .real-estate__note {
    padding: 1rem 20px;
    @media (min-width: 992px) {
      padding: 1rem 45px 1rem 45px;
    }
    background-color: rgba(0, 150, 94, 0.8);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: white;
    text-transform: uppercase;
  }

  .real-estate__landing-page {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }

  .real-estate__landing-page__title {
    h1 {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 64px;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      font-family: "SansCond", Arial, sans-serif;
      text-transform: uppercase;
    }

    h5 {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.52;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
    margin-bottom: 340px;
  }

  .real-estate__hero {
    margin-top: -200px;
  }
}

.single-real-estate {
  &__title {
    h1 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      font-stretch: condensed;
      color: #00965e;
      text-transform: uppercase;
      line-height: 1;
      margin: 0 0 1rem 0;
    }
  }

  &__address {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #2e3033;
    margin-bottom: 26px;
    svg {
      height: 20px;
      width: auto;
      margin-right: 0.75rem;

      path {
        fill: black;
      }
    }
  }

  &__details {
    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      font-stretch: condensed;
      color: #00965e;
      text-transform: uppercase;
      line-height: 1;
    }
  }

  &__details--info {
    margin-top: 42px;

    h6 {
      font-size: 18px;
      line-height: 1.33;
      color: #2e3033;
      margin: 1rem 0 1rem 0;

      &.mt {
        margin-top: 1rem;
      }

      &.icon {
        svg {
          height: 28px;
          width: auto;
          margin-right: 0.75rem;
        }
      }

      @media (min-width: 992px) {
        margin: 0 0 1rem 0;
      }
    }

    hr {
      display: block;
      width: 100%;
      height: 1px;
      background-color: black;
      margin: 42px 15px 0 15px;
      border: none;
    }
  }

  &__data {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
    color: #2e3033;

    &.icon {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: #00965e;

      svg {
        height: 20px;
        width: auto;
        margin-right: 0.75rem;

        path {
          fill: #00965e;
        }
      }
    }
  }

  &__note {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    color: #2e3033;
    white-space: break-spaces;
  }

  &__share {
    margin-top: 24px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  &__image {
    background-color: #e3e3e3;
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      path,
      ellipse {
        fill: white;
      }
    }
  }

  &__carusel {
    margin-bottom: 4rem;
  }

  &__promoted {
    display: inline-block;
    background-color: #ffa203;
    text-align: center;
    color: #2e3033;
    font-size: 14px;
    padding: 12px 20px 10px 20px;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
}

.single-real-estate__gallery {
  .image-gallery-thumbnails-wrapper {
    padding: 0 50px;
  }

  .image-gallery-right-nav {
    right: 0;
  }

  .image-gallery-left-nav {
    left: 0;
  }
  .image-gallery-right-nav,
  .image-gallery-left-nav {
    padding: 0 1rem;
    bottom: -80px;
    top: unset;
    transform: none;
    filter: none;
    .image-gallery-svg {
      color: #2e3033;
      height: 1.5rem;
      width: auto;
    }
  }

  .image-gallery-thumbnail {
    width: 200px;

    &:hover,
    &.active {
      border-color: #00965e;
    }
  }
}
