@import 'src/styles/_colors.scss';

%text-style {
    padding:7px 8px 5px 8px;
    display: inline-block;
    color: #FFFFFF;
    border-radius: 2px;
    font-size: 12px;
    font-family: "Sans", Arial,sans-serif;	
    line-height: 12px;	
    text-align: center;
    margin: 5px;
}

.tag {
    &--active {
        @extend %text-style;
        background-color: $brand-color-yellow;
    }

    &--basic {
        @extend %text-style;
        background-color: $side-color-darkgrey;
    }
}