@import "src/styles/_colors.scss";

$containerWidth: 586px;
$verticalPadding: 114px;
$horizontalPadding: 40px;

.calculator-container {
  .offer-container {
    width: 100%;
    max-width: $containerWidth;
    min-height: 200px;
    background-color: #fff;
    padding: $horizontalPadding $verticalPadding $horizontalPadding $verticalPadding;
    border: 1px solid #f2f2f2;

    @media (max-width: 576px) {
      padding: $horizontalPadding / 4 $verticalPadding / 4;
    }

    &--dark {
      background-color: $side-color-lightgrey;
    }

    .typography--h2 {
      width: 100%;
      color: $black;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      margin: 5px 0 30px;
    }

    .back-button-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .offer-calculation-details {
      margin-bottom: 34px;

      .offer-calculation-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 58px;
        border-top: 1px solid #d8d8d8;

        // IE hacks
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
          height: 58px;
        }

        @media (max-width: 576px) {
          flex-wrap: wrap;
          padding: 10px 0;
        }

        &:last-child {
          border-bottom: 1px solid #d8d8d8;
        }

        .offer-calculation-detail--description {
          font-size: 16px;
          color: #2e3033;
          letter-spacing: -0.3px;
          line-height: 24px;
        }

        .offer-calculation-detail--value {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $brand-color-green;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
          min-width: fit-content;
        }
      }
    }

    .typography--h2 {
      width: 100%;
      color: $black;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .summary-button-container {
      margin-top: 30px;
    }
  }

  .offer-calculation-description {
    text-align: center;
  }

  .computed-value-description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .computed-value-description--summary {
    @extend .computed-value-description;

    margin-bottom: 0.1rem;
    justify-content: flex-start;
  }

  .computed-value {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-color-green;
  }

  .computed-value--summary {
    @extend .computed-value;

    margin-bottom: 0.1rem;
    justify-content: flex-start;
  }
}
