@import "src/styles/_colors.scss";

.loading {
  min-height: calc(100vh - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  &--abs {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 1px;
    top: 0;
    left: 0;
    background-color: rgba(#fff, 0.6);
  }
  &--small {
    min-height: 200px;
  }
  .spinner-border {
    color: $brand-color-green;
  }
}
