.cultivation-details-page {
  .cft-header {
    z-index: 1001;
  }
  .nav-langs {
    margin-right: 0;
  }

  .button--green {
    &:hover {
      background-color: transparent;
    }
  }

  .cft-actions-buttons--cancel {
    svg,
    path {
      fill: #FD0059;
    }
    @media (max-width: 767px) {
      display: flex;
      width: 70px;
      span {
        display: block;
        color: #585A5C;
        font-size: 14px;
        font-weight: 400;
      }
    }

  }

  .header-title {
    font-size: 0.95rem;
    font-weight: 700;
    margin-top: 0.5rem;
    text-align: center;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .breadcrumbs {
    text-align: left;
    position: relative;
    bottom: 40px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      bottom: 10px;
    }
  }

  .cft-layout.cft-layout--withFooter {
    margin-bottom: 0;
  }

  .footer__footer-menu {
    display: none;
  }

  .headline {
    .cft-label__label {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;

      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }

    @media (max-width: 767px) {
      .cft-tooltip {
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }

  .regagri-location {
    @media (max-width: 767px) {
      &>div:first-child {
        text-align: left;
        position: relative;
        padding-right: 40px;
      }
    }

    .name-container {
      position: relative;

      .map-btn {
        position: absolute;
        right: -120px;
        top: 33px;

        p {
          display: flex;
        }

        .loading {
          position: absolute;
          right: 11px;
          top: 9px;
          min-height: auto;
          .spinner-border {
            color: #fff;
            border-width: 0.15rem;
            width: 1.25rem!important;
            height: 1.25rem!important;
          }
        }

        @media (max-width: 767px) {
          margin-top: 20px;
          position: static;
        }

        svg {
          margin-top: 2px;
          margin-left: 5px;
        }

        &>div {
          &:hover {
            .loading {
              .spinner-border {
                color: #009d5c;
              }
            }
            path {
              fill: #009d5c;
            }
          }
        }
      }

      .coords {
        font-size: 12px;
        color: #828385;
        margin: 10px 0;
      }

      @media (min-width: 768px) {
        .change {
          text-align: center;
        }
      }
    }

    .map {
      width: 100%;
      max-width: 920px;
      margin: 0 auto;
      padding: 10px 0 50px 0;
      text-align: center;

      h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 20px;
      }

      &__buttons {
        margin-top: 20px;

        &>div:first-child {
          margin-right: 30px;
        }
      }

      .leaflet-container {
        height: 400px;
        width: 100%;
      }
    }
  }

  .regagri-soil-types {
    margin-top: 80px;

    @media (max-width: 767px) {
      margin-top: 40px;

      &>div:first-child {
        text-align: left;
        position: relative;
        padding-right: 40px;
      }
    }

    .loading {
      min-height: auto;
      margin-top: 20px;
    }

    .cft-box {
      max-height: 85px;
      max-width: 235px;
    }

    .types {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      max-width: 690px;
      margin: auto;

      .box {
        width: 40%;

        @media (max-width: 767px) {
          width: 100%;
          margin-top: 10px;
        }

        .cft-box {
          margin: 30px 0 0 0;

          @media (max-width: 767px) {
            margin: 0;
            max-width: 100%;
            min-height: 60px;
            justify-content: center;
          }
        }

        .buttons {
          display: flex;
          justify-content: center;

          @media (max-width: 767px) {
            margin: 10px 0 0 0;
            justify-content: start;
            align-items: baseline;
          }

          .change {
            margin-top: 15px;
            margin-right: 30px;
            text-align: center;

            @media (max-width: 767px) {
              margin: 10px 0 0 0;
            }
          }

          .delete {
            margin-top: 15px;

            p {
              color: #FD0059;
            }
          }
        }
      }

      .area {
        width: 60%;
        margin-top: 40px;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

    }

    .organic-matter {
      @media (max-width: 767px) {
        position: relative;

        .cft-tooltip {
          position: absolute;
          right: 0;
        }
      }
    }

    .all-types {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .cft-box {
        margin: 5px;

        @media (max-width: 767px) {
          max-width: 100%;
          justify-content: center;
          min-height: 60px;
          margin: 5px 0;
        }
      }
    }

    .add-btn {
      margin-top: 20px;
      text-align: center;

      @media (max-width: 767px) {
        text-align: left;
      }
    }
  }

  .regagri-fertilizers {
    margin-top: 80px;

    @media (max-width: 767px) {
      margin-top: 40px;

      &>div:first-child {
        text-align: left;
        position: relative;
      }
    }

    .quantity {
      @media (max-width: 767px) {
        position: relative;

        .cft-tooltip {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .regagri-practices {
    margin-top: 80px;

    @media (max-width: 767px) {
      margin-top: 40px;

      &>div:first-child {
        text-align: left;
        position: relative;
      }
    }

    .disclaimer {
      font-size: 12px;
      font-style: normal;
    }

    h4 {
      font-size: 13px;
      margin-top: 20px;
    }

    .types {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;

      .cft-box {
        max-width: 235px;
        margin: 5px;

        @media (max-width: 767px) {
          max-width: 100%;
          min-height: 60px;
          margin: 0 0 5px 0;
          justify-content: space-between;
          flex-direction: initial;
        }
      }

      .arrow {
        transform: rotate(270deg);
        transition: transform .3s ease;

        svg,
        g,
        polygon {
          fill: #2E3033;
        }

        &--is-open {
          transform: rotate(90deg);
          transition: transform .3s ease;
        }
      }
    }

    .checkboxes {
      max-width: 970px;
      margin: 20px auto 0 auto;

      @media (max-width: 767px) {
        margin: 20px auto 0 0;
      }

      &>div {
        margin-bottom: 15px;
      }

      .checkbox {
        .text {
          display: inline-block;
          text-align: left;
        }
        &>span {
          strong {
            margin-right: 5px;
          }

          &>div {
            min-width: initial;
          }

          .cft-tooltip {
            color: #fff;
            font-weight: 400;
            line-height: 1.7rem;
            font-size: 0.8rem;
          }
        }
      }
    }
    .form-error {
      margin-top: 0.25rem;
      font-size: 0.813rem;
      color: #fd0059;
      width: 100%;
      text-align: left;
      max-width: 970px;
      margin: 20px auto 0 auto;
    }
  }

  .submit-btn {
    text-align: center;
    @media (max-width: 767px) {
      text-align: left;
    }

    button {
      font-size: 14px;
      padding: 10px;
      text-transform: uppercase;
      color: #fff;
      &:hover {
        color: #00965E;
      }
      &:focus {
        outline: 0px;
      }
    }
  }
  .regagri-report {
    background-color: #fff;
    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      color: #00965e;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 60px;
      @media (max-width: 767px) {
        margin-bottom: 40px;
        margin-top: 20px;
      }
    }
    label {
      margin-bottom: 30px;
    }
    .details {
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
      p {
        border-bottom: 1px solid #ABACAC;
        padding-bottom: 15px;
        strong {
          margin-right: 5px;
        }
      }
    }
    .explorer {
      @media (max-width: 767px) {
        label {
          width: 100%;
          border-bottom: 1px solid #ABACAC;
          padding-bottom: 40px;
          margin: 0;
        }
      }
      .custom-border {
        min-height: 240px;
        border-bottom: 1px solid #ABACAC;
        border-top: 1px solid #ABACAC;
        border-left: 0px;
        border-right: 0px;
        @media (max-width: 767px) {
          min-height: initial;
          border-top: 0px;
        }
      }
      .row {
        h4 {
          font-size: 16px;
          padding: 20px 0;
          margin-bottom: 0;
        }
        .number {
          font-family: "SansCond", Arial, sans-serif;
          font-size: 2.5rem;
          color: #00965e;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 0;
          line-height: 2.5rem;
        }
        .unit {
          color: #828385;
          font-size: 13px;
          font-weight: 700;
          span {
            display: block;
            margin-top: 8px;
          }
        }
        h6 {
          font-size: 11px;
          font-weight: 700;
          color:#2E3033;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }
    }
    .emissions {
      label {
        margin-top: 50px;
        margin-bottom: 20px;
      }
      .table {
        h4 {
          font-size: 16px;
          margin-bottom: 15px;
          margin-top: 25px;
        }
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          height: 40px;
          &:nth-child(2n) {
            background-color: #EAE9EB;
          }
          p {
            text-transform: uppercase;
            font-weight: 700;
            margin: 0;
            font-size: 11px;
            line-height: 11px;
            color: #2E3033;
            &:nth-child(2) {
              color: #00965E;
            }
          }
        }
      }
    }
    .charts {
      margin-top: 30px;
      label {
        margin-top: 30px;
      }
      .emission, .soil-ogranic-matter {
        position: relative;
        @media (max-width: 767px) {
          .recharts-legend-wrapper {
            bottom: -20px!important;
          }
        }
      }

      .unit {
        position: absolute;
        font-size: 16px;
        font-weight: 700;
        transform-origin: top left;
        left: -10px;
        top: 56%;
        transform: rotate(-90deg) translateX(-50%);
      }
      .legend {
        position: relative;
        bottom: 30px;
        left: 60px;
        font-size: 16px;
        font-weight: 700;
        @media (max-width: 767px) {
          left: 0px;
          bottom: 57px;
        }
      }
    }
    .more {
      margin-top: 40px;
      label {
        display: block;
        border-bottom: 1px solid #ABACAC;
        padding: 20px 0;
        margin: 0;
        a {
          font-weight: 400;
          text-decoration: underline;
          color: #00965E;
        }
      }
      .description {
        margin-top: 20px;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
}

.practices-modal-content {
  margin: 90px 0 0 0;

  @media (max-width: 767px) {
    margin: 60px 0 0 0;
    padding: 20px;
  }

  h4 {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 20px;
  }

  .save-button {
    margin-top: 20px;
    text-align: center;
  }
}


.tools-page__modal {
  @media (max-width: 1100px) {
    padding: 20px 10px;
  }
  text-align: center;
  padding: 50px 80px;
  img {
    border-radius: 50%;
    @media (max-width: 767px) {
      max-width: 80px;
    }
  }
  h1 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    text-transform: uppercase;
  }
  p.text {
    margin-bottom: 30px;
  }
  &--close {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      height: 78px;
      width: 78px;
      path {
        fill: #FD0059;
      }
    }

    &:focus {
      outline: none;
    }
    &:hover {
      svg {
        path {
          fill: #00965e;
        }
      }
    }
  }
}

.regagri-report--full-screen-chart {
  overflow-y: hidden!important;
  .recharts-surface {
    transform: rotate(270deg);
  }
  .modal-content {
    height: calc(100vh - 65px);
    justify-content: center;
    align-items: center;
  }
  .recharts-responsive-container {
    width: calc(100vh - 120px)!important;
    
    @media (min-width: 320px) and (max-width: 480px) {
      width: calc(120vh)!important;
      transform: scale(60%);
    }
  }

  .recharts-default-legend {
    position: relative;
    bottom: 180px;
    left: 220px!important;
  }

  .recharts-default-legend {
    transform: rotate(270deg);
    position: relative;
    left: 60px;
    @media (min-width: 320px) and (max-width: 480px) {
      left: 250px!important;
      transform: rotate(270deg) scale(150%);
    }
  }
  span.legend {
    position: absolute;
    bottom: 18px;
    right: 70px;
    transform: rotate(270deg);
    @media (min-width: 320px) and (max-width: 480px) {
      right: 35px;
      bottom: 30px;
    }
  }
  span.unit {
    transform: rotate(180deg);
    position: absolute;
    bottom: 15px;
    @media (min-width: 320px) and (max-width: 480px) {
      bottom: 30px;
    }
  }
  .headline {
    transform: rotate(270deg);
    position: absolute;
    left: -70px;
    @media (min-width: 320px) and (max-width: 480px) {
      left: -82px;
    }
  }
  .tools-page__modal--close {
    left: 0;
    right: initial;
  }
}