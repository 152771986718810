@import "src/styles/_breakpoints";

.c-poll {
  &-chart {
    width: 100%;
    &__pie {
      max-width: 200px;
      margin: 0 auto 15px;
      text {
        text-anchor: middle;
        font-family: "Sans", Arial,sans-serif;
        font-size: 16px;
        font-weight: bold;
        // fill: #fff;
      }
      &-slices {
        stroke: #fff;
      }
    }
  }
  &-results {
    flex: 1 1;
    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-family: "Sans", Arial,sans-serif;
      font-weight: bold;
      font-size: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__val {
      flex: 0 0 52px;
      height: 30px;
      color: #fff;
      text-align: center;
      padding: 5px;
      border-radius: 3px;
      margin-right: 11px;
    }
    &__txt {
      flex: 1;
      line-height: 18px;
    }
  }
  @include respond-between(lg, md) {
    // @include respond-above(sm) {
    &-chart {
      display: flex;
    }
    &-results {
      order: -1;
      padding-top: 20px;
    }
    // }
  }
}
