@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints";

.c-poll {
  background-color: #f8f8f8;
  border: 2px solid #ececec;
  position: relative;
  width: 100%;
  &--side {
    flex: 1;
    padding: 32px;
    padding-bottom: 100px;
    @include respond-above(lg) {
      margin-left: -2px;
    }
    @include respond-below(lg) {
      margin-top: -2px;
    }
    form .bttn {
      // max-width: 115px;
      left: 32px;
      min-width: 115px;
    }
    .c-poll-chart__pie {
      &-slices {
        stroke: #f8f8f8;
      }
    }
  }
  &--slide {
    padding: 17px 12px 90px;
    form .bttn {
      margin-top: 30px;
    }
  }
  &--voted {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 32px;
    // justify-content: center;
    &-2 {
      align-content: flex-start;
      align-items: flex-start;
    }
  }
  &--big {
    background-color: #fff;
    padding: 45px 66px 66px;
    .c-poll {
      &-heading {
        display: none;
      }
      &-question {
        font-weight: bold;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column-reverse;

    & > div {
      order: -1;
    }

    .bttn {
      min-width: 115px;
    }
  }
  &-heading {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: $brand-color-green;
    text-transform: uppercase;
    line-height: 18px;
    font-family: "SansCond", Arial, sans-serif;
    &__row {
      flex: 0 0 100%;
    }
    &-2 {
      margin-bottom: 24px;
    }
  }
  &-question {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &-answer {
    display: flex;
    margin-bottom: 20px;
    input {
      display: none;
      &:checked {
        + .c-poll-answer__replacement {
          background-color: $brand-color-yellow;
        }
      }
    }
    &__txt {
      flex: 1;
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-left: 7px;
    }
    &__replacement {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: 2px;
      border: 1px solid #a8a8a8;
      background-color: #dedede;
    }
  }
  &-info {
    flex: 0 0 100%;
    text-align: center;
    img {
      margin-bottom: 30px;
    }
    .bttn {
      max-width: 185px;
      margin: 30px auto;
    }
  }
  &--notuser {
    color: $brand-color-green;
  }
  &--big {
    padding: 25px;
    .d-flex.mb-4 {
      display: none !important;
    }
    .c-poll-chart {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include respond-above(md) {
        .c-poll-results {
          order: -1;
        }
      }
      &__pie {
        margin-bottom: 0;
        margin-right: 30px;
      }
    }
  }
  &--space-top {
    margin-top: 20px;
  }
}
