.cft-button {
  border: 1px solid #979797;
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;

  &.cft-button--noMargin {
    margin: 0 !important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    transition: all 0.3s;
    border-color: rgba(0, 150, 94, 0.2);
    background-color: rgba(0, 150, 94, 0.2);
  }

  &.cft-button--isActive {
    transition: all 0.3s;
    border-color: #00965e;
    background-color: #00965e;
    color: #fff;
  }

  &.cft-button--isExport {
    transition: all 0.3s;
    border-color: #00965e;
    background-color: #00965e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.cft-button--isImport {
    color: #fff;
    padding: 0;
    border: 0;
    background-color: #00965e;

    svg {
      transform: rotate(180deg);
    }
  }

  &.cft-button--isHighlighted {
    background-color: #ffa203;
    border-color: #ffa203;

    &.cft-button--isActive {
      color: #000;
    }

    &:hover {
      background-color: transparent;
      color: #ffa203;
    }
  }

  &.cft-button--isTransparent {
    background-color: transparent;
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: #000;
    }
  }

  &.cft-button--isText {
    margin: 0;
    background-color: transparent;
    border-color: #fff;
    color: #000;

    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: #000;
      opacity: 0.5;
    }
  }

  &.cft-button--isInvalid {
    background-color: #efbcbc;
    border: 1px solid #efbcbc;

    &:hover {
      opacity: 0.5;
    }
  }

  @media (min-width: 768px) {
    margin: 0.938rem;
    min-width: 110px;
    width: auto;
    height: 50px;
  }

  @media (max-width: 990px) {
    &.cft-button--isExport {
      transition: all 0.3s;
      background-color: transparent;
      color: #00965e;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  @media (max-width: 767px) {
    &.cft-button--isExport {
      transition: all 0.3s;
      background-color: transparent;
      color: #00965e;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;
    }
    &.cft-button--isImport {
      color: #fff;
      padding: 0;
      border: 0;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.cft-button--isFullwidth {
    @media (min-width: 768px) {
      width: calc(100% - 1.876rem);
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.cft-button--isRounded {
    border-radius: 3px;
  }

  &.cft-button--isAlternative {
    font-weight: 400;
    height: 48px;
    padding: auto 1rem;

    &:hover {
      opacity: 0.6;
    }
  }

  &.cft-button--isNegative {
    border-color: #00965e;
    color: #00965e;
    background-color: #fff;
    border-width: 2px;
  }
}
