.cft-progress {
  width: 100%;
  .cft-progress__bar {
    position: relative;
    background-color: #fefefe;
    border-radius: 10px;
    height: 15px;
    overflow: hidden;
    z-index: 1;

    .cft-progress__bar__fill {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: block;
      border-radius: 10px;
      transition: width 0.5s ease-in-out;
    }
  }

  &.cft-progress--isHeader {
    .cft-progress__bar {
      height: 6px;
      border-radius: 0;

      .cft-progress__bar__fill {
        border-radius: 0;
      }
    }
  }

  &.cft-progress--withValueIndicator {
    .cft-progress__bar {
      background-color: transparent;
    }
    .cft-progress__value {
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      z-index: 2;
      width: 30px;
      font-size: 0.813rem;
      line-height: 19px;
      text-align: center;
      transition: left 0.5s ease-in-out;
      font-weight: 600;
    }
  }
}
