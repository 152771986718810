.c-tag {
  text-transform: lowercase;
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  background-color: #6d6e70;
  padding: 4px 6px;
  border-radius: 2px;
  margin-right: 6px;
  margin-bottom: 6px;
  font-weight: 300;

  &__active {
    background-color: #00965e;
  }

  &__clickable {
    cursor: pointer;
  }

  span {
    display: inline-block;
    margin-left: 6px;
    cursor: pointer;

    path {
      fill: white;
    }
  }
}
