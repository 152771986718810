.c-events-aside {
  margin-top: 30px;

  & > div {
    margin-bottom: 30px;
  }
}

.c-events {
  margin-top: 30px;

  &__group {
    font-size: 36px;
    font-family: "SansCond", Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    border-top: 2px solid #e9e9e9;
    padding-top: 26px;
  }

  &__filter {
    margin-bottom: 15px;
    color: rgba(46, 48, 51, 0.4);
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    align-items: center;
    align-content: center;
    display: flex;
  }

  .c-radio-group {
    display: inline-block;
    color: #2e3033;
    font-size: 14px;
    text-transform: lowercase;
    font-weight: 300;
    margin-left: 15px;
    margin-bottom: 0;
  }
}

.c-event {
  background-color: #00965e;
  color: white;
  display: flex;
  margin-bottom: 30px;
  height: 320px;

  @media (max-width: 767px) {
    height: auto;
  }

  &__details {
    padding: 30px;
    width: 100%;
    position: relative;

    &__date {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 20px;
      font-weight: 600;
    }

    h3 {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 30px;
    }

    p {
      max-height: 72px;
      height: 72px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 300;
    }

    a {
      position: absolute;
      bottom: 30px;
      left: 30px;

      @media (max-width: 767px) {
        position: relative;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__cover {
    min-width: 300px;
    background-position: center;
    background-size: cover;
  }
}
