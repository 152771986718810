@import "src/styles/_colors.scss";

.select-label {
  margin-bottom: 0;
}

.select {
  &__input-error {
    margin: 1px 0 0 0;
    color: #e92b5c;
    text-align: right;
    font-size: 13px;
    line-height: 16px;
  }
}
