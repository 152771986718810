.cft-tooltip {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1.7rem;
  background-color: rgba(0, 157, 92, 1);
  color: #ffffff;
  border-radius: 50%;
  font-weight: 400;
  font-size: 0.8rem;
  margin: 0 0.5rem 0 0.5rem;
}

.tippy-box[data-theme~="green"] {
  background-color: rgba(0, 157, 92, 1);
  color: #ffffff;
}
.tippy-box[data-theme~="green"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: rgba(0, 157, 92, 1);
}
.tippy-box[data-theme~="green"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: rgba(0, 157, 92, 1);
}
.tippy-box[data-theme~="green"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: rgba(0, 157, 92, 1);
}
.tippy-box[data-theme~="green"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: rgba(0, 157, 92, 1);
}
