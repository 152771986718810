.cft-radio-field {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  .cft-radio-field__group {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      height: 2px;
      background-color: #00965e;
      top: 8px;
    }
  }
  .cft-radio-field__input {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 300;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 18px;
    width: 18px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .cft-radio-field__checkmark {
        &:after {
          transition: background-color 0.3s;
          background-color: #00965e;
        }
      }

      &:hover ~ .cft-radio-field__checkmark {
        &:after {
          transition: background-color 0.3s;
          background-color: rgba(#00965e, 0.5);
        }
      }
    }

    .cft-radio-field__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #d1d1d1;

      &:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }

    .cft-radio-field__label {
      position: absolute;
      top: 20px;
      white-space: nowrap;
      display: block;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.cft-radio-field--isInvalid {
    .cft-radio-field__checkmark {
      background-color: #efbcbc;
      border: 1px solid #efbcbc;

      &:after {
        background-color: #efbcbc;
      }
    }

    .cft-radio-field__group {
      &:before {
        background-color: #fd0059;
      }
    }
  }

  .cft-error {
    margin-top: 3rem;
  }
}
