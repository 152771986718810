.iterator-list {
  margin-top: 30px;
  counter-reset: sekcja;
  list-style-type: none;
  padding-left: 0;
  li {
    // position: relative;
    display: flex;
    margin-bottom: 20px;
    padding-top: 5px;
    &:before {
      counter-increment: sekcja;
      content: counters(sekcja, ".") " ";
      flex: 0 0 25px;
      width: 25px;
      height: 25px;
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
      margin-top: -2.5px;
      margin-right: 11px;
      background-color: #00965e;
      border-radius: 50%;
    }
  }
  &__body {
    flex: 1;
  }
  &__title {
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    display: block;
    width: 100%;
  }
  &__desc {
    font-size: 13px;
    color: #2e3033;
    letter-spacing: 0;
    line-height: 24px;
  }
}
