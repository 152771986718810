@import "src/styles/_colors.scss";

.CardArticle {
  height: 100%;
  overflow: hidden;
  border: 2px solid $side-color-lightgrey;
  background-color: #ffffff;
  //   padding-bottom: 12px;

  a {
    text-decoration: none;
    position: relative;

    & > div {
      position: relative;

      .category-icon {
        display: none;
        position: absolute;
        width: 32px;
        height: 32px;
        bottom: 8px;
        left: 8px;
        background-color: $side-color-lightgrey;
        padding: 3px;
        border-radius: 5px;
      }
    }

    @media (max-width: 767px) {
      display: inline-flex;
    }
  }

  .article-image,
  .img-temp,
  .no-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    background-color: $side-color-lightgrey;
    overflow: hidden;

    @media (max-width: 767px) {
      width: 150px;
      display: inline-flex;
    }
  }

  .article-content {
    height: auto;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 10px;

    .article-basicInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 10px 0;
      position: relative;

      & > p:first-of-type {
        margin: 0;
        // width: 50% !important;
        flex: 0 0 33%;
        // height: 24px;
        width: 53px;
        color: $brand-color-green;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 16px;
        padding-top: 4px;
      }

      .category--basic {
        margin: 0;
        // position: absolute;
        font-family: "Sans", Arial, sans-serif;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        // right: 0;
        border-radius: 2px;
        background-color: #2e3033;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        color: #ffffff !important;
        span {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      @media (max-width: 767px) {
        position: absolute;
        width: 100%;
        top: -2rem;
        left: 0;
        margin: 0;
        display: flex;
      }
    }

    .articles-tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 12px;

      p {
        margin: 0;
        color: $black;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        margin-right: 6px;
      }

      .tag--basic {
        color: #ffffff;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        font-family: "Sans", Arial, sans-serif;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        height: 24px;
        margin-bottom: 8px;
        margin-right: 3px;
      }
    }

    article {
      .article-title {
        color: $black;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      .article-text {
        color: $black;
        font-family: "Sans", Arial, sans-serif;
        font-size: 16px;
        line-height: 24px;
        max-height: 122px;
        // margin-bottom: 8px;
        overflow: hidden;

        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
      display: inline-flex;
      width: calc(100% - 150px);
      padding-right: 0;
      margin: 0;

      article {
        display: flex;

        .article-title {
          align-self: center;
        }
      }
    }
  }
  &--bigger {
    .no-image,
    .article-image {
      height: 300px;
    }
  }
  &-mb {
    margin-bottom: 30px;
  }
  .c-video {
    &:after {
      max-height: 50%;
    }
  }

  @media (max-width: 767px) {
    border-width: 0 0 2px 0;
    overflow: unset;
    height: unset;
  }

  & > div {
    @media (max-width: 767px) {
      display: flex;
    }
  }
}
.sideArticle {
  // margin-bottom: 30px !important;
  transition: all 0.6s ease-in-out;
  $add: 350px;
  &:hover {
    transform: scale(1);
    z-index: 20;
  }

  @media (min-width: 767px) {
    margin-bottom: 0;
    position: absolute;
    $add: 350px;
    &--0 {
      transform: scale(1);
      &:hover {
        transform: scale(1.3);
      }
    }
    &--1 {
      transform: scale(0.6);
      z-index: 10;
      bottom: 90px;
      left: $add + -50px;
    }
    &--2 {
      transform: scale(0.6);
      z-index: 10;
      bottom: 140px;
      left: $add + 140px;
      &:hover {
        bottom: 100px;
      }
    }
    &--3 {
      transform: scale(0.6);
      bottom: -60px;
      left: $add + 60px;
      z-index: 5;
    }
  }

  &.sideArticle--solid {
    display: block;
    width: 100%;
    height: 389px;
    position: relative;

    @media (min-width: 767px) {
      min-width: 270px;
      max-width: 270px;
    }
    @media (max-width: 767px) {
      height: unset;
      padding-bottom: 2rem;
    }

    .no-image {
      height: 160px;
    }

    .category--basic {
      margin-right: -15px !important;

      @media (max-width: 767px) {
        margin-right: 0 !important;
      }

      span {
        border-radius: 0;
        background-color: #00965e;
      }
    }

    .article-basicInfo > p:first-of-type {
      color: #808080;
    }

    .article-text {
      font-size: 14px;
      line-height: 20px;
    }

    .article-title {
      font-size: 1rem;
    }

    &.sideArticle--small {
      height: 300px;
      @media (max-width: 767px) {
        height: unset;
      }
    }
  }
}

.access__badge {
  display: block;
  position: absolute;
  background-color: #2e3033;
  color: white;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 6px 8px 4px;
  top: 8px;
  right: 8px;

  &--premium {
    background-color: #ffa203;
  }
}
