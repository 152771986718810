@import "src/styles/_breakpoints";

.banners-carousel {
  min-height: 448.25px;
  display: flex;
  position: relative;
}

.b-section {
  position: relative;
  padding: 40px 0 !important;
  @include respond-below("md") {
    padding: 32px 0 !important;
  }

  &.no-top-padding {
    padding-top: 0 !important;
  }

  .swiper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-wrapper {
      order: 1;
      margin-bottom: 2rem;
    }
    .carousel-navigation {
      order: 2;
      display: flex;
      gap: 8px;
      align-items: center;

      &__arrow {
        cursor: pointer;
        background: #00965e;
        border-radius: 50%;
        padding: 4px;
        svg {
          transform: scale(0.9);
          path {
            stroke: white;
            stroke-width: 3px;
          }
        }
      }
    }
    .swiper-pagination {
      order: 3;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6em;
      margin-left: 1rem;
    }
  }

  header {
    color: #2e3033;
    font-family: "Sans", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    text-transform: uppercase;

    h2 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1em;
      position: relative;
      letter-spacing: -1px;
      text-transform: uppercase;
      margin-bottom: 24px;
      @include respond-below("lg") {
        margin-bottom: 22px;
        font-size: 2rem;
      }

      .Sections__more {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    small {
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
      display: block;
      margin: 12px 0;
      font-family: "Sans", Arial, sans-serif;
      border-radius: 4px;
    }
  }

  &.VideosSection {
    hr {
      display: none;
    }
  }
}

.Sections {
  &__heading {
    margin-top: 60px;
    margin-bottom: -1rem;
    @include respond-below("lg") {
      margin-top: 40px;
    }
    color: #2e3033;
    font-family: "Sans", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    text-transform: uppercase;

    h2 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1em;
      position: relative;
      letter-spacing: -1px;
      text-transform: uppercase;
      margin-bottom: 1rem;
      @include respond-below("lg") {
        margin-bottom: 22px;
        font-size: 2rem;
      }
    }
  }

  &__subtitle {
    color: black;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    text-transform: none;
  }
}
