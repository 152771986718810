@import "src/styles/_colors.scss";

.pagination-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

  .pages-container {
    display: flex;
    flex-direction: row;
    padding: 24px 0;

    .page-item {
      color: #808080;
      font-family: "SansCond", Arial, sans-serif;
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      margin: 0 8px;

      &--selected {
        font-family: "SansCond", Arial, sans-serif;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        text-align: center;
        color: #00965e;
        cursor: pointer;
        margin: 0 8px;
      }
    }
  }

  .buttonAdditional--yellow {
    align-self: center;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .pagination-arrow-prev {
    transform: rotate(90deg);
  }

  .pagination-arrow-next {
    transform: rotate(-90deg);
  }

  .pagination-arrow-next,
  .pagination-arrow-prev {
    &--disabled {
      // opacity: 0.5;
      pointer-events: none;
      svg {
        polygon {
          fill: #00965e;
        }
      }
    }
  }
}
