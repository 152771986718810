@import "src/styles/_colors.scss";

.cookies-policy-wrapper {
  a {
    text-decoration: underline;
    font-family: "Sans", Arial,sans-serif;
    font-weight: 700;
  }
  z-index: 1000;
  .collapser-button {
    color: white;
    margin: 16px 0;
  }
  .uncollapsed {
    &:after {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-right: 1px solid white;
      border-top: 1px solid white;
      transform: rotate(-45deg);
      margin-left: 8px;
    }
  }
  .collapsed {
    &:after {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-right: 1px solid white;
      border-top: 1px solid white;
      transform: rotate(135deg) translateY(3px);
      margin-left: 8px;
    }
  }
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  position: fixed;
  bottom: 0;

  .cookies-container {
    background-color: $brand-color-green;
    padding-top: 5px;
    @media (max-width: 767px) {
      position: relative;
    }
    .icon {
      width: 8%;
      text-align: center;
      @media (max-width: 767px) {
        margin: 10px 0;
      }
    }
    & > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .buttons-container {
      padding-bottom: 20px;
      justify-content: flex-end;
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      > * {
        text-decoration: none;
        margin-left: 10px;
        font-weight: 400;
        font-size: 15px;
        padding: 0 14px;
        height: 32px;
        display: flex;
        align-items: center;
        line-height: 1;
        @media (max-width: 767px) {
          margin-bottom: 5px;
          margin-left: 0px;
        }
        &:hover {
          color: black;
        }
      }
      .close {
        background-color: transparent;
        margin-left: 0;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-right: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }


  .description {
    width: 92%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    color: white;
    font-family: Sans;
    font-size: 11px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    &--short {
      color: white;
      font-family: Sans;
      font-size: 11px;

      @media (max-width: 767px) {
        padding: 0 20px;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .top-container {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .title {
      font-family: SansCond;
      font-size: 22px;
      color: white;
      text-transform: uppercase;
    }
  }

  .close-button {
    border: 2px solid $brand-color-yellow;
    background: $brand-color-yellow;
    text-transform: uppercase;
    padding: 4px 32px;
    &:hover {
      background: white;
    }
  }
}
