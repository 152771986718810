.img-temp {
  position: relative;

  background-color: $side-color-lightgrey;
  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 50px;
    background: url("./assets/logo/agronomist_a.svg") center center / contain
      no-repeat;
    margin-top: -25px;
    margin-left: -50px;
    opacity: 0.3;
    filter: grayscale(1);
  }
  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(#000, 0.2);
  }
  &--min {
    min-height: 400px;
  }
}

.img {
  &-align {
    &--center {
      margin: auto;
    }
    &--left {
      margin-left: 0;
    }
    &--right {
      margin-right: 0;
    }
  }
}

figure {
  img {
    display: block;
    max-width: 100%;
  }
}
