.cft-report__box {
  position: relative;
  padding: 2.5rem;
  & > .cft-report__divider {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 767px) {
    padding: 1rem;
  }
}

.cft-report__summary {
  @media (max-width: 767px) {
    padding-right: 1rem !important;
  }

  .cft-report__title {
    padding-left: 0.75rem;
    margin-bottom: 1.625rem;
  }

  &__header {
    font-size: 0.688rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 0 !important;
  }

  &__box {
    padding: 0 0.75rem;
  }

  &__value {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    color: #00965e;
    margin: 0;

    small {
      margin-left: 0.375rem;
      display: inline-block;
      font-size: 0.813rem;
      font-family: "Sans", Arial, sans-serif;
      color: #606060;
    }
  }

  .cft-report__divider:last-of-type {
    display: none;
  }
}

.cft-report__divider {
  border-color: #979797;
}

.cft-report__title {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}
