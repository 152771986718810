.Sections__articles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.Sections__more {
  color: #00965e;
  font-size: 14px;
  text-transform: none;
  line-height: 14px;
  font-family: "Sans", Arial, sans-serif;
  font-weight: 400;
}

.Sections__subtitle {
  color: #808080;
  font-family: "Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;

  .Sections__more {
    float: right;
    margin-bottom: 10px;
  }
}

.section_show-off {
  button.section_show-off__add-photo {
    font-family: "Sans", Arial, sans-serif;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00965e;
    font-size: 14px;
    color: white;
    font-weight: 300;
    text-transform: uppercase;
    border: 2px solid #00965e;
    padding: 12px 40px 10px 20px;
    line-height: 14px;

    &:hover {
      background-color: white;
      color: #00965e;
    }

    &::after {
      content: "+";
      font-size: 40px;
      position: absolute;
      right: 8px;
      top: 13px;
    }
  }
}

.section_show-off--header {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
