@import "src/styles/_colors.scss";

.typography {
  &--h1 {
    color: $black;
    display: inline-block;
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    // margin: 5px;
    letter-spacing: 0;
  }

  &--h2 {
    color: $black;
    display: inline-block;
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0;
    margin: 5px;
  }

  &--h3 {
    color: $black;
    display: inline-block;
    font-family: "Sans", Arial,sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin: 5px;
  }

  &--h4 {
    color: $black;
    display: inline-block;
    font-family: "Sans", Arial,sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 5px;
  }

  &--h5 {
    color: $black;
    display: inline-block;
    font-family: "Sans", Arial,sans-serif;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 16px;
    margin: 5px;
  }
}
