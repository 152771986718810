@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints.scss";


nav {
  .navigation-menu {
    display: none;
    width: max-content;
    height: 100%;
    margin-left: 48px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      & > li {
        display: block;
        
        a {
          &.active {
            color: $brand-color-green !important;

            & + svg {
              path {
                stroke: $brand-color-green !important;
              }
            }
          }
        }
        
        @include respond-above('md'){
          &:hover {
            & > header, & > header > a {
              color: $brand-color-green !important;
              svg {
                path {
                  stroke: $brand-color-green !important;
                }
              }
            }
          }
        }
      }

      &.main-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        height: auto;

        li {
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include respond-above('md'){
            &:hover {
              .sub-group {
                display: block;
              }
            }
          }
        }

        .sub-group {
          display: none;
          position: absolute;
          top: 100%;
          left: -16px;
          min-width: 200px;
          white-space: nowrap;
          background-color: white;
          padding: 0;
          flex-direction: column;

          & > li {
            display: block;
            width: 100%;
            font-size: 14px;
            border: 1px solid rgba(128, 128, 128, 0.2);

            & > header {
              display: block;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 1;

              a {
                display: block;
                padding: 16px;
              }
            }
          }
        }

        & > li {
          padding: 0;
          position: relative;

          & > header {
            font-family: "Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            
            &.with-children {
              position: relative;
              
              svg {
                display: inline-block;
                margin-left: 11px;
                transition: all 0.2s ease-in-out;
              }
            }
          }

          @include respond-above('md'){
            &:hover {
              .sub-group-container {
                display: block;
              }
  
              & > header {
                svg {
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }

        header {
          cursor: pointer;
        }
      }
    }

    .child-group {
      display: grid;
      grid-template-columns: auto auto auto;
      margin: 0 -20px;
      & > li {
        margin-bottom: 1rem;
        padding: 0 20px;
      }
    }
    @media (min-width: 992px) {
      display: flex;
    }

    &.navigation-menu--mobile {
      padding: 0;
      margin: 0;
      width: 100%;
      font-family: 'Sans';
      height: auto;

      ul {
        color: black;

        &.main-group {
          flex-direction: column;
          gap: 0;

          & > li {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: max-content;
            margin: 0;
            padding: 30px 20px !important;
            border-top: 1px solid #EAE9EB;
            &:last-of-type {
              border-bottom: 1px solid #EAE9EB;
            }

            svg {
              pointer-events: none;
              // display: inline-block;
              // margin-left: 11px;
              transition: all 0.2s ease-in-out;
            }
            
            & > header {
              pointer-events: none;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 1.25rem;
              line-height: 1;
              color: black;

              a {
                transition: all 0.2 ease;
                pointer-events: all;
                display: inline;
              }

              &.with-children {
                &::after {
                  border-top-color: black;
                }
              }
            }

            &.is-open-for-mobile {
              & > header, & > header > a {
                color: $brand-color-green !important;
                svg {
                  path {
                    stroke: $brand-color-green !important;
                  }
                }
              }
              
              svg {
                transform: rotate(-180deg);
              }

              ul {
                display: flex !important;
              }
            }
          }
        }

        .sub-group,
        .sub-group-container {
          position: relative;
          height: unset;
          left: unset;
          top: unset;
          box-shadow: none;
          background-color: unset;
        }
        .sub-group {
          display: none;
          flex-direction: column;
          gap: 30px;
          width: 100%;
          padding: 30px 20px 0 20px;

          & > li {
            border: none;
            font-size: 1.25rem;
            line-height: 1.2em;
            margin: 0;

            a {
              padding: 0 !important;
            }
          }
        }

        .child-group {
          display: flex;
          flex-wrap: wrap;

          & > li {
            font-weight: 300;
          }
        }

        a {
          &.active {
            color: #fff;
          }
        }
      }

      .current-lang-btn-mobile {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
        text-transform: capitalize;

        &:hover {
          svg {
            transform: rotate(-180deg);
          }
        }
      }

      .navigation-buttons__button--languages {
        position: static;
        display: flex !important;
        flex-direction: column;
        width: 100%;
        margin: 0;
        height: max-content;
        min-height: 80px;
        
        & > div {
          width: 100%;
          height: max-content;
          padding: 30px 20px;
          background: #FFFFFF;
          border-top: 1px solid #EAE9EB;
          border-bottom: 1px solid #EAE9EB;
          display: flex;
          justify-content: space-between;
          font-size: 1.25rem;
          line-height: 1;

          svg {
            transition: all 0.2s ease;
            transform: rotate(0deg);
            color: $brand-color-green !important;
            svg {
              path {
                stroke: $brand-color-green !important;
              }
            }
          }
        }
        & > ul {
          position: static;
          display: none;
          width: 100%;

          &.is-showed {
            display: flex;
            flex-direction: column;
          }

          li {
            display: flex;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 30px 20px;
            padding-left: 50px;
            width: 100%;
            margin: 0;

            span {
              padding: 0;
              margin: 0;
              font-size: 1.25rem;
              line-height: 1;
            }
          }
        }
      }

      @media (max-width: 991px) {
        display: block;
      }
    }
  }
}
