.cft-text-field {
  width: 100%;
  &__input {
    width: 100%;
    position: relative;
    border: 2px solid #00965e;
    input {
      border: none;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #2e3033;
      outline: none;
      line-height: 1.5rem;
      font-weight: 300;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__adornment {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 0.813rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606060;
  }

  &.cft-text-field--alternative {
    .cft-text-field {
      &__input {
        border-color: #d6d6d6;
        input {
          padding: 0.887rem 1rem;
          line-height: 1rem;
          font-weight: 400;
        }
      }
      &__adornment {
        span {
          display: flex;
          background: #eae9eb;
          padding: 4px 8px;
          border-radius: 4px;
          color: #2e3033;
        }
      }
    }
  }

  &.cft-text-field--isInvalid {
    .cft-text-field__input {
      border-color: #fd0059;
      border-width: 3px;
    }
  }

  &.cft-text-field--rounded {
    border-radius: 3px;
    .cft-text-field {
      &__input {
        border-radius: 3px;

        input {
          border-radius: 3px;
        }
      }
    }
  }
}
