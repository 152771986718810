.c-toggleswitch {
  display: flex;
  // justify-content:
  margin-bottom: 30px;
  &-label {
    flex: 1;
    padding-right: 15px;
    .input {
      min-height: 1px;
    }
  }
  &-controller {
    flex: 0 0 48px;
  }
}

.c-controller {
  display: flex;
  align-items: center;
  height: 24px;
  position: relative;

  &-bar {
    height: 12px;
    width: 100%;
    border-radius: 6px;
    background-color: #ececec;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  }
  &-diode {
    height: 24px;
    width: 24px;
    border-radius: 16px;
    position: absolute;
    background-color: #9b9b9b;
    transition: all 0.1s ease-in;
  }
  &--active {
    .c-controller {
      &-diode {
        background-color: #00965e;
        transform: translateX(100%);
      }
    }
  }
}
