@import "src/styles/_colors.scss";

%basic-span-style {
  height: 24px;
  color: $black;
  font-family: "Sans", Arial,sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-left: 5px;
}

.imageRadioButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .radio-button-icon {
    width: 58px;
    height: 58px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .radio-button-icon--active {
    svg path {
      fill: $brand-color-green;
    }
  }

  .radio-button-icon--inactive {
    svg path {
      fill: #808080;
    }
  }

  .imageRadioButton--active {
    border: 1px solid transparent;
    background-color: rgba($brand-color-green, 0.2);
  }

  .imageRadioButton--inactive {
    opacity: 0.6;
    border: 1px solid #808080;
    border-radius: 5px;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease-in-out;
  }

  .label--active {
    font-weight: 600;
  }
}
