.mb-20 {
  margin-bottom: 20px;
}

.mb-0 {
  margin-bottom: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.75rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.25rem;
}
.mb-5 {
  margin-bottom: 1.5rem;
}
.mb-6 {
  margin-bottom: 1.75rem;
}
.mb-7 {
  margin-bottom: 2rem;
}
.mb-8 {
  margin-bottom: 1.75rem;
}
.mb-9 {
  margin-bottom: 2rem;
}
.mb-10 {
  margin-bottom: 2.25rem;
}
.mb-11 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 2.75rem;
}
.mb-13 {
  margin-bottom: 3rem;
}
