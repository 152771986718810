@import "src/styles/_colors.scss";

.backdrop {
  background-color: #fff;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.custom-tooltip {
  cursor: pointer;
  position: relative;
  z-index: 10;
  margin-left: 4px;

  img {
    width: 1.5rem;
    height: 1.6rem;
  }

  .tooltip-content {
    text-align: left;
    top: 4px;
    left: 32px;
    position: absolute;
    padding: 16px 22px;
    font-size: 13px;
    color: #fff;
    background: $brand-color-green;
    border-radius: 3px;
    width: max-content;
    max-width: 280px;
  }
}
