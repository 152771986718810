@import "src/styles/_colors.scss";

%basic-label-style {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  color: $black;
  font-family: "Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

%basic-input-style {
  min-width: 200px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  color: $black;
  font-family: "Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  padding: 11px 33px 9px 15px;
}

.input--amount {
  min-height: 70px;
  label {
    @extend %basic-label-style;
    position: relative;

    .input-icon-container {
      position: absolute;
      right: 10px;
      // top: 15%;
      align-self: center;
      cursor: pointer;
      // transform: none;
    }

    .input-label-text {
      margin-bottom: 0;
    }

    input {
      @extend %basic-input-style;
      border: 2px solid $brand-color-green;
    }
    .input-field {
      position: relative;
      flex: 0 0 100%;
    }
  }

  &--active {
    label {
      @extend %basic-label-style;

      input {
        @extend %basic-input-style;
        border: 2px solid $black;
      }
    }
  }

  &--error {
    label {
      @extend %basic-label-style;

      input {
        @extend %basic-input-style;
        border: 2px solid $side-color-red;
      }

      p.input-error {
        margin: 1px 0 0 0;
        color: $side-color-red;
        text-align: right;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}
