.cft-header {
  background-color: #fff;
  min-height: 88px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #ececec;

  & > .container {
    height: 88px;
    display: flex;
    align-items: center;

    & > .row {
      width: 100%;
    }
  }

  .cft-header__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > div {
      width: 33.333%;

      &:nth-child(3) {
        text-align: right;
        display: inline-flex;
        justify-content: flex-end;
      }
    }
  }

  .cft-header__title,
  .cft-header__crop {
    margin: 0;
    text-align: center;
  }

  .cft-header__title {
    //font-size: 0.75rem; leaving this to remember original size (invision) - AC-125 (fixing size issue)
    font-size: 0.95rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  .cft-header__crop {
    color: rgba($color: #2e3033, $alpha: 0.6);
  }

  &.cft-header--withProgressBar {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    min-height: 56px;
    height: auto;
    .container {
      height: 50px;
      padding-right: 0;
      .row {
        margin-right: 0;
      }
    }
    .cft-header__content {
      padding-right: 0;
      .cft-header__title, .cft-header__crop{
        display: none;
      }
    }
    svg {
      margin:0 .1rem;
      height: 23px;
      width: 130px;
    }
  }

}
