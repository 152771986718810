.cft-footer {
  background-color: #00965e;
  height: 86px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.cft-footer__isPending {
    .cft-footer__content {
      justify-content: center;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: 33.333%;
    }
  }

  &__text {
    display: flex;
    justify-content: center;

    span {
      color: #fff;
      font-size: 1.2rem;
      line-height: 0;
    }
  }

  &__next {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    height: 56px;
    .container {
      height: 56px;
      .row {
        height: 56px;
      }
    }
    &__content > div {
      width: auto;
    }
    &__text {
      height: 56px;
      flex-grow: 2;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.3;
        padding: 0 0.2rem;
      }
    }
  }

  .loading {
    .spinner-border {
      color: #ffa203;
    }
  }
}
