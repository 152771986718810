.cft-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.875rem;
  padding-top: 3px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .cft-checkbox__checkmark {
      background-color: #00965e;
      border-color: #00965e;

      &:after {
        display: block;
      }
    }
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 50%;
    transition: 0.6s all;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #00965e;
      border: 2px solid #fff;
    }
  }

  &:hover {
    input ~ .cft-checkbox__checkmark {
      opacity: 0.3s;
    }
  }
}
