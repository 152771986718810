.cft-error-box {
  background: #f8d4d4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: #ef6f6c;
  font-size: 12px;
  margin: 16px auto;
}
