@charset "UTF-8";
@import url(~leaflet/dist/leaflet.css);
@font-face {
  font-family: "Sans";
  src: url("./assets/fonts/BNPPSans/BNPPSans-300.eot");
  src: url("./assets/fonts/BNPPSans/BNPPSans-300.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSans/BNPPSans-300.woff") format("woff"), url("./assets/fonts/BNPPSans/BNPPSans-300.ttf") format("truetype"), url("./assets/fonts/BNPPSans/BNPPSans-300.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sans";
  src: url("./assets/fonts/BNPPSans/BNPPSans-400.eot");
  src: url("./assets/fonts/BNPPSans/BNPPSans-400.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSans/BNPPSans-400.woff") format("woff"), url("./assets/fonts/BNPPSans/BNPPSans-400.ttf") format("truetype"), url("./assets/fonts/BNPPSans/BNPPSans-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sans";
  src: url("./assets/fonts/BNPPSans/BNPPSans-700.eot");
  src: url("./assets/fonts/BNPPSans/BNPPSans-700.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSans/BNPPSans-700.woff") format("woff"), url("./assets/fonts/BNPPSans/BNPPSans-700.ttf") format("truetype"), url("./assets/fonts/BNPPSans/BNPPSans-700.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SansCond";
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-300.eot");
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-300.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-300.woff") format("woff"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-300.ttf") format("truetype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-300.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SansCond";
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-400.eot");
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-400.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-400.woff") format("woff"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-400.ttf") format("truetype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SansCond";
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-700.eot");
  src: url("./assets/fonts/BNPPSansCond/BNPPSansCond-700.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-700.woff") format("woff"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-700.ttf") format("truetype"), url("./assets/fonts/BNPPSansCond/BNPPSansCond-700.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

.bg-two-columns {
  position: relative; }
  .bg-two-columns:after {
    content: " ";
    position: absolute;
    right: 0;
    left: 50%;
    top: 0;
    bottom: 0;
    z-index: -1; }
  .bg-two-columns--gray:after {
    background-color: #ECECEC; }

.bg--main {
  background: #ECECEC; }

.img-temp {
  position: relative;
  background-color: #ECECEC; }
  .img-temp:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 50px;
    background: url("./assets/logo/agronomist_a.svg") center center/contain no-repeat;
    margin-top: -25px;
    margin-left: -50px;
    opacity: 0.3;
    filter: grayscale(1); }
  .img-temp--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .img-temp--min {
    min-height: 400px; }

.img-align--center {
  margin: auto; }

.img-align--left {
  margin-left: 0; }

.img-align--right {
  margin-right: 0; }

figure img {
  display: block;
  max-width: 100%; }

.mb-20 {
  margin-bottom: 20px; }

.mb-0 {
  margin-bottom: 0.25rem; }

.mb-1 {
  margin-bottom: 0.5rem; }

.mb-2 {
  margin-bottom: 0.75rem; }

.mb-3 {
  margin-bottom: 1rem; }

.mb-4 {
  margin-bottom: 1.25rem; }

.mb-5 {
  margin-bottom: 1.5rem; }

.mb-6 {
  margin-bottom: 1.75rem; }

.mb-7 {
  margin-bottom: 2rem; }

.mb-8 {
  margin-bottom: 1.75rem; }

.mb-9 {
  margin-bottom: 2rem; }

.mb-10 {
  margin-bottom: 2.25rem; }

.mb-11 {
  margin-bottom: 2.5rem; }

.mb-12 {
  margin-bottom: 2.75rem; }

.mb-13 {
  margin-bottom: 3rem; }

.c-heading--tertiary {
  font-family: "Sans", Arial,sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 30px; }

.c-heading--report {
  font-family: "Sans", Arial,sans-serif;
  font-weight: bold;
  font-size: 64px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 0; }

.c-video__overlay {
  cursor: pointer;
  position: relative; }
  .c-video__overlay:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 128px;
    height: 146px;
    opacity: 0.7;
    background-image: url("./assets/icons/triangle.svg");
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.3s ease-in-out;
    z-index: 3; }

.c-video__iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  border: 0; }

.c-video:hover:after {
  opacity: 1; }

.c-video.is-open.c-video:after {
  opacity: 0;
  visibility: hidden; }

.c-video.is-open .c-video__iframe {
  opacity: 1;
  visibility: visible; }

.c-video--simple {
  width: 100%;
  padding-top: 56.25%; }
  .c-video--simple:after {
    display: none; }

.banners-carousel {
  min-height: 448.25px;
  display: flex;
  position: relative; }

.b-section {
  position: relative;
  padding: 40px 0 !important; }
  @media (max-width: 992px) {
    .b-section {
      padding: 32px 0 !important; } }
  .b-section.no-top-padding {
    padding-top: 0 !important; }
  .b-section .swiper {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .b-section .swiper-wrapper {
      order: 1;
      margin-bottom: 2rem; }
    .b-section .swiper .carousel-navigation {
      order: 2;
      display: flex;
      gap: 8px;
      align-items: center; }
      .b-section .swiper .carousel-navigation__arrow {
        cursor: pointer;
        background: #00965e;
        border-radius: 50%;
        padding: 4px; }
        .b-section .swiper .carousel-navigation__arrow svg {
          transform: scale(0.9); }
          .b-section .swiper .carousel-navigation__arrow svg path {
            stroke: white;
            stroke-width: 3px; }
    .b-section .swiper .swiper-pagination {
      order: 3;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6em;
      margin-left: 1rem; }
  .b-section header {
    color: #2e3033;
    font-family: "Sans", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
    text-transform: uppercase; }
    .b-section header h2 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1em;
      position: relative;
      letter-spacing: -1px;
      text-transform: uppercase;
      margin-bottom: 24px; }
      @media (max-width: 1200px) {
        .b-section header h2 {
          margin-bottom: 22px;
          font-size: 2rem; } }
      .b-section header h2 .Sections__more {
        position: absolute;
        right: 0;
        top: 0; }
    .b-section header small {
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
      display: block;
      margin: 12px 0;
      font-family: "Sans", Arial, sans-serif;
      border-radius: 4px; }
  .b-section.VideosSection hr {
    display: none; }

.Sections__heading {
  margin-top: 60px;
  margin-bottom: -1rem;
  color: #2e3033;
  font-family: "Sans", Arial, sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  text-transform: uppercase; }
  @media (max-width: 1200px) {
    .Sections__heading {
      margin-top: 40px; } }
  .Sections__heading h2 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1em;
    position: relative;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin-bottom: 1rem; }
    @media (max-width: 1200px) {
      .Sections__heading h2 {
        margin-bottom: 22px;
        font-size: 2rem; } }

.Sections__subtitle {
  color: black;
  font-family: "Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none; }

.articles-block {
  padding-bottom: 70px;
  width: 100%;
  position: relative;
  align-items: center; }
  .articles-block .button--small {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px); }
  .articles-block .typography--h1 {
    width: 100%;
    margin-top: 64px;
    color: #00965E;
    text-transform: uppercase; }

.articles-row {
  width: 100%;
  margin-top: 30px; }
  .articles-row > * {
    margin-bottom: 30px; }

@media (min-width: 992px) {
  .articles-block {
    border-right: 1px solid #ECECEC; } }

.articles-categories {
  background-color: #e9e9e9;
  padding: 8px 12px; }
  .articles-categories h3 {
    color: #2e3033;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 40px; }
  .articles-categories.articles-categories--small a {
    font-size: 11px; }
  .articles-categories a {
    text-transform: uppercase;
    font-family: "Sans";
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    color: white;
    background-color: #808080;
    padding: 6px;
    margin-right: 6px;
    margin-bottom: 0.5rem;
    font-weight: 300;
    border-radius: 4px; }
    .articles-categories a.active {
      background-color: #00965e; }
    .articles-categories a:hover {
      opacity: 0.7; }
  .articles-categories--top-bar {
    background-color: white;
    padding: 31px 0;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center; }
    @media (max-width: 767px) {
      .articles-categories--top-bar {
        overflow-x: scroll;
        padding: 1rem 0; } }
    .articles-categories--top-bar a {
      background-color: transparent;
      color: #000000;
      font-size: 14px;
      padding: 0;
      margin: auto;
      font-weight: 400; }
      @media (max-width: 767px) {
        .articles-categories--top-bar a {
          padding: 0 15px;
          white-space: nowrap; } }
      .articles-categories--top-bar a.category-69.active, .articles-categories--top-bar a.category-70.active, .articles-categories--top-bar a.category-71.active, .articles-categories--top-bar a.category-68.active {
        color: #e92b5c; }
      .articles-categories--top-bar a.active {
        background-color: white;
        color: #00965e; }

.articles-subcategories {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 16px; }
  .articles-subcategories-el {
    color: #00965E;
    text-decoration: underline;
    margin-right: 16px;
    padding: 8px 0;
    font-weight: bold;
    cursor: pointer; }
    .articles-subcategories-el.is-active {
      color: #2E3033; }

.popular-articles {
  background-color: #e9e9e9;
  margin-bottom: 30px; }
  .popular-articles__tabs > span {
    position: relative;
    display: inline-flex;
    background-color: #9b9b9b;
    width: 50%;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    height: 44px;
    cursor: pointer;
    color: white; }
    .popular-articles__tabs > span.active {
      background-color: #00965e; }
      .popular-articles__tabs > span.active:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #00965e;
        transform: rotate(45deg);
        position: absolute;
        bottom: -5px; }
    .popular-articles__tabs > span:hover {
      opacity: 0.7; }
  .popular-articles__article {
    display: block;
    padding: 12px;
    border-bottom: 1.5px solid rgba(128, 128, 128, 0.2); }
    .popular-articles__article__date {
      display: block;
      font-family: "SansCond", Arial, sans-serif;
      color: #808080;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px; }
    .popular-articles__article p {
      color: #2e3033;
      font-family: "Sans";
      font-size: 13px;
      line-height: 18px;
      margin: 0; }
    .popular-articles__article:hover {
      opacity: 0.6; }
  .popular-articles__content--empty {
    text-align: center;
    padding: 1rem; }

.events-block {
  padding-bottom: 20px;
  width: 100%;
  min-height: 900px;
  position: relative;
  align-items: center; }
  @media (min-width: 1200px) {
    .events-block {
      align-items: flex-start; } }
  .events-block .button--small {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px); }
  .events-block .typography--h1 {
    margin-top: 64px;
    color: #00965E; }
  .events-block .buttonAdditional--grey,
  .events-block .buttonAdditional--green {
    margin-top: 0;
    margin-bottom: 0; }
  .events-block .slider-control-topcenter {
    background-color: #fff !important; }

.events-calendar .buttonAdditional--green, .events-calendar .buttonAdditional--grey {
  display: flex;
  justify-content: center;
  align-self: center;
  width: auto; }

.events-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 24px;
  position: relative;
  user-select: none; }
  .events-calendar .all-events-calendar {
    width: 100%;
    border-top: 2px solid #00965E;
    border-bottom: 2px solid #00965E;
    padding-top: -24px;
    height: 850px;
    overflow: hidden;
    margin-bottom: 32px;
    padding-bottom: 60px; }
    .events-calendar .all-events-calendar > div:nth-child(3) {
      filter: grayscale(100%);
      opacity: 0.3;
      background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f9f9f9 100%); }
      .events-calendar .all-events-calendar > div:nth-child(3) .event-all-dates {
        background-color: #ECECEC;
        margin: 0;
        padding: 25px; }
  .events-calendar .basic-event-calendar {
    width: 100%;
    padding-top: -24px;
    max-height: 580px;
    overflow: hidden;
    margin-bottom: 32px;
    padding-bottom: 60px; }
    .events-calendar .basic-event-calendar > div:nth-child(4) {
      filter: grayscale(100%);
      opacity: 0.3;
      background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f9f9f9 100%); }
      .events-calendar .basic-event-calendar > div:nth-child(4) .event-all-dates {
        background-color: #ECECEC;
        margin: 0;
        padding: 25px; }

.single-event-calendar .buttonAdditional--yellow,
.single-event-calendar .buttonAdditional--grey {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  .single-event-calendar .buttonAdditional--yellow .arrowIcon,
  .single-event-calendar .buttonAdditional--grey .arrowIcon {
    width: 32px;
    height: 32px; }

.events-row .loading,
.all-events-row .loading {
  background: transparent; }

.c-events-container {
  border-top: 2px solid #00965e;
  border-bottom: 2px solid #00965e;
  flex: 0 0 100%;
  width: 100%;
  max-height: 782px;
  overflow: hidden;
  height: 100vh;
  scrollbar-width: none;
  position: relative; }
  .c-events-container .loading {
    background: none; }
  .c-events-container::-webkit-scrollbar {
    display: none; }
  .c-events-container--hide {
    opacity: 0; }
  .c-events-container--small {
    height: 560px;
    max-height: 560px;
    border: none; }
    .c-events-container--small + .buttonAdditional--grey,
    .c-events-container--small + .buttonAdditional--yellow {
      margin-top: -32px;
      position: relative;
      z-index: 30; }
  .c-events-container--2 {
    height: 300px;
    max-height: 300px; }
  @media (max-width: 767px) {
    .c-events-container {
      max-height: calc(100vh - 300px); } }

.c-events-card {
  display: flex;
  padding-bottom: 32px;
  height: 350px; }

.events-slider--small .c-events-card {
  display: flex;
  padding-bottom: 32px;
  height: 144px; }

.events-slider .slider-frame {
  padding-top: 50px;
  padding-bottom: 50px; }

.events-slider .slider-control-topcenter,
.events-slider .slider-control-bottomcenter {
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0 !important;
  transform: none !important;
  z-index: 2;
  user-select: none; }
  .events-slider .slider-control-topcenter .arrowIcon--selected,
  .events-slider .slider-control-bottomcenter .arrowIcon--selected {
    margin-bottom: 0; }
  .events-slider .slider-control-topcenter p,
  .events-slider .slider-control-bottomcenter p {
    display: none; }

.events-slider .slider-control-topcenter {
  background-color: #ececec; }

.events-slider .slider-control-bottomcenter:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  z-index: -1;
  bottom: 0px;
  pointer-events: none; }

.events-slider + .loading {
  top: 52px;
  bottom: 0;
  height: auto;
  background: #ececec;
  opacity: 0.9;
  visibility: visible; }
  .events-slider + .loading.hide {
    opacity: 0;
    visibility: hidden; }

.events-calendar--widget {
  background-color: #00965e; }
  .events-calendar--widget__title {
    font-family: "SansCond", Arial, sans-serif;
    color: white;
    display: flex;
    padding: 14px 14px 0; }
    .events-calendar--widget__title h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 22px;
      text-transform: uppercase; }
    .events-calendar--widget__title > span {
      margin-right: 12px; }
  .events-calendar--widget__content {
    overflow: hidden;
    padding-top: 30px; }
  .events-calendar--widget__cta {
    text-align: center;
    padding-bottom: 12px; }
    .events-calendar--widget__cta a {
      display: inline-block;
      font-weight: 300;
      background-color: #ffa203;
      padding: 12px;
      text-transform: uppercase;
      font-size: 14px;
      border: 2px solid #ffa203; }
      .events-calendar--widget__cta a:hover {
        background-color: white;
        color: black; }
  .events-calendar--widget .slider-control-topcenter {
    background-color: #00965e;
    top: -32px !important;
    min-height: 32px; }
    .events-calendar--widget .slider-control-topcenter .buttonAdditional--yellow {
      transform: rotate(180deg); }
    .events-calendar--widget .slider-control-topcenter .buttonAdditional--grey {
      margin: 0 auto;
      justify-content: center; }
  .events-calendar--widget .slider-control-bottomcenter {
    background-color: #00965e;
    z-index: unset; }
    .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey {
      margin: 0 auto;
      justify-content: center;
      cursor: default !important; }
      .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey .arrowIcon,
      .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey svg,
      .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey g,
      .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey polygon,
      .events-calendar--widget .slider-control-bottomcenter .buttonAdditional--grey path {
        cursor: default !important; }
  .events-calendar--widget .slider-control-bottomcenter {
    position: relative !important; }
  .events-calendar--widget .buttonAdditional--yellow {
    cursor: pointer; }
    .events-calendar--widget .buttonAdditional--yellow polygon {
      fill: #ffa203; }
  .events-calendar--widget .events-slider--small .c-events-card {
    padding-bottom: 0;
    height: auto;
    border-bottom: 2px solid #e9e9e9; }
  .events-calendar--widget .slider-frame {
    border: 2px solid #e9e9e9; }
  .events-calendar--widget .event-dates {
    background-color: #f7f7f7; }
  .events-calendar--widget .slider-list > li {
    max-height: 82px; }

.b-gallery {
  margin: 0 -15px; }

.c-poll-slider {
  background-color: #ececec;
  padding: 19px 0; }
  .c-poll-slider .typography--h1 {
    color: #00965E; }
  .c-poll-slider .articles-slider {
    width: 100%; }
  @media (min-width: 992px) {
    .c-poll-slider__wrap {
      margin: 0 -40px; } }
  @media (max-width: 992px) {
    .c-poll-slider__wrap {
      margin: 0 -15px; } }
  .c-poll-slider__slide {
    background-color: #ffffff;
    min-height: 660px;
    display: flex;
    flex-direction: column; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .c-poll-slider__slide {
        /* IE10+ CSS styles go here */
        height: 660px; } }
    .c-poll-slider__slide .c-poll {
      flex: 1 1 auto;
      background-color: transparent;
      border: none; }
  .c-poll-slider__more {
    padding: 12px 0;
    margin: 0 12px;
    min-height: 81px;
    border-top: 2px solid #ececec;
    flex-shrink: 0; }
    .c-poll-slider__more p {
      margin-bottom: 5px; }
    .c-poll-slider__more a {
      display: block;
      font-size: 16px;
      line-height: 16px;
      transition: all 0.3s ease-in-out; }
      .c-poll-slider__more a:hover {
        color: #00965E; }
  .c-poll-slider .slider {
    padding: 10px 40px 0 !important; }
    @media (max-width: 992px) {
      .c-poll-slider .slider {
        padding-right: 30px !important;
        padding-left: 30px !important; } }
    .c-poll-slider .slider-control-centerleft .buttonAdditional--yellow {
      transform: rotate(90deg);
      cursor: pointer; }
    .c-poll-slider .slider-control-centerright .buttonAdditional--yellow {
      transform: rotate(-90deg);
      cursor: pointer; }

.restricted-article-window {
  width: 100%;
  margin: 0 auto;
  position: relative; }
  .restricted-article-window header {
    color: #00965E;
    background-color: #E5F5F0;
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0; }
    .restricted-article-window header h3 {
      font-family: "SansCond", Arial, sans-serif;
      text-transform: uppercase;
      font-size: 40px !important;
      line-height: 40px !important;
      color: #00965E !important;
      margin-bottom: 0;
      margin-top: 2.125rem; }
    .restricted-article-window header p {
      font-weight: 400;
      font-size: .875rem;
      line-height: 1.5em;
      max-width: 25rem;
      margin: 1rem auto 1.5rem;
      color: black; }
    .restricted-article-window header a.register-link {
      color: #00965e;
      text-decoration: underline;
      font-size: .8125rem;
      font-weight: 700;
      margin-top: 1.5rem; }

.synoptic-comment {
  width: 384px;
  background-color: #ffffff;
  padding: 16px;
  position: relative; }
  .synoptic-comment--green {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    width: auto;
    background-color: #00965E;
    margin-bottom: 50px; }
    .synoptic-comment--green header,
    .synoptic-comment--green .synoptic-comment__overflow {
      color: #ffffff; }
  .synoptic-comment--hide {
    display: none; }
  .synoptic-comment__overflow {
    max-height: 306px;
    overflow: auto; }
  .synoptic-comment--collapsed .synoptic-comment__overflow {
    max-height: 518px; }
  .synoptic-comment header {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    text-transform: uppercase; }
  .synoptic-comment p {
    font-size: 12px; }
  @media (max-width: 1200px) {
    .synoptic-comment {
      width: 100%; } }

.comment-news {
  background-color: #197a56;
  border-radius: 0 0 0 5px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: #ffa203;
  text-transform: uppercase; }
  .comment-news + header {
    padding-right: 90px; }

.VideoCover {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center; }
  .VideoCover__cover {
    position: relative;
    width: 70px;
    height: 40px;
    background-size: cover;
    margin-right: 16px; }
    .VideoCover__cover svg {
      height: 17px;
      width: 23px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -13.5px;
      margin-top: -11.5px; }
      .VideoCover__cover svg path:nth-of-type(2) {
        fill: rgba(59, 55, 56, 0.9); }
  .VideoCover__title {
    width: calc(100% - 86px); }
    .VideoCover__title h3 {
      color: #373a3c;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      margin: 0; }
  .VideoCover__preview {
    width: 100%;
    height: auto;
    padding-top: 56.25%;
    position: relative; }
    .VideoCover__preview iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none; }
  .VideoCover.VideoCover--isBig {
    display: block; }
    .VideoCover.VideoCover--isBig .VideoCover__cover {
      width: 100%;
      height: auto;
      padding-top: 56.25%;
      position: relative;
      background-size: cover; }
      .VideoCover.VideoCover--isBig .VideoCover__cover svg {
        height: 34px;
        width: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -23px;
        margin-top: -17px; }
        .VideoCover.VideoCover--isBig .VideoCover__cover svg path:nth-of-type(2) {
          fill: rgba(59, 55, 56, 0.9); }
    .VideoCover.VideoCover--isBig .VideoCover__title {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 1rem;
      background: white;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.75) 65%, black 100%); }
      .VideoCover.VideoCover--isBig .VideoCover__title h3 {
        color: #ffffff;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        text-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5); }
  .VideoCover__modal {
    position: relative;
    padding: 5rem 1rem 3rem 1rem;
    max-width: 90%;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .VideoCover__modal {
        padding: 5rem 5rem 3rem 5rem; } }
    .VideoCover__modal__close {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: 0; }
      .VideoCover__modal__close svg {
        height: 78px;
        width: 78px; }
        .VideoCover__modal__close svg path {
          fill: white; }
      .VideoCover__modal__close:focus {
        outline: none; }
      .VideoCover__modal__close:hover svg path {
        fill: #00965e; }
    .VideoCover__modal h3 {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 1rem 0; }
    .VideoCover__modal p {
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0; }
  .VideoCover:hover .VideoCover__cover svg path:nth-of-type(2) {
    fill: #00965e; }
  .VideoCover:hover .VideoCover__title h3 {
    color: #00965e; }

.VideoCover__modal-container {
  max-width: 90% !important; }
  @media (min-width: 992px) {
    .VideoCover__modal-container {
      max-width: 920px !important;
      position: relative;
      top: 20vh; } }

.VideosList__list {
  padding: 0.5rem 0;
  height: 304px;
  overflow-y: hidden;
  scroll-behavior: smooth;
  border-top: 6px solid white; }
  .VideosList__list .VideoCover {
    padding: 0.5rem 0;
    border-top: 1px solid #d8d8d8; }
    .VideosList__list .VideoCover:last-of-type {
      border-bottom: 1px solid #d8d8d8; }

.VideosList__actions {
  display: flex;
  justify-content: center; }
  .VideosList__actions button {
    border: 1px solid #c4c7c7;
    color: #c4c7c7;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    border-radius: 2px;
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .VideosList__actions button svg {
      height: 22px;
      width: 14px; }
    .VideosList__actions button:hover {
      border-color: #00965e;
      color: #00965e; }
    .VideosList__actions button:focus {
      outline: none; }

.VideosPreview {
  padding: 1rem 0; }
  @media (min-width: 992px) {
    .VideosPreview {
      padding: 2.5rem 0; } }
  .VideosPreview__playback {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 1rem;
    background-color: #00965e; }
    .VideosPreview__playback iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none; }

.calendar-weather {
  height: 500px;
  width: 100%;
  max-width: 580px;
  background-color: #ffffff;
  margin-left: 10%; }
  .calendar-weather .weather-adjust {
    height: 150px;
    width: 100%;
    background-color: #00965E;
    padding: 24px 32px; }
    .calendar-weather .weather-adjust .menu-contition-adjust {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 60%; }
      .calendar-weather .weather-adjust .menu-contition-adjust p {
        color: #FFFFFF;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 28px;
        font-weight: bold;
        line-height: 32px;
        width: 75%; }
      .calendar-weather .weather-adjust .menu-contition-adjust .adjustIcon {
        background-color: #FFA203;
        width: 25%;
        min-width: 100px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .calendar-weather .weather-adjust .menu-contition-adjust .adjustIcon span {
          margin-top: 2px;
          margin-left: 10px;
          font-size: 13px; }
    .calendar-weather .weather-adjust .buttons-date-adjust .button--yellow, .calendar-weather .weather-adjust .buttons-date-adjust .button--inactive {
      margin-right: 8px;
      width: 96px; }
  .calendar-weather .weather-description {
    padding: 30px 10px 10px 20px;
    height: 150px; }
    .calendar-weather .weather-description p:first-of-type {
      margin: 0;
      margin-bottom: 5px;
      color: #2E3033;
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      line-height: 40px; }
    .calendar-weather .weather-description p:nth-of-type(2) {
      margin: 0;
      margin-bottom: 5px;
      color: #2E3033;
      font-family: "SansCond", Arial, sans-serif;
      font-size: 24px;
      line-height: 16px; }
    .calendar-weather .weather-description p:nth-of-type(3) {
      margin: 0;
      margin-top: 25px;
      color: #2E3033;
      font-family: "Sans", Arial,sans-serif;
      font-size: 13px;
      font-weight: bold; }
    .calendar-weather .weather-description p:nth-of-type(4) {
      color: #2E3033;
      font-family: "Sans", Arial,sans-serif;
      font-size: 13px;
      line-height: 16px; }
  .calendar-weather .weather-icons {
    padding: 20px 10px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: center;
    height: 200px; }
    .calendar-weather .weather-icons .weather-icon {
      width: 15%;
      display: flex;
      flex-direction: column;
      align-content: space-around;
      align-items: flex-start; }
      .calendar-weather .weather-icons .weather-icon :first-child {
        color: #2E3033;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 23px;
        font-weight: bold;
        line-height: 29px;
        text-align: center; }
      .calendar-weather .weather-icons .weather-icon :nth-child(2) {
        margin: 24px 0; }
      .calendar-weather .weather-icons .weather-icon :nth-child(3) {
        color: #2E3033;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 23px;
        line-height: 29px;
        text-align: center; }
    .calendar-weather .weather-icons .weather-icon--current {
      width: 20%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-content: space-around;
      align-items: flex-start; }
      .calendar-weather .weather-icons .weather-icon--current :first-child {
        color: #00965E; }
      .calendar-weather .weather-icons .weather-icon--current :nth-child(2) {
        margin: 24px 0px 40px 0px;
        display: block;
        transform: scale(2); }
      .calendar-weather .weather-icons .weather-icon--current :nth-child(3) {
        color: #00965E;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 64px;
        line-height: 29px;
        text-align: center; }

.weather-widget {
  font-family: "SansCond", Arial, sans-serif;
  display: inline-flex;
  flex-direction: column;
  background-color: white;
  overflow-y: hidden;
  min-width: 580px; }
  @media (max-width: 767px) {
    .weather-widget {
      width: 100%;
      min-width: unset; } }
  .weather-widget__heading__actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .weather-widget__heading__actions .weather-widget_single {
      background-color: rgba(0, 0, 0, 0.1);
      min-width: 30%;
      padding-left: 8px;
      margin-left: -8px;
      text-transform: capitalize; }
  .weather-widget__provider {
    background-color: #ececec;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center; }
    .weather-widget__provider p {
      margin: 0 4px 0 0;
      font-size: 0.7rem;
      font-weight: 300;
      text-transform: none;
      font-family: "Sans", Arial, sans-serif; }
    @media (max-width: 767px) {
      .weather-widget__provider {
        flex-direction: column;
        align-items: flex-start; }
        .weather-widget__provider p {
          font-size: 0.8rem; }
        .weather-widget__provider img {
          margin-left: 0; } }
  .weather-widget__heading {
    position: relative;
    background-color: #00965E;
    color: white;
    text-transform: uppercase;
    padding: 16px 20px;
    font-size: 28px;
    min-height: 112px; }
    @media (max-width: 767px) {
      .weather-widget__heading {
        min-height: 132px; } }
    @media (max-width: 767px) {
      .weather-widget__heading small {
        font-size: 100%;
        color: #FFA203; } }
    .weather-widget__heading__text {
      font-weight: 700; }
      @media (max-width: 767px) {
        .weather-widget__heading__text {
          line-height: 30px;
          padding-right: 36px; } }
      .weather-widget__heading__text small {
        font-weight: 300;
        margin-left: 12px;
        position: relative;
        bottom: 2px; }
    .weather-widget__heading .arrowIcon {
      position: absolute;
      left: 0;
      top: 12px;
      cursor: pointer;
      height: 28px;
      width: 28px;
      align-self: center; }
      .weather-widget__heading .arrowIcon svg {
        height: 28px;
        width: 28px;
        transform: rotate(90deg); }
    .weather-widget__heading .button--small .settingsIcon {
      display: inline; }
    .weather-widget__heading .button--small p {
      padding: 0 12px 0 2px; }
      @media (max-width: 767px) {
        .weather-widget__heading .button--small p {
          padding: 0; } }
    @media (max-width: 767px) {
      .weather-widget__heading .button--small {
        top: 15px;
        min-width: unset;
        width: 42px;
        right: 15px; }
        .weather-widget__heading .button--small span {
          display: none; } }
    .weather-widget__heading--bttm {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .weather-widget__heading--bttm .weather-widget__heading__text {
        flex: 1; }
        .weather-widget__heading--bttm .weather-widget__heading__text p {
          display: block;
          font-family: "Sans", Arial, sans-serif;
          font-size: 13px;
          line-height: 30px;
          margin-bottom: 0; }
      .weather-widget__heading--bttm .weather-widget__heading__bttn {
        flex: 0 0 130px;
        text-align: right; }
        .weather-widget__heading--bttm .weather-widget__heading__bttn .button--small {
          position: relative;
          padding: 7px 13px;
          top: auto;
          right: auto;
          text-align: center; }
          .weather-widget__heading--bttm .weather-widget__heading__bttn .button--small p {
            padding: 0; }
          .weather-widget__heading--bttm .weather-widget__heading__bttn .button--small span {
            display: block; }
      @media (max-width: 768px) {
        .weather-widget__heading--bttm .weather-widget__heading__bttn {
          flex: 0 0 100%;
          text-align: left; }
          .weather-widget__heading--bttm .weather-widget__heading__bttn .button--small {
            width: 100px; } }
  .weather-widget__properties-list {
    position: relative;
    width: 580px;
    overflow-y: auto; }
    @media (max-width: 767px) {
      .weather-widget__properties-list {
        width: 100%;
        min-height: 137px;
        height: auto;
        padding: 8px 15px 8px 30px; } }
  .weather-widget__property {
    width: 580px;
    overflow-x: hidden; }
    @media (max-width: 767px) {
      .weather-widget__property__days {
        display: flex;
        flex-wrap: wrap; } }
    .weather-widget__property__next {
      display: flex;
      justify-content: space-between;
      background-color: #ececec;
      padding: 1rem; }
      .weather-widget__property__next .button--small {
        position: relative;
        min-width: 120px; }
        .weather-widget__property__next .button--small p {
          padding: 2px 8px;
          text-transform: lowercase; }
          .weather-widget__property__next .button--small p::first-letter {
            text-transform: uppercase; }
        .weather-widget__property__next .button--small .arrowIcon {
          position: absolute; }
          .weather-widget__property__next .button--small .arrowIcon svg {
            width: 1rem;
            height: auto; }
      .weather-widget__property__next .next .arrowIcon {
        right: 0px;
        top: 2px; }
        .weather-widget__property__next .next .arrowIcon svg {
          transform: rotate(-90deg); }
      .weather-widget__property__next .previous .arrowIcon {
        left: 0px;
        top: 2px; }
        .weather-widget__property__next .previous .arrowIcon svg {
          transform: rotate(90deg); }
    .weather-widget__property__current_day {
      padding: 1rem 1rem 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .weather-widget__property__current_day h6 {
        font-family: "SansCond", Arial, sans-serif;
        color: #00965e;
        font-size: 40px;
        font-weight: 600; }
        .weather-widget__property__current_day h6::first-letter {
          text-transform: uppercase; }
      .weather-widget__property__current_day .weather-icon {
        margin-top: -2rem;
        margin-bottom: -2rem; }
      .weather-widget__property__current_day .temperature {
        display: flex;
        font-family: "Sans", Arial, sans-serif;
        align-items: flex-end; }
        .weather-widget__property__current_day .temperature p {
          font-weight: 300;
          margin: 0 8px;
          font-size: 14px; }
      .weather-widget__property__current_day .temperature__wrapper {
        display: flex; }
    .weather-widget__property__temp-night {
      color: #9B9B9B; }
    .weather-widget__property__selected-temperature {
      color: #00965E;
      display: flex;
      width: 60%;
      font-size: 60px; }
      .weather-widget__property__selected-temperature small {
        display: block;
        margin-top: -20px; }
      .weather-widget__property__selected-temperature > div:first-child {
        width: 55px; }
    .weather-widget__property__selected-weather {
      width: 40%;
      font-family: "Sans", Arial, sans-serif;
      font-size: 14px; }
      .weather-widget__property__selected-weather span {
        display: flex;
        margin-bottom: 14px; }
      @media (max-width: 767px) {
        .weather-widget__property__selected-weather {
          font-size: 12px; } }
    .weather-widget__property__week-day {
      text-transform: uppercase;
      color: #9B9B9B; }
    .weather-widget__property__day {
      width: 240px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      padding: 33.5px 16px;
      background-color: #f8f8f8;
      font-family: "Sans", Arial, sans-serif; }
      .weather-widget__property__day:nth-child(even) {
        background-color: #f2f2f2; }
      .weather-widget__property__day.active {
        background-color: #FFA203; }
        .weather-widget__property__day.active::after {
          position: absolute;
          content: "";
          right: -5px;
          width: 0;
          height: 5px;
          border-top: 5px solid transparent;
          border-left: 5px solid #FFA203;
          border-bottom: 5px solid transparent;
          top: 50%;
          transform: translateY(-50%); }
        .weather-widget__property__day.active .weather-widget__property__temp-night {
          color: white; }
        .weather-widget__property__day.active .weather-widget__property__week-day {
          color: black; }
      .weather-widget__property__day:hover {
        opacity: 0.6; }
        @media (max-width: 767px) {
          .weather-widget__property__day:hover {
            opacity: 1; } }
      .weather-widget__property__day > div:first-child {
        width: 120px; }
        @media (max-width: 767px) {
          .weather-widget__property__day > div:first-child {
            width: 80px; } }
      @media (max-width: 767px) {
        .weather-widget__property__day {
          padding: 10px 16px;
          width: 50%; } }
    .weather-widget__property__forecast {
      width: 100%;
      margin-bottom: 2rem;
      padding: 0 1rem;
      border-bottom: 1px solid rgba(151, 151, 151, 0.2); }
      .weather-widget__property__forecast__selected {
        height: 220px; }
        .weather-widget__property__forecast__selected > div {
          display: flex; }
        @media (max-width: 767px) {
          .weather-widget__property__forecast__selected {
            height: 150px; } }
      .weather-widget__property__forecast .day {
        font-family: "Sans", Arial, sans-serif;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #f2f2f2;
        margin: 0 2px;
        font-size: 0.7rem; }
        .weather-widget__property__forecast .day.current {
          color: #FFA203;
          border-bottom: 2px solid #FFA203; }
        .weather-widget__property__forecast .day .weather-icon {
          margin-bottom: -8px; }
        .weather-widget__property__forecast .day:focus {
          outline: none; }
      .weather-widget__property__forecast .slider-control-centerleft {
        -webkit-user-select: none;
        /* Chrome all / Safari all */
        -moz-user-select: none;
        /* Firefox all */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        margin-left: -24px; }
        .weather-widget__property__forecast .slider-control-centerleft .arrowIcon {
          cursor: pointer; }
          .weather-widget__property__forecast .slider-control-centerleft .arrowIcon svg {
            transform: rotate(90deg); }
      .weather-widget__property__forecast .slider-control-centerright {
        -webkit-user-select: none;
        /* Chrome all / Safari all */
        -moz-user-select: none;
        /* Firefox all */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        margin-right: -24px; }
        .weather-widget__property__forecast .slider-control-centerright .arrowIcon {
          cursor: pointer; }
          .weather-widget__property__forecast .slider-control-centerright .arrowIcon svg {
            transform: rotate(-90deg); }
      @media (max-width: 767px) {
        .weather-widget__property__forecast {
          width: 100%; } }
    @media (max-width: 767px) {
      .weather-widget__property {
        width: 100%;
        height: auto;
        flex-direction: column; } }
    .weather-widget__property__info {
      font-family: "Sans", Arial, sans-serif;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem 0 1rem; }
      .weather-widget__property__info > div {
        width: 50%; }
        .weather-widget__property__info > div h6 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1rem; }
        .weather-widget__property__info > div p {
          font-size: 14px;
          font-weight: 300; }
    .weather-widget__property__back {
      padding: 0 1rem 1rem;
      display: flex;
      justify-content: flex-end; }
  .weather-widget .slider {
    padding: 0; }
  .weather-widget__empty-pois {
    text-align: center;
    padding: 50px;
    font-size: 18px; }
  .weather-widget main {
    height: 0;
    transition: height 0.25s;
    overflow: hidden; }
    .weather-widget main.weather-widget__weatherConditions {
      height: 294px; }
      @media (max-width: 767px) {
        .weather-widget main.weather-widget__weatherConditions {
          height: 246px; } }
      @media (max-width: 480px) {
        .weather-widget main.weather-widget__weatherConditions {
          height: 318px; } }
    .weather-widget main.weather-widget__propertyBox {
      height: 508px; }
      @media (max-width: 767px) {
        .weather-widget main.weather-widget__propertyBox {
          height: 600px; } }

.weather-widget__select__wrapper {
  min-width: 270px;
  color: black;
  font-family: "Sans", Arial, sans-serif; }
  .weather-widget__select__wrapper svg {
    fill: black !important; }
  .weather-widget__select__wrapper .weather-widget__select__control {
    font-size: 14px;
    border-radius: 0;
    background-color: white;
    border-color: white; }
  .weather-widget__select__wrapper .weather-widget__select__value-container {
    color: black !important;
    padding: 0 8px; }
    .weather-widget__select__wrapper .weather-widget__select__value-container div {
      color: black !important;
      text-transform: none;
      font-size: 1rem;
      font-weight: 300; }
  .weather-widget__select__wrapper .weather-widget__select__indicator-separator {
    display: none; }
  .weather-widget__select__wrapper .weather-widget__select__single-value {
    color: white; }
  .weather-widget__select__wrapper .weather-widget__select__menu {
    font-size: 14px;
    color: black !important; }

.single-property {
  border: solid 1px #979797;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .single-property {
      flex-direction: row; } }
  .single-property__thumbnail {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #e3e3e3;
    background-size: cover;
    background-position: center center; }
    @media (min-width: 992px) {
      .single-property__thumbnail {
        width: 362px;
        height: auto; } }
  .single-property__content {
    position: relative;
    width: 100%;
    padding: 28px; }
    .single-property__content__address span {
      font-weight: 300; }
    .single-property__content__address svg {
      height: 21px;
      width: auto;
      margin-right: 15px; }
      .single-property__content__address svg path {
        fill: #2e3033; }
    .single-property__content__title {
      font-size: 18px;
      font-weight: bold;
      margin: 28px 0 20px 0; }
    .single-property__content__area {
      color: #00965e;
      margin-bottom: 20px; }
      .single-property__content__area svg {
        height: 13px;
        width: auto;
        margin-right: 8px; }
        .single-property__content__area svg path {
          fill: #00965e; }
      .single-property__content__area span {
        display: inline-block; }
        .single-property__content__area span:first-of-type {
          margin-right: 24px;
          display: none; }
          @media (min-width: 992px) {
            .single-property__content__area span:first-of-type {
              display: inline-block; } }
    .single-property__content__properties {
      font-size: 14px;
      color: #2e3033;
      padding: 0; }
      .single-property__content__properties span {
        position: relative;
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: black;
        border-radius: 50%;
        margin: 0 15px;
        bottom: 3px; }
        .single-property__content__properties span:first-of-type {
          margin-left: 0; }
    .single-property__content__date {
      display: none;
      font-weight: 300;
      color: #00965e; }
      @media (min-width: 992px) {
        .single-property__content__date {
          display: block; } }
    .single-property__content__price {
      font-size: 23px;
      font-weight: bold;
      text-align: right;
      color: #00965e; }
    .single-property__content__cta a {
      background-color: #ffa203;
      border: 2px solid #ffa203;
      padding: 13px 21px 11px 21px; }
      .single-property__content__cta a:hover {
        background-color: white;
        color: #ffa203; }
      @media (max-width: 400px) {
        .single-property__content__cta a {
          width: 100%;
          display: block;
          text-align: center;
          margin-top: 1rem; } }
    @media (min-width: 992px) {
      .single-property__content__cta {
        position: absolute;
        bottom: 8px;
        right: 8px; } }
    .single-property__content--price {
      display: flex;
      justify-content: space-between;
      margin-top: 24px; }
      @media (min-width: 992px) {
        .single-property__content--price {
          margin-top: 0;
          display: block; } }
      @media (max-width: 400px) {
        .single-property__content--price {
          flex-direction: column; } }
  .single-property__promoted {
    display: block;
    background-color: #ffa203;
    text-align: center;
    color: #2e3033;
    font-size: 14px;
    padding: 12px 20px 10px 20px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 10px; }

.suggested-properties {
  width: 100%;
  margin-bottom: 2rem; }
  .suggested-properties .suggested-properties__single-link:hover {
    color: unset; }
  .suggested-properties__header h3 {
    width: 100%;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-stretch: condensed;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    border: none;
    padding: 0; }
    @media (max-width: 992px) {
      .suggested-properties__header h3 {
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        font-size: 2rem; } }
    .suggested-properties__header h3 a {
      font-family: "Sans", Arial, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-decoration: underline;
      text-transform: none;
      color: #00965e; }
  .suggested-properties__single {
    border: solid 2px #e9e9e9; }
    .suggested-properties__single .single-property__thumbnail {
      background-color: #707070;
      width: 100%;
      padding-top: 75%;
      position: relative; }
  .suggested-properties .suggested-properties__single.single-property {
    flex-direction: column; }
    .suggested-properties .suggested-properties__single.single-property .single-property__content__title {
      margin-top: 0; }
    .suggested-properties .suggested-properties__single.single-property .single-property__content__address {
      margin-bottom: 1.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .suggested-properties .suggested-properties__single.single-property .single-property__content__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.gallery-row__filter {
  padding: 15px 0;
  margin-bottom: 15px;
  color: rgba(46, 48, 51, 0.4);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  align-content: center;
  display: flex;
  border-bottom: 2px solid #e9e9e9; }
  .gallery-row__filter .c-radio-group {
    display: inline-block;
    color: #2e3033;
    font-size: 14px;
    text-transform: lowercase;
    font-weight: 300;
    margin-left: 15px;
    margin-bottom: 0; }

.gallery-row .ShowOffField__rating {
  margin-top: -10px; }

.gallery-row .img-uploaded {
  display: flex;
  background-color: rgba(0, 150, 94, 0.2);
  border: 2px solid #00965e;
  border-radius: 4px;
  font-size: 14px;
  align-items: center;
  width: 100%;
  margin: 30px 0 8px 0; }
  .gallery-row .img-uploaded polygon {
    fill: #00965e; }
  .gallery-row .img-uploaded svg {
    width: 72px;
    height: 72px; }
  .gallery-row .img-uploaded p {
    margin: 0; }
    .gallery-row .img-uploaded p a {
      text-decoration: underline; }

.gallery-tags {
  background-color: #e9e9e9;
  padding: 10px; }
  .gallery-tags h3 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    margin: 6px 0 24px;
    font-weight: 600; }

.gallery-top3 {
  background-color: #00965e;
  color: white;
  padding: 10px; }
  .gallery-top3 .ShowOffFieldContainer > .row {
    display: flex;
    flex-direction: column; }
    .gallery-top3 .ShowOffFieldContainer > .row .col-md-4 {
      width: 100%;
      flex: 1;
      max-width: unset; }
  .gallery-top3 h3 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    margin: 6px 0 24px;
    font-weight: 600; }
  .gallery-top3 .ShowOffField__author {
    color: white; }
    .gallery-top3 .ShowOffField__author span {
      color: white; }
  .gallery-top3 .ShowOffField {
    border-bottom: 2px solid rgba(233, 233, 233, 0.6); }
  .gallery-top3 .c-photos-viewer {
    margin: 0 !important; }

.gallery-select-tag {
  margin-top: 30px;
  max-width: 384px;
  color: #2e3033; }
  .gallery-select-tag .gallery-select-tags__indicators .gallery-select-tags__indicator-separator,
  .gallery-select-tag .gallery-select-tags__indicators .gallery-select-tags__dropdown-indicator {
    display: none; }
  .gallery-select-tag .gallery-select-tags__control {
    border: 2px solid #00965e !important;
    border-radius: 0; }
    .gallery-select-tag .gallery-select-tags__control.gallery-select-tags__control--is-focused {
      border-color: #00965e !important;
      outline-color: #00965e !important;
      box-shadow: 0 0 0 1px #00965e !important; }

.events-page-header {
  background-size: cover;
  background-position: center center; }
  .events-page-header .btn-add-photo {
    background-color: #ffa203;
    color: #2e3033;
    border-color: #ffa203; }

.gallery-tags__show-all {
  font-size: 12px;
  cursor: pointer;
  display: block;
  margin-top: 12px; }
  .gallery-tags__show-all:hover {
    opacity: 0.6; }

.registration-benefits-content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .registration-benefits-content .pros-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .registration-benefits-content .pros-container p {
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #2E3033;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 0 10px; }
  .registration-benefits-content img {
    height: 200px;
    width: 70%;
    object-fit: cover;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px; }

.weather-content__provider {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .weather-content__provider img {
    object-fit: unset;
    height: auto;
    width: 250px;
    margin: 0 0 0.5rem 1rem; }
  .weather-content__provider p {
    font-size: 1.2rem; }

.registration-benefits-content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .registration-benefits-content .provider-img {
    width: auto;
    max-height: 80px; }
    .registration-benefits-content .provider-img--small {
      max-height: 40px;
      width: auto; }
  .registration-benefits-content .provider-title {
    margin-top: 64px;
    margin-bottom: 16px;
    color: #9ca4ab; }
  .registration-benefits-content .content-title {
    margin: 32px 0 32px 0;
    color: #00965e;
    -webkit-align-self: center;
    align-self: center;
    display: block;
    display: inline-block;
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0; }
    .registration-benefits-content .content-title--small {
      margin: 48px 0 16px 0;
      color: #00965e;
      -webkit-align-self: center;
      align-self: center;
      display: block;
      display: inline-block;
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0; }
  .registration-benefits-content .pros-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0; }
    .registration-benefits-content .pros-container p {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #2E3033;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 0 0 10px; }
    .registration-benefits-content .pros-container--small {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0; }
      .registration-benefits-content .pros-container--small p {
        font-family: "Sans", Arial,sans-serif;
        color: #2E3033;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0 0 0 10px;
        font-size: 14px; }
  .registration-benefits-content img {
    height: 200px;
    width: 70%;
    object-fit: cover;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px; }
  .registration-benefits-content .content-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto); }
    .registration-benefits-content .content-grid > :first-child {
      grid-row: 1; }
    .registration-benefits-content .content-grid > :nth-child(2) {
      grid-row: 4; }
    .registration-benefits-content .content-grid > :nth-child(3) {
      grid-row: 2; }
    .registration-benefits-content .content-grid > :nth-child(4) {
      grid-row: 5; }
    .registration-benefits-content .content-grid > :nth-child(5) {
      grid-row: 3; }
    .registration-benefits-content .content-grid > :nth-child(6) {
      grid-row: 6; }
    @media (min-width: 1200px) {
      .registration-benefits-content .content-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto); }
        .registration-benefits-content .content-grid > :first-child {
          padding-right: 15px;
          grid-row: 1; }
        .registration-benefits-content .content-grid > :nth-child(2) {
          grid-row: 1; }
        .registration-benefits-content .content-grid > :nth-child(3) {
          grid-row: 2; }
        .registration-benefits-content .content-grid > :nth-child(4) {
          grid-row: 2; }
        .registration-benefits-content .content-grid > :nth-child(5) {
          grid-row: 3; }
        .registration-benefits-content .content-grid > :nth-child(6) {
          grid-row: 3; } }

.user-logIn-page__container {
  max-width: 960px; }

.user-logIn-page__boxes {
  padding: 0; }

.user-logIn-page__column {
  padding: 2.5rem 1.25rem 1.25rem 1.25rem;
  border-radius: 6px 0px 0px 6px; }
  @media (min-width: 992px) {
    .user-logIn-page__column {
      padding: 2.5rem; } }
  .user-logIn-page__column--left {
    background: white;
    margin-bottom: 1.25rem; }
    @media (min-width: 992px) {
      .user-logIn-page__column--left {
        margin-bottom: 0; } }
  .user-logIn-page__column--right {
    background: #E5F5F0; }
    @media (min-width: 992px) {
      .user-logIn-page__column--right {
        border-radius: 0px 6px 6px 0px; } }

.user-logIn-page__submit {
  margin-top: 23px; }

.user-logIn-page__main-wrapper {
  background: #ececec;
  display: flex;
  flex-direction: column;
  padding: 20px; }
  @media (min-width: 992px) {
    .user-logIn-page__main-wrapper {
      padding-top: 65px;
      padding-bottom: 65px;
      flex-direction: row; } }
  .user-logIn-page__main-wrapper .breadcrumbs {
    top: 2rem; }

.user-logIn-page .logIn-description {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .user-logIn-page .logIn-description .forgot-password-link {
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    color: #00965E;
    transition: all 200ms ease; }
    .user-logIn-page .logIn-description .forgot-password-link:hover {
      color: #FFA203 !important;
      text-decoration-color: #FFA203 !important; }
  .user-logIn-page .logIn-description .typography--h1 {
    color: #00965E;
    margin: 0 0 2.25rem 0;
    width: 100%; }
  .user-logIn-page .logIn-description p:nth-of-type(2) {
    font-family: "Sans", Arial, sans-serif;
    font-size: 18px;
    color: #2E3033;
    letter-spacing: 0; }
  .user-logIn-page .logIn-description form {
    display: flex;
    flex-direction: column; }
    .user-logIn-page .logIn-description form .button--yellow,
    .user-logIn-page .logIn-description form .bttn {
      margin-top: 16px;
      width: 150px;
      align-self: center; }
    .user-logIn-page .logIn-description form .input {
      margin-bottom: 15px; }
      .user-logIn-page .logIn-description form .input input {
        border-radius: 3px;
        border: 1px solid #D6D6D6;
        box-shadow: none; }
        .user-logIn-page .logIn-description form .input input:focus, .user-logIn-page .logIn-description form .input input:active {
          border-color: #00965E;
          outline-color: #00965E; }
    .user-logIn-page .logIn-description form .link--follow {
      align-self: flex-end; }
  .user-logIn-page .logIn-description .input-error--big {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left; }

.user-logIn-page .logIn-socialMedia {
  padding: 24px 10px 10px 10px;
  max-width: 100%; }
  .user-logIn-page .logIn-socialMedia p {
    width: 100%;
    text-align: center; }
  .user-logIn-page .logIn-socialMedia .socialMedia-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    .user-logIn-page .logIn-socialMedia .socialMedia-buttons-container img {
      margin: 5px;
      cursor: pointer;
      height: 32px; }

.user-register .register-description {
  width: 100%; }
  .user-register .register-description .typography--h1 {
    color: #00965E;
    width: 100%;
    margin-bottom: 2.5rem; }
  .user-register .register-description > * {
    width: 100%; }
  .user-register .register-description p:nth-of-type(2) {
    font-family: "Sans", Arial, sans-serif;
    font-size: 18px;
    color: #2E3033;
    letter-spacing: 0;
    margin-bottom: 1rem; }
  .user-register .register-description .props-container {
    display: flex;
    align-items: flex-start; }
    .user-register .register-description .props-container .checkedIcon {
      margin-top: -2px;
      margin-right: 14px;
      position: relative; }
      .user-register .register-description .props-container .checkedIcon svg {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #00965E; }

.user-register__register-button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem; }

.u-header--inline {
  display: inline-flex;
  align-items: center; }

.u-font-v-middle {
  line-height: 1em;
  padding-top: 0.15em; }

.u-font-green {
  color: #00965E !important; }

.u-font-yellow {
  color: #FFA203 !important; }

.small-description {
  font-size: 13px !important;
  line-height: 20px !important;
  font-weight: 300; }

.c-link {
  cursor: pointer;
  color: #00965E;
  font-weight: 400;
  text-decoration: underline;
  transition: all 200ms ease !important; }
  .c-link:hover {
    color: #FFA203;
    text-decoration-color: #FFA203; }
  .c-link--blank {
    color: inherit !important; }
  .c-link--underline {
    text-decoration: underline !important; }

.c-btn-link {
  border: 2px solid #ffa203;
  background-color: #ffa203;
  text-transform: uppercase;
  padding: 12px;
  color: #2e3033;
  font-size: 14px;
  font-weight: 300; }
  .c-btn-link:hover {
    background-color: white;
    color: #ffa203; }
  .c-btn-link.c-btn-link--green {
    color: white;
    border: 2px solid #00965e;
    background-color: #00965e; }
    .c-btn-link.c-btn-link--green:hover {
      color: #00965e;
      background-color: white; }

.c-radio-group {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.c-radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.c-radio-group .checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #00965e; }

.c-radio-group:hover input ~ .checkmark {
  opacity: 0.6; }

.c-radio-group input:checked ~ .checkmark {
  background-color: white; }

.c-radio-group .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #2e3033; }

.c-radio-group input:checked ~ .checkmark:after {
  display: block; }

.c-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .c-checkbox a {
    color: #00965e;
    text-decoration: underline; }

.c-checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0; }

.checkmark {
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 2px solid #00965e;
  border-radius: 2px; }

.c-checkbox input:checked ~ .checkmark {
  background-color: #00965e; }

.c-checkbox input:checked ~ .checkmark:after {
  display: block; }

.b-aside {
  margin-top: 30px; }
  .b-aside > div {
    margin-bottom: 30px; }
    .b-aside > div > img {
      width: 100%;
      height: auto; }
  .b-aside .EventsCalendar--small .event-card .event-details h3 {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px; }
  .b-aside .EventsCalendar--small .event-card .event-details p {
    font-size: 10px;
    line-height: 12px; }

.container-fluid {
  padding: 0;
  margin: 0; }

.tools-page__filters {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #cfcccc; }

.events-page-header {
  background-image: url(assets/img/poledopopisu.jpg);
  padding: 16px 0;
  color: white;
  background-size: cover;
  background-position: center center; }
  .events-page-header a,
  .events-page-header .separator::after {
    color: #fff;
    opacity: 0.5; }
  .events-page-header a:hover {
    opacity: 1; }
  .events-page-header h1 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    margin: 23px 0; }
  .events-page-header h3 {
    font-size: 16px; }

.tools-page .tools-page__additional .tools-page__titile {
  text-align: center !important; }

.e-input {
  width: 100%;
  border: 2px solid #e9e9e9;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 15px;
  font-weight: 300; }
  .e-input:focus {
    border-color: #00965e;
    outline-color: #00965e; }

html {
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased; }

body {
  font-family: "Sans", Arial, sans-serif; }
  body.block-scroll {
    overflow: hidden;
    margin: 0;
    height: 100%;
    overflow: hidden; }

a {
  color: inherit; }

a:hover,
a:active {
  color: none;
  text-decoration: none;
  cursor: pointer;
  color: #FFA203;
  text-decoration-color: #FFA203; }

.brand-link {
  cursor: pointer !important;
  color: inherit;
  text-decoration: underline !important;
  transition: all 200ms ease !important; }
  .brand-link:hover,
  .brand-link a:active {
    color: #FFA203 !important;
    text-decoration-color: #FFA203 !important; }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.typography--h1 {
  text-transform: uppercase; }

@media (max-width: §px) {
  .ReactModal__Content {
    width: unset !important;
    top: 40px !important;
    left: 20px !important;
    right: 20px !important; }
    .ReactModal__Content .modal__login__text {
      padding: 72px 16px !important; } }

.modal__content {
  padding: 0 10px;
  margin-top: 72px; }
  @media (min-width: 768px) {
    .modal__content {
      padding: 0 100px; } }
  .modal__content h2 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold; }

.modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none; }
  .modal__close svg {
    width: 30px;
    height: auto; }
    .modal__close svg path {
      fill: #00965e; }

.img-grid-x3 {
  display: flex;
  margin: 0 -0.5rem; }
  @media (max-width: 767px) {
    .img-grid-x3 {
      flex-direction: column;
      margin: 0; } }
  .img-grid-x3 .img-grid-x3__content {
    width: 33%;
    margin: 0.5rem; }
    @media (max-width: 767px) {
      .img-grid-x3 .img-grid-x3__content {
        width: 100%;
        margin: 0.5rem 0; } }
    .img-grid-x3 .img-grid-x3__content > div:first-of-type {
      width: 100%;
      padding-bottom: 75%;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 1rem; }

.modal__meta {
  max-width: 468px;
  width: 100%;
  margin: 0 auto; }
