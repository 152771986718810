@import "src/styles/_colors.scss";

.topicCard {
  .premium-wrapper {
    background: $brand-color-green;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    font-size: 13px;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 700;
    span {
      color: white;
      text-transform: uppercase;
    }
  }

  max-width: 300px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  flex-direction: column;

  .card-image {
    height: 128px;
    width: 100%;
    flex: 0 0 128px;
    + .card-content {
      padding-top: 25px + 27px;
    }
  }

  .card-content {
    flex: 1;
    padding: 25px 10px 60px 10px;
    min-height: 270px;
    position: relative;
    // display: flex;
    // flex-direction: column;
    text-align: center;
    // align-items: center;
    background-color: #ffffff;

    .typography--h2 {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }

    .tool-text {
      text-align: center;
      width: 100%;
    }

    .bttn--small {
      width: 190px;
    }

    .button--yellow {
      position: absolute;
      bottom: 20px;
      width: 180px;

      p {
        padding: 0;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a:hover {
        color: $brand-color-yellow;
      }
    }
  }
}
