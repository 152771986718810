@import "src/styles/_breakpoints";

.c-article-with-poll {
  display: flex;
  padding: 0 15px;
  flex: 0 0 100%;
  margin-bottom: 30px;
  .CardArticle {
    flex: 0 0 65%;
    .article-image,
    .img-temp {
      min-height: 300px;
    }
  }
  @include respond-below(lg) {
    flex-wrap: wrap;
    .CardArticle {
      flex: 0 0 100%;
      height: auto;
    }
  }
}
