.cft-label {
  display: inline-block;
  &__label {
    display: inline-block;
    color: #2e3033;
    line-height: 1.5rem;
    font-size: 1rem;
  }
  &__with-children {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .cft-tooltip {
      min-width: 1.5rem;
      margin-left: 1rem;
      margin-right: 0;
    }
  }
  &__is-full-width {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    width: 100% !important;
    max-width: unset !important;

    .cft-label__label {
      text-align: left !important;
    }
  }
}

.narrow-labels {
  .cft-label {
    $this: &;
    display: flex;
    justify-content: center;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;

    & + input {
      position: absolute;
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__with-children {
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
