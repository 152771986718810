@mixin activeBox {
  transition: all 0.3s;
  border-color: rgba(0, 150, 94, 0.2);
  background-color: rgba(0, 150, 94, 0.2);
}

.cft-box {
  border: 1px solid #979797;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 0.938rem;

  svg {
    color: #9b9b9b;

    // TO-FIX: Fix after new svg arrived.
    .cft-box__svg-fix {
      margin-bottom: -0.5rem;
      margin-top: 0.5rem;
    }
  }

  & > p {
    margin: 0;

    &.cft-box__paragraph {
      min-height: 68px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      white-space: pre-wrap;

      &.cft-box__paragraph--isWide {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
      }

      &.cft-box__paragraph--isSmall {
        min-height: auto;
      }
    }
  }

  &.cft-box--isSmall {
    min-height: 100px;
  }

  &.cft-box--isMedium {
    min-height: 215px;
  }

  &.cft-box--isLarge {
    min-height: 260px;
  }

  &.cft-box--isActive,
  &:hover {
    @include activeBox;

    svg {
      path {
        color: #00965e;
      }
    }
  }

  &:hover {
    background-color: rgba(0, 150, 94, 0.1);
    border-color: rgba(0, 150, 94, 0.5);

    .cft-box__animation-btn-box {
      .cft-box__animation-btn-box__content {
        bottom: 0;
        transition: bottom 0.2s ease-in;
      }
    }
  }

  @media (min-width: 768px) {
    max-width: 210px;
    margin: 0.938rem;
  }

  &.cft-box--isInvalid {
    border-color: #efbcbc;
    background-color: #efbcbc;
  }

  .cft-box__animation-btn-box {
    height: 68px;
    position: relative;
    width: 100%;
    overflow: hidden;

    p {
      width: 100%;
      margin-bottom: 0;
    }

    .cft-button {
      height: 32px;
      margin-bottom: 0;
      margin-top: 0.5rem;
    }

    .cft-box__animation-btn-box__content {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      height: 68px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      bottom: calc(-0.5rem + -32px);
      transition: bottom 0.1s ease-out;
    }
  }

  @media (max-width: 767px) {
    & {
      width: 100%;
      padding: 0 1rem 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      text-align: left;
      flex-direction: row-reverse;
      cursor: pointer;
      margin-bottom: 0.938rem;

      svg {
        overflow: visible;
      }

      p {
        &.cft-box__paragraph {
          align-items: center;
          //white-space: pre;

          &.cft-box__paragraph--isWide {
            margin: 0;
            padding: 0;
            width: auto;
          }
        }

        &.cft-box__no-icon {
          margin: 0 auto;
        }
      }
    }

    .cft-button {
      display: none;
    }

    .cft-box__animation-btn-box {
      width: auto;

      .cft-box__animation-btn-box__content {
        position: revert;
      }
    }

    &.cft-box--isSmall {
      min-height: 38px;
    }

    &.cft-box--isMedium {
      min-height: 80px;
    }

    &.cft-box--isLarge {
      min-height: 98px;
    }
  }

  &.cft-box--isFullWidth {
    margin: 0.938rem 0 0 0;
    max-width: unset;
    width: 100%;
    padding: 0.73rem 1.5rem;
  }
}

.cft-box-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin-left: -0.938rem;
    margin-right: -0.938rem;
    width: calc(100% + 1.876rem);
  }
}
