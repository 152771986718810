.cft-number-field {
  width: 100%;
  &__input {
    width: 100%;
    position: relative;
    border: 2px solid #00965e;
    input {
      border: none;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      color: #2e3033;
      outline: none;
      line-height: 1.5rem;
      font-weight: 300;
      text-align: center;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &__control {
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 0;
    height: 100%;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e3033;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;

    &.cft-number-field__control--plus {
      right: 0;
    }
    &.cft-number-field__control--minus {
      left: 0;
      padding-bottom: 2px;
    }

    &:hover {
      cursor: pointer;
      transition: opacity 0.3s;
      opacity: 0.6;
    }
  }

  &.cft-number-field--isInvalid {
    .cft-number-field__input {
      border-color: #fd0059;
      border-width: 3px;
    }
  }
}
