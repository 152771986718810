@import "src/styles/_colors.scss";

.c-alert {
  border-radius: 14.5px;
  background-color: #0080ff;
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 12px;
  transition: all 0.3s ease-in-out;
  &-big {
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    position: fixed;
    z-index: 1000000;
    opacity: 1;
    visibility: visible;
    &--top {
      top: 10px;
      z-index: 1000000;
      + div {
        padding-top: 100px;
      }
    }
    &--bottom {
      top: auto;
      bottom: 10px;
    }
    &__container {
      padding: 15px;
      &--yellow {
        background-color: $brand-color-yellow;
      }
      &--green {
        background-color: $brand-color-green;
        color: #fff;
      }
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        &-buttons {
          flex: 0 0 100%;
        }
      }
      &--top {
        align-items: flex-start;
      }
      &-title {
        font-family: SansCond;
        font-size: 22px;
        // color: white;
        text-transform: uppercase;
      }
      &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &--hide {
    visibility: hidden;
    opacity: 0;
  }
  &-pwa {
    display: flex;
    align-items: center;
    z-index: 500;
    border-radius: 8px;
    &__icon {
      flex: 0 0 32px;
      img {
        width: 100%;
      }
    }
    &__desc {
      flex: 1;
      // font-size: 11px;
      // line-height: 13px;
      padding: 0 10px;
      img {
        height: 20px;
      }
    }
  }
}
