.restricted-article-window {
  width: 100%;
  margin: 0 auto;
  position: relative;

  header {
    color: #00965E;
    background-color: #E5F5F0;
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    h3 {
      font-family: "SansCond", Arial, sans-serif;
      text-transform: uppercase;
      font-size: 40px !important;
      line-height: 40px !important;
      color: #00965E !important;
      margin-bottom: 0;
      margin-top: 2.125rem;
    }

    p {
      font-weight: 400;
      font-size: .875rem;
      line-height: 1.5em;
      max-width: 25rem;
      margin: 1rem auto 1.5rem;
      color: black;
    }

    a.register-link {
      color: #00965e;
      text-decoration: underline;
      font-size: .8125rem;
      font-weight: 700;
      margin-top: 1.5rem;
    }

  }
}
