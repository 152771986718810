.popular-articles {
  background-color: #e9e9e9;
  margin-bottom: 30px;

  &__tabs > span {
    position: relative;
    display: inline-flex;
    background-color: #9b9b9b;
    width: 50%;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    height: 44px;
    cursor: pointer;
    color: white;

    &.active {
      background-color: #00965e;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #00965e;
        transform: rotate(45deg);
        position: absolute;
        bottom: -5px;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__article {
    display: block;
    padding: 12px;
    border-bottom: 1.5px solid rgba(128, 128, 128, 0.2);

    &__date {
      display: block;
      font-family: "SansCond", Arial, sans-serif;
      color: #808080;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }

    p {
      color: #2e3033;
      font-family: "Sans";
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__content--empty {
    text-align: center;
    padding: 1rem;
  }
}
