@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cft-report-progress {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 1rem;
  z-index: 1;
  margin: 0 30px;
  overflow: hidden;

  &.cft-report-progress--isOpen {
    z-index: 1100;
  }

  &__content {
    position: relative;
    background-color: rgb(236, 236, 236);
  }

  &__box {
    cursor: pointer;
    display: flex;
    height: 95px;
    width: 370px;
    background-color: #3f4151;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;

    .cft-report-progress__arrow {
      transition: transform 0.3s;
      fill: #fff;
      transform: scaleY(-1);
    }
  }

  &__reports {
    transition: max-height 0.6s ease-out;
    max-height: 0;

    &__wrapper {
      padding: 1.25rem;
      max-height: calc(100vh - 340px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 11px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: #3f4151;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
      }
    }

    .cft-button {
      width: calc(100% - 2.5rem);
      margin: 0 1.25rem 1.25rem 1.25rem;
    }
  }

  .cft-report-progress__single-report {
    margin-bottom: 1.563rem;

    p {
      margin-bottom: 0.625rem;
      font-weight: 300;
      font-size: 0.875rem;

      span {
        font-weight: 600;
        float: right;
        color: #00965e;
      }
    }
  }

  &__wrapper {
    margin-top: 13.125rem;
  }

  &__overlay {
    transition: opacity 0.6s;
    position: fixed;
    background-color: rgba($color: #373a3c, $alpha: 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    display: none;

    &.cft-report-progress__overlay--isOpen {
      display: block;
      animation: fadein 0.6s;
    }
  }

  &.cft-report-progress--isOpen {
    .cft-report-progress__reports {
      transition: max-height 0.6s ease-in;
      max-height: 700px;

      .cft-report-progress__reports__wrapper {
        opacity: 1;
      }
    }

    .cft-report-progress__content {
    }

    .cft-report-progress__box {
      .cft-report-progress__arrow {
        transform: scaleY(1);
      }
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    bottom: 3rem;
    width: 100vw;
    padding: 0;
    margin: 0;
    transition: background-color 0.5s;
    &__content {
      background-color: transparent;
      min-height: 70px;
      height: auto;
      display: flex;
      flex-direction: column;
    }
    &.cft-report-progress--isOpen {
      opacity: 1;
      background-color: rgb(236, 236, 236);
      .cft-report-progress__content {
        background-color: transparent;
        color: black;
      }
      bottom: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;

      .cft-progress-circle {
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        box-shadow: none;
        border: none;
        height: auto;
        width: auto;

        svg {
          border: none;
          padding: 0;
          background-color: transparent;
        }

        &__value {
          top: 29px;
        }
      }

      .cft-report-progress__arrow {
        display: block;
      }

      .cft-report-progress__box {
        background: #3f4151;
        align-items: center;
        justify-content: space-around;
      }

      .cft-report-progress__text {
        display: block;
      }

      .cft-report-progress__box_right_border {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
      }

      .cft-report-progress__box_left_border {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
      }
    }
    &__box {
      padding: 0;
      margin: 0;
      width: 100vw;
      height: auto;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    &__text {
      display: none;
    }
    &__arrow {
      display: none;
    }
    .cft-progress-circle {
      font-size: 10px;
      padding: 0;
      //border-bottom-left-radius: 50%;
      //border-bottom-right-radius: 50%;
      box-shadow: inset 15px -40px 0px -15px #00965f;
      height: auto;
      width: auto;
      min-height: 70px;
      display: flex;
      background-color: transparent;
      border-bottom: 1px solid #00965f;
      align-items: flex-end;
      margin-bottom: 0.2rem;

      justify-content: center;

      &__value {
        top: 35px;
      }

      svg {
        width: auto;
        height: 51px;
        background-color: #fff;
        border: 5px solid #00965f;
        border-radius: 50%;
        padding: 0.2rem;
        margin: 0;
      }
    }
    .cft-report-progress__box_right_border {
      width: 25px;
      height: 25px;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 300%;
        height: 300%;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: -15px 15px 0 0 #00965f;
        border-radius: 30px;
      }
    }
    .cft-report-progress__box_left_border {
      width: 25px;
      height: 25px;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 300%;
        height: 300%;
        position: absolute;
        bottom: 0;
        right: 0;
        box-shadow: 15px 15px 0 0 #00965f;
        border-radius: 30px;
      }
    }
  }
}
