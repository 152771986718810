.regagri-modal-container {
  top: 50%!important;
  position: absolute;
  left: 40px;
  right: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(241, 241, 241);
  overflow: hidden auto;
  outline: none;
  padding: 20px;
  @media (min-width: 768px) {
    transform: translateY(-60%);
  }
}

.tools-page {
  .custom-tooltip {
    .tooltip-content {
      @media (max-width: 767px) {
        left: unset;
        right: -20vw;
        top: 2rem;
      }
    }
  }

  &.optimization {
    .PageHeader {
      header {
        h1 {
          @media (max-width: 767px) {
            color: black;
          }
        }
      }
    }
  }

  .PageHeader {
    min-height: 454px;

    header {
      max-width: 760px;

      h1 {
        font-size: 60px;
        margin-bottom: 1.7rem;
        line-height: 60px;

        @media (max-width: 767px) {
          font-size: 40px;
          line-height: 40px;
          text-align: center;
        }
      }

      h3 {
        font-weight: 400;

        span {
          display: block;
          margin-bottom: 1.75rem;
        }

        @media (max-width: 767px) {
          display: none;
        }

        ul {
          list-style: none;
          padding-left: 1rem;

          li:before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #ffa203; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
        }
      }
    }

    @media (max-width: 767px) {
      min-height: 320px;
      overflow-x: hidden;

      .breadcrumbs {
        position: absolute;
        top: -1rem;
        padding: 1rem 0;
        width: 100%;
        background-color: #ececec;
        color: black;
        position: relative;

        a {
          color: rgba(0, 0, 0, 0.6);
        }

        &::after {
          content: "";
          background-color: #ececec;
          height: 100%;
          width: 100vw;
          position: absolute;
          top: 0;
          left: 100%;
        }

        &::before {
          content: "";
          background-color: #ececec;
          height: 100%;
          width: 100vw;
          position: absolute;
          top: 0;
          right: 100%;
        }
      }
    }
  }

  .tools-page__features {
    max-width: 960px;
    margin-bottom: 8rem;

    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }

    &__header {
      margin: 3rem 0;
      text-align: center;
      white-space: pre-wrap;
      h1 {
        font-family: "SansCond", Arial, sans-serif;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-transform: uppercase;
      }

      @media (max-width: 767px) {
        margin-bottom: 2rem;
      }
    }

    &__feature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      & > div {
        padding: 1rem 2rem;

        &.isLeft {
          margin-left: -2rem;
        }
      }

      ul {
        padding-left: 1rem;

        li {
          font-size: 13px;
          margin-bottom: 0.5rem;
          &::marker {
            color: #ffa203;
          }
        }
      }

      h3 {
        font-size: 16px;
        font-weight: bold;
        color: #00965e;
        margin-bottom: 1rem;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;

        img {
          width: 100%;
        }

        & > div {
          padding: 1rem 0;

          &.isLeft {
            margin-left: 0;
          }
        }

        &.isReverse {
          flex-direction: column-reverse;
        }
      }

      .c-widget__cta {
        max-width: 200px;
        margin: 2rem auto 0 auto;
      }
    }

    &__averagePrices {
      margin-bottom: 2rem;

      p {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 21px;
        text-align: center;
        width: 100%;

        svg {
          margin-right: 1rem;
        }
      }
    }
  }

  .tools-page__how {
    background-color: #00965e;
    padding: 4rem 0;

    .tools-page__titile {
      color: #fff;
    }

    .tools-page__gain__content {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: calc(100% - 70px);
        height: 39px;
        position: absolute;
        left: calc(50% + 33px);
        top: 0;
        background-image: url("../../assets/tools/icons/arrow_1.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 1199px) {
          top: 15px;
        }

        @media (max-width: 991px) {
          display: none;
        }
      }

      p {
        color: #fff;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .gain-icon {
          margin: 0 0 1rem 0;
        }

        p {
          width: calc(100% - 64px);
          text-align: center;
        }
      }
    }
  }

  .tools-page__gain {
    background-color: #ececec;
    padding: 4rem 0;

    &__content {
      .gain-icon {
        width: 74px;
        height: 74px;
        background-color: #ffa203;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        overflow: hidden;
      }
      p {
        margin-top: 2rem;
        font-weight: bold;
        color: #00965e;
        text-align: center;
      }
    }

    @media (max-width: 767px) {
      padding: 2rem 0;
    }

    .tools-page__gain__row {
      margin-top: 3rem;
    }
  }

  .tools-page__register {
    background-color: #ffffff;
    padding: 4rem 0;

    @media (max-width: 767px) {
      padding: 2rem 0 3rem 0;
    }
  }

  .tools-page__coolfarm {
    margin-bottom: 1rem;
    img {
      width: 100%;
      max-width: 320px;
      margin: auto;
    }

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  .tools-page__help, .tools-page__proceed {
    background-color: #00965e;
    padding: 4rem 0;
    .tools-page__titile {
      color: #fff;
    }

    @media (max-width: 767px) {
      padding: 2rem 0 3rem 0;
    }
  }

  .tools-page__proceed {
    background-color: #fff;
    .tools-page__titile {
      color: #212529;
    }
  }

  .tools-page__titile {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 3rem;
    text-transform: uppercase;
    white-space: pre-wrap;

    small {
      margin-top: 1rem;
      display: block;
      font-size: 1rem;
      color: #2e3033;
      text-transform: none;
      font-family: "Sans", Arial, sans-serif;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.tools-page__titile--toLeft {
      text-align: left;
    }
  }

  .c-widget__cta.tools-page__cta {
    max-width: 260px;
    margin: 0 auto;
    font-weight: 500;
    padding: 0.6rem 0.4rem;

    &:hover {
      background-color: white;
    }
  }

  .tools-page__additional {
    background-color: #ececec;
    padding: 4rem 0;
    .tools-page__titile {
      text-align: center;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    &__tools {
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .tools-page__meteo {
    img {
      margin: 8px 8px 2rem 8px;
    }
    p {
      text-align: center;
      color: #4d5765;
      font-size: 13px;
      margin-bottom: 2rem;
      white-space: pre;
    }

    .col-12 {
      @media (max-width: 767px) {
        flex-direction: column;
        img {
          max-height: 130px;
          margin: 2rem 0 !important;
        }
      }
    }
  }
}

.tools-page__paragraph {
  color: #fff;
  margin-bottom: 2rem;
  text-align: center;
  white-space: pre-wrap;
}

.tools-page__tools {
  margin: 4rem 0;
}

.tools-card {
  background-color: #ececec;
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  &__thumbnail {
    display: flex;
    background-position: center;
    background-size: cover;
    width: 250px;
    background-position: bottom right;

    @media (max-width: 992px) {
      width: 100%;
      height: 300px;
    }
  }

  &__content {
    width: calc(100% - 250px);
    padding: 2rem 2rem 1rem 2rem;

    @media (max-width: 992px) {
      width: 100%;
    }

    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      color: #00965e;
      margin-bottom: 1rem;
      text-transform: uppercase;
      position: relative;

      &.tools-card--badge-title {
        padding-right: 250px;

        @media (max-width: 992px) {
          padding-right: 0;
        }
      }

      .tools-card__login-badge {
        position: absolute;
        background-color: #2e3033;
        font-size: 9px;
        color: #ffffff;
        border-radius: 6px;
        padding: 0.25rem;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Sans", Arial, sans-serif;
        max-height: 43px;
        max-width: 250px;
        line-height: 1rem;
        font-weight: 300;
        text-transform: none;
        white-space: pre;

        @media (max-width: 992px) {
          top: -300px;
        }

        svg {
          margin: 5px;
          width: 17px;
          height: auto;
        }

        & > div {
          width: calc(100% - 30px);
          padding-right: 0.5rem;
        }
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 1rem;

      li {
        font-size: 13px;
        margin-bottom: 1rem;
        position: relative;
        padding-left: 1.5rem;
        &:before {
          content: "";
          width: 14px;
          height: 9px;
          transform: rotate(-45deg);
          display: block;
          border-left: 4px solid #ffa203;
          border-bottom: 4px solid #ffa203;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .c-widget__cta {
      font-weight: 500;
      width: 150px;

      @media (max-width: 992px) {
        margin: 2rem auto 0 auto;
        width: 100%;
      }
    }
  }
}

.tools-page__tools-types {
  margin: 2rem 0;

  .tools-page__tools-types__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @media (min-width: 992px) {
      flex-direction: column;
      flex-wrap: wrap;

      flex-direction: row;
    }

    @media (max-width: 991px) {
      margin-bottom: 5rem;
    }
  }

  @media (max-width: 991px) {
    .tools-page__titile {
      margin-bottom: 0;
    }

    background-color: #00965e;
    margin-top: 0;
    color: #fff;

    h1.tools-page__titile {
      small {
        color: #fff;
      }
    }
  }
}

.tools-page__cta {
  &:hover {
    background-color: transparent!important;
  }
}

.tools-page__widget {
  background-color: #ececec;

  .tools-page__widget_cover {
    background-color: grey;
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-position: center;
    background-size: cover;

    @media (max-width: 767px) {
      padding-top: 87%;
      background-position: top;
    }
  }

  .tools-page__widget__content {
    padding: 1.5rem;

    h3 {
      font-family: "SansCond", Arial, sans-serif;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      color: #00965e;
      margin-bottom: 2rem;
      text-transform: uppercase;
      max-width: 420px;
    }

    @media (max-width: 991px) {
      padding: 2rem 2rem 1rem 2rem;
    }
  }

  .tools-page__widget_content__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem;

    li {
      font-size: 13px;
      margin-bottom: 1rem;
      position: relative;
      padding-left: 1.5rem;
      color: #000;
      &:before {
        content: "";
        width: 14px;
        height: 9px;
        transform: rotate(-45deg);
        display: block;
        border-left: 4px solid #ffa203;
        border-bottom: 4px solid #ffa203;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }
}

.tools-page__demo {
  .tools-page__demo__icon {
    background-color: #2e3033;
    border-radius: 19px;
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .c-widget__cta {
    margin: 2rem auto;
    max-width: 250px;
    font-weight: 500;
  }

  .tools-page__note {
    color: #4d5765;
    font-size: 13px;
    max-width: 720px;
    text-align: center;
    margin: 0 auto 2rem auto;
  }

  .tools-page__demo__logos {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    align-items: center;

    img {
      margin: 0 1.5rem;
      height: 50px;
      width: auto;

      &.tools-page__demo__logos--rolnikon {
        height: 85px;
      }

      @media (max-width: 991px) {
        &:first-of-type {
          margin: 1rem 1.5rem 3rem 1.5rem;
        }
      }
    }
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
}

.tools-page__list {
  ol {
    li {
      padding: 1rem 0.5rem;
      font-weight: bolder;
      position: relative;
      span {
        font-weight: 300;
        @media (max-width: 991px) {
          width: 90%;
        }
      }

      &::before {
        display: block;
        content: "";
        width: calc(100% + 2rem);
        height: 1px;
        background-color: #000;
        top: 0;
        left: -1rem;
        opacity: 0.1;
        position: absolute;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}
