@import "src/styles/_colors.scss";

.c-content-bar {
  font-family: "Sans";
  background-color: $brand-color-green;
  color: $white;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  padding: 10px 8px;

  a {
    margin-left: 1ch;
    transition: all 200ms ease !important;
    text-decoration: underline !important;
    &:hover {
      color: $brand-color-yellow !important;
      text-decoration-color: $brand-color-yellow !important;
    }
  }
}
