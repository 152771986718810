.cft-actions-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    height: 11px;
  }

  svg {
    margin-left: 0.5rem;
    width: 18px;
    height: 18px;
  }

  &.cft-actions-buttons--cancel {
    svg {
      fill: #e92b5c;
    }
  }

  &.cft-actions-buttons--goBack {
    svg {
      fill: #00965e;
    }
  }

  &.cft-actions-buttons--goBackCalc {
    margin-left: -0.75rem;
    svg {
      margin-left: 0;
      margin-right: 0.5rem;
      fill: #00965e;
    }
  }

  &.cft-actions-buttons--export {
    width: 190px;
    height: 48px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;

    p {
      margin: 0;
    }

    svg {
      fill: #fff;
      overflow: visible;
      width: auto;
      height: auto;
      margin: 0 0.5rem 0 0;
    }
  }
  &.cft-actions-buttons--import {
    min-width: 150px;
    min-height: 50px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;

    p {
      margin: 0;
    }

    svg {
      fill: #fff;
      overflow: visible;
      width: auto;
      height: auto;
      margin: 0 0.5rem 0 0;
    }
  }

  &.cft-actions-buttons--export--isSmall {
    width: 117px;
  }

  @media (max-width: 990px) {
    margin: 0;
    &.cft-actions-buttons--export {
      width: 34px;
      height: 34px;
      padding: 0;
      display: flex;
      margin: auto;
      justify-content: center;
      p {
        display: none;
      }
      svg {
        fill: #00965e;
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0;
    &.cft-actions-buttons--export {
      width: 34px;
      height: 34px;
      padding: 0;
      display: flex;
      margin: auto;
      justify-content: center;

      p {
        display: none;
      }

      svg {
        fill: #00965e;
      }
    }
    &.cft-actions-buttons--import {
      min-width: 300px;
      justify-content: center;
      background-color: #00965e;
      p {
        font-size: 14px;
      }
    }
    &.cft-actions-buttons--cancel {
      width: 34px;
      height: 34px;
      padding: 0;

      span {
        display: none;
      }

      svg {
        transform: scale(1.7);
      }
    }
    &.cft-actions-buttons--goBack {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      min-width: 120px;
      height: auto;
      padding: 0;
      margin: 0;
      svg {
        //transform: scale(1.5);
      }
      span {
        font-size: 0.6rem;
        //display: none
      }
    }

    &.cft-actions-buttons--goBackCalc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      min-width: 120px;
      height: auto;
      padding: 0;
      margin: 0;
      svg {
        //transform: scale(1.5);
      }
      span {
        font-size: 0.6rem;
      }
    }
  }
}
