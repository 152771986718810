@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints";

.footer {
  padding: 20px 0;

  &__break {
    margin: 20px 0;
    height: 1px;
    background: #EAE9EB;
  }

  &__social-media {
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    &-icon {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $brand-color-green;
      width: 36px;
      height: 36px;
      padding: 6px;
    }
  }

  &__author-column {
    display: flex;
    justify-content: center;
    
    @include respond-above('lg'){
      justify-content: flex-end;
    }
    @include respond-below('sm'){
      justify-content: flex-start;
    }
  }

  &__footer-menu {
    list-style: none;
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    align-items: center;
    height: 100%;
    gap: 24px;

    // > :first-child {
    //   padding-right: 15px;
    // }

    // > :last-child {
    //   padding-left: 15px;
    // }

    padding: 20px 0;
    @include respond-below('lg'){
      padding: 0;
      margin: 0;
    }

    @include respond-below('md'){
      padding: 0;
      margin-bottom: 32px;
      gap: 12px;
      flex-wrap: wrap;
      &__menu-item {
        flex: 0 0 100%;
      }
    }

    &__menu-item {
      padding-right: 7.5px;
      padding-left: 7.5px;
      display: inline-block;

      a {
        display: inline-block;
        white-space: nowrap;
      }
      &__header {
        font-family: "Sans", Arial, sans-serif;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0;
        text-align: center;
        
        &:hover {
          p {
            color: $brand-color-green;
          }
        }
        
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &__footer-signature {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    color: $black;
    font-family: "Sans", Arial, sans-serif;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 25px;
    text-align: center;
    margin: 0;
    height: 100%;

    > span {
      color: $brand-color-green;
      text-decoration: underline;
      font-weight: 700;
      margin-left: 5px;
      margin-right: 5px;
      
      a {
        color: $brand-color-green;
        text-decoration: underline;
        transition: all 200ms ease;
        &:hover {
          color: $brand-color-yellow !important;
          text-decoration-color: $brand-color-yellow !important;
        }
      }
    }
  }

  &__app-logo {
    max-height: 40px;
    &-wrapper {
      display: flex;
      justify-content: center;

      @media (min-width: 1200px) {
        justify-content: flex-start;
      }
    }
  }

  border-top: 2px solid #ececec;
}
