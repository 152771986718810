@import "src/styles/_colors.scss";

$containerWidth: 586px;
$verticalPadding: 114px;
$horizontalPadding: 40px;

.calculator-container {
  input {
    width: 100%;
  }

  .back-btn {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }

  .credit-container {
    width: 100%;
    max-width: $containerWidth;
    min-height: 200px;
    background-color: $side-color-lightgrey;
    padding: $horizontalPadding $verticalPadding $horizontalPadding $verticalPadding;
    border: 1px solid #f2f2f2;

    @media (max-width: 576px) {
      padding: $horizontalPadding / 4 $verticalPadding / 4;
    }

    &--dark {
      background-color: $side-color-lightgrey;
      margin-top: 2px;
    }

    .typography--h2 {
      width: 100%;
      color: $black;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      margin: 5px 0 30px;
    }

    .back-button-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .credit-amount-container {
      .computed-value-description {
        display: flex;
        align-items: center;
        justify-content: center;

        .computed-value {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $brand-color-green;
        }
      }
    }

    .input-adornment-text {
      font-size: 18px;
      color: $brand-color-green;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 28px;
      // margin-top: 24px;
      padding-top: 2px;
    }

    .input-with-slider {
      margin-top: 20px;
    }

    .radio-fields-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      >.radioButton {
        width: 50%;

        span {
          font-family: "Sans", Arial, sans-serif;
          font-weight: 300;
          margin-left: 10px;
        }
      }
    }

    .computed-value-description {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .computed-value-description--summary {
      @extend .computed-value-description;

      margin-bottom: 0.1rem;
      justify-content: flex-start;
    }

    .computed-value {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $brand-color-green;
    }

    .computed-value--summary {
      @extend .computed-value;

      margin-bottom: 0.1rem;
      justify-content: flex-start;
    }

    .error-message {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      font-family: "font-family: "Sans ";
font-weight: 300;
      ";
margin-top: 40px;
      text-align: left;

      &--pink {
        font-family: "Sans", Arial, sans-serif;
        color: #e92b5c;
      }

      &--red {
        font-family: "Sans", Arial, sans-serif;
        color: $side-color-red;
      }
    }

    .next-step-button-container {
      background-color: $brand-color-green;
      max-width: $containerWidth;
      width: 100%;
      min-height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      >div {
        min-width: 160px;
      }
    }
  }
}
