.bg {
  &-two-columns {
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      right: 0;
      left: 50%;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    &--gray {
      &:after {
        background-color: $side-color-lightgrey;
      }
    }
  }
  &--main {
    background: $side-color-lightgrey;
  }
}
