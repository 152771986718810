@import "src/styles/_colors.scss";

.events-block {
  padding-bottom: 20px;
  width: 100%;
  min-height: 900px;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  position: relative;
  align-items: center;

  @media (min-width: 1200px) {
    align-items: flex-start;
  }

  .button--small {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px);
  }

  .typography--h1 {
    margin-top: 64px;
    color: $brand-color-green;
  }

  .buttonAdditional--grey,
  .buttonAdditional--green {
    margin-top: 0;
    margin-bottom: 0;
  }

  .slider-control-topcenter {
    background-color: #fff !important;
  }
}

.events-calendar {
  .buttonAdditional {
    &--green,
    &--grey {
      display: flex;
      justify-content: center;
      align-self: center;
      width: auto;
    }
  }
}
