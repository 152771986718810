.widget__green-changes {
  font-family: "SansCond", Arial, sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  margin-top: 210px;
}

.widget__meteos {
  margin-top: 60px;
  font-size: 16px;
  font-weight: 700;

  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    padding-right: 60px;
  }
}

.widget__cft {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 90px;
  color: #000;

  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    padding-right: 60px;
  }
}

.widget__contest {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 120px;
  padding-right: 90px;
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
  }
}

.widget__articles-heels {
  margin-top: 120px;
  font-size: 16px;
  font-weight: 700;
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    li {
      position: relative;
      display: block;
      padding-left: 8px;
      padding-left: 30px;
      margin-right: 20px;
      margin-bottom: 10px;

      &::before {
        content: "";
        display: block;
        border: 3px solid #ffa203;
        width: 15px;
        height: 8px;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 4px;
        margin: auto;
        border-top: none;
        border-right: none;
      }
    }
  }
}

.widget__nitrogen-calculator {
  height: 260px;

  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2e3033;
  }
}

.widget__estate {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 32px;
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
  }

  span {
    display: block;
    padding-right: 72px;
  }
}

.widget__eko_game {
  margin-top: 174px;
  color: #000;

  h3 {
    text-transform: uppercase;
    position: relative;
    word-spacing: 1.5rem;
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: #079a62;
      border-radius: 50%;
      top: 8px;
      left: 58px;
    }
  }
}

.widget__competition {
  min-height: 170px;
  .c-widget__cta {
    max-width: 200px;
  }
}

.widget__quiz {
  span {
    max-width: 100px;
    display: block;
    margin-bottom: 50px;
    margin-top: 40px;
  }
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.widget__academy {
  p {
    display: block;
    margin-bottom: 110px;
  }
  h3 {
    font-size: 28px;
    font-family: "SansCond", Arial, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.widget__voluntarySaleCalculation {
  width: 100%;
  text-align: center;
  padding: 4rem 2rem;
  margin: 1rem 0 2rem 0;
  max-width: unset;
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .c-widget__cta {
    max-width: 230px;
    margin: 0 auto;
  }
}

.widget__kredytomat {
  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2e3033;
  }
  p {
    color: #2e3033;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6rem;
  }
}

.widget__pomidor {
  margin-top: 60px;
  font-size: 16px;
  font-weight: 700;

  h3 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    padding-right: 60px;
  }
}
