.cft-leaving-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .cft-leaving-box {
    position: relative;
    width: 570px;
    height: 480px;
    background-color: #fff;

    .cft-leaving-exit {
      position: absolute;
      right: 0;
      top: 0;
      min-width: 66px;
      min-height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00965e;
      cursor: pointer;

      svg {
        transform: scale(2.5);
        color: #fff;
      }
    }

    .cft-leaving-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem 4.6rem;

      .cft-leaving-icon {
        max-width: 64px;
        min-width: 64px;
        min-height: 64px;
        color: red;
        margin-bottom: 2rem;
      }

      .cft-leaving-title {
        color: #2e3033;
        font-family: "SansCond", Arial, sans-serif;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0 !important;
        word-spacing: 0 !important;
        line-height: 40px;
        text-align: center;
        margin-bottom: 2rem;
      }

      .cft-leaving-description {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        text-align: center;
        font-size: 16px;
      }

      .cft-leaving-export-button {
        position: absolute;
        bottom: 58px;
        cursor: pointer;
      }

      .cft-leaving-back-button {
        position: absolute;
        bottom: 0;
        transform: translateY(40px);
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        text-shadow: 0.1px 0.1px #fff;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 767px) {
    .cft-leaving-box {
      height: 80% !important;
      width: 100% !important;
      position: absolute !important;
      top: 0 !important;
      .cft-leaving-icon{
        margin-bottom:.5rem !important;
      }

      .cft-leaving-title {
        margin-bottom: .5rem !important;
      }
    }
    .cft-leaving-export-button {
      position: absolute !important;
      bottom: 2% !important;
    }
  }
}
