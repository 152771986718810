@mixin EmbedBNPPSans($path, $weight: normal, $style: normal) {
  @font-face {
    $f: "#{$path}.";
    $name: BNPPSans;
    font-family: "Sans";
    src: url("#{$f}eot");
    src: url("#{$f}eot?#iefix") format("embedded-opentype"),
      url("#{$f}woff") format("woff"), url("#{$f}ttf") format("truetype"),
      url("#{$f}svg") format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include EmbedBNPPSans("./assets/fonts/BNPPSans/BNPPSans-300", 300, normal);
@include EmbedBNPPSans("./assets/fonts/BNPPSans/BNPPSans-400", 400, normal);
@include EmbedBNPPSans("./assets/fonts/BNPPSans/BNPPSans-700", 700, normal);
// @include EmbedBNPPSans(
//   "./assets/fonts/BNPPSans/BNPPSans-700-Italic",
//   700,
//   italic
// );

@mixin BNPPSans($weight: normal, $style: normal) {
  font-family: "BNPPSans";
  font-weight: $weight;
  font-style: $style;
}

@mixin EmbedBNPPSansCond($path, $weight: normal, $style: normal) {
  @font-face {
    $f: "#{$path}.";
    $name: BNPPSansCond;
    font-family: "SansCond";
    src: url("#{$f}eot");
    src: url("#{$f}eot?#iefix") format("embedded-opentype"),
      url("#{$f}woff") format("woff"), url("#{$f}ttf") format("truetype"),
      url("#{$f}svg") format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include EmbedBNPPSansCond(
  "./assets/fonts/BNPPSansCond/BNPPSansCond-300",
  300,
  normal
);
@include EmbedBNPPSansCond(
  "./assets/fonts/BNPPSansCond/BNPPSansCond-400",
  400,
  normal
);
@include EmbedBNPPSansCond(
  "./assets/fonts/BNPPSansCond/BNPPSansCond-700",
  700,
  normal
);

@mixin BNPPSansCond($weight: normal, $style: normal) {
  font-family: "BNPPSansCond";
  font-weight: $weight;
  font-style: $style;
}
