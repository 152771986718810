.real-estate-categories {
  .real-estate-categories__property-types {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 992px) {
      width: calc(100% + 2rem);
      margin: 0 -1rem;
    }
  }

  .real-estate-categories__property-type {
    width: 100%;
    background-color: #00965e;
    margin: 0 0 1rem 0;
    text-align: center;
    padding-bottom: 1.5rem;

    @media (min-width: 992px) {
      margin: 0 1rem 2rem 1rem;
      width: calc(25% - 2rem);
    }

    .thumbnail {
      width: 100%;
      padding-top: 75%;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
    }

    h6 {
      color: #ffffff;
      font-family: "SansCond", Arial, sans-serif;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;
      margin: 1rem 0;
    }

    a {
      background-color: #ffa203;
      border: 2px solid #ffa203;
      padding: 0.5rem 21px 0.4rem 21px;

      &:hover {
        color: #ffa203;
        background-color: transparent;
      }
    }
  }
}
