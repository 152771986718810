.VideosList {
  &__list {
    padding: 0.5rem 0;
    height: 304px;
    overflow-y: hidden;
    scroll-behavior: smooth;
    border-top: 6px solid white;
    .VideoCover {
      padding: 0.5rem 0;
      border-top: 1px solid #d8d8d8;

      &:last-of-type {
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;

    button {
      border: 1px solid #c4c7c7;
      color: #c4c7c7;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 11px;
      border-radius: 2px;
      background: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 22px;
        width: 14px;
      }

      &:hover {
        border-color: #00965e;
        color: #00965e;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
