@import "src/styles/_colors.scss";

%basic-span-style {
  // height: 24px;
  color: $black;
  font-family: "Sans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  margin-left: 5px;
}

%basic-input-style {
  height: 24px;
  width: 24px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  outline: 2px solid $brand-color-green;
}

.checkbox {
  display: flex;
  flex-direction: row;

  &--100 {
    width: 100% !important;
  }

  &--higher {
    > span {
      font-size: 14px !important;
    }
  }

  label {
    flex: 0 0 34px;
    cursor: pointer;
    display: flex;
    position: relative;

    input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      height: 24px;
      width: 24px;
      outline: 2px solid $brand-color-green;
      background-color: #ffffff;
      margin-right: 10px;
      cursor: pointer;
      &::-ms-check {
        display: none;
      }
    }

    .checkbox-replacement {
      position: absolute;
      left: -4px;
      top: -4px;
      display: flex;
      align-items: center;
      justify-items: center;
      margin: 0;
    }

    .checkedIcon {
      cursor: pointer;
    }
  }

  &--error {
    label {
      input {
        outline: 2px solid $side-color-red;
      }
    }
  }

  span {
    width: 100%;
    @extend %basic-span-style;
  }
}
