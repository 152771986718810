@import 'src/styles/_colors.scss';

%text-style {
    padding:7px 8px 5px 8px;
    display: inline-block;
    color: #FFFFFF;
    border-radius: 2px;
    font-family: "Sans", Arial,sans-serif;
    font-size: 12px;	
    line-height: 12px;	
    text-align: center;
    margin: 5px;
    background-color: #ffffff;
}

.category {
    &--active {
        display: flex;
        flex-direction: row;
        background-color: #ffffff !important;
        box-shadow: none !important;

        & > span{
            @extend %text-style;
            background-color: $brand-color-yellow;
        }

        img {
            width: 24px;
            height: 24px;

        }
    }

    &--basic {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
        background-color: #ffffff !important;
        box-shadow: none !important;

        & > span {
            @extend %text-style;
            background-color: $black;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
}
