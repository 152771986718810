.cft-widget {
  width: 100%;
  height: 95px;
  background-color: #00965e;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  &__progress {
    width: 95px;
    height: 95px;
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0.25rem;
    width: calc(100% - 95px);
    h3 {
      font-family: "SansCond", Arial, sans-serif;
      text-transform: uppercase;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 1.75rem;
      }
    }

    a {
      width: 100%;
      margin-top: 0.5rem;
      @media (min-width: 768px) {
        max-width: 121px;
        min-width: 121px;
        margin-top: 0;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 1.25rem;
    }
  }

  @media (min-width: 768px) {
    max-width: 580px;
  }
}
