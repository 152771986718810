@import "src/styles/_colors.scss";

.breadcrumbs {
  position: relative;
  z-index: 1;
  font-size: 13px;

  a {
    transition: all 200ms ease;
    color: $brand-color-green;

    &:hover {
      color: $brand-color-yellow !important;
      text-decoration-color: $brand-color-yellow !important;
    }
  }
  .separator {
    &:after {
      content: "/";
      color: #66737F;
      display: inline-block;
      margin: 0 8px;
    }
  }
}
