.c-widget {
  max-width: 270px;
  padding: 20px;
  background-color: #00965e;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  margin-bottom: 30px;

  &__cta {
    transition: all 0.3s ease-in-out;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #ffa203;
    width: 100%;
    color: #2e3033;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4rem;
    border: 2px solid #ffa203;

    &:hover {
      color: #2e3033;
      background-color: transparent;
      background-color: #fff;
    }
  }

  &__content {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
  }

  &.c-widget--isHorizontal {
    max-width: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
