@import "src/styles/_colors.scss";

.share-article {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
  align-items: flex-start;
  position: sticky;
  top: 8rem;

  &--inline {
    flex-direction: row;
  }

  p:first-of-type {
    color: $black;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 24px;
    margin: 0 5px 0 0;
    text-transform: uppercase;
  }

  .facebook,
  .linkedin,
  .twitter,
  .share-icon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-color-green;
    width: 3rem;
    height: 3rem;
    padding: .375rem;
    cursor: pointer;
  }
}
