.temp-share-btns-component-horizontal {
  padding: 3rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
.temp-share-btns-component-vertical {
  display: none;
  position: fixed;
  top: 285px;
  left: 50%;
  transform: translateY(-50%) translateX(-670px);
}

@media (min-width: 1400px) {
  .temp-share-btns-component-vertical {
    display: block;
  }
  .temp-share-btns-component-horizontal {
    display: none;
  }
}

@media (width: 767px),
  (min-width: 930px) and (max-width: 990px),
  (width: 1199px)
{
  .temp-share-btns-component-vertical {
    display: block;
    top: 8rem !important;
    left: 2rem !important;
    transform: translateY(0) translateX(0);
  }
  .temp-share-btns-component-horizontal {
    display: none;
  }
}