@import "src/styles/_colors.scss";

.c-add-filed-form {
  label {
    width: 100%;
    input {
      margin-top: 6px;
      width: 100%;

      &:checked + .checkmark {
        background-color: white !important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHRpdGxlPmljby9jaGVjazwvdGl0bGU+PGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+PGcgaWQ9Imljby9jaGVjayIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgaWQ9Imljby9uZXh0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC4wMDAwMDAsIDExLjAwMDAwMCkiIGZpbGw9IiMyRTMwMzMiIGZpbGwtcnVsZT0ibm9uemVybyI+PHBvbHlnb24gaWQ9IlBhdGgiIHBvaW50cz0iNC4zNDg5MzU4NyA2LjE0NDgyMjg0IDExLjI1NTYwODIgLTAuNzIzMTg3NDY3IDEzLjM3MDk2NjIgMS40MDQwNzg1IDQuMzQ3NjEzNzkgMTAuMzc2OTIwMiAtMC4zOTgxMjQ1MjEgNS42NTE4NDY3NiAxLjcxODU2MjE2IDMuNTI1OTAyODUiPjwvcG9seWdvbj48L2c+PC9nPjwvc3ZnPg==") !important;
        background-position: center;
        background-repeat: no-repeat;
      }

      & + .checkmark.is-error {
        border: 2px solid $side-color-red !important;
      }
    }
  }

  &__title-max {
    float: right;
    font-weight: 300;
    margin: 6px 0;
    font-size: 14px;
  }

  &__tags-small {
    font-weight: 300;
    display: block;
    margin: 10px 0;
  }

  &__selected-tags {
    margin: 8px 0 32px 0;
  }

  &__promoted_tags {
    border: 2px solid #e9e9e9;
    padding: 20px;
  }

  &__terms {
    margin: 30px 0;
  }

  &__actions {
    text-align: center;
    margin: 30px 0;
  }

  button[type="submit"] {
    display: inline-flex;
    height: 48px;
    width: 207px;
    text-transform: uppercase;
    font-weight: 300;
    align-items: center;
    justify-content: center;
  }

  .select-tags__indicators {
    display: none;
  }

  .select-tags__control {
    border: 2px solid #e9e9e9 !important;
    border-radius: 0;

    &.select-tags__control--is-focused {
      border-color: #00965e !important;
      outline-color: #00965e !important;
      box-shadow: 0 0 0 1px #00965e !important;
    }
  }

  .input-error {
    color: #e92b5c;
    text-align: left;
  }
}
