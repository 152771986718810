.cft-datepicker-field {
  width: 100%;
}

.datepicker-container {
  position: relative;

  svg {
    right: 12px;
    top: 10px;
    position: absolute;
    width: 18px;
    fill: #979797;
    opacity: 0.8;
  }
}

.react-datepicker__input-container {
  width: 100%;
  position: relative;
  border: 2px solid #00965e;

  input {
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #2e3033;
    outline: none;
    line-height: 1.5rem;
    font-weight: 300;
  }
}

.react-datepicker-popper {
  width: 100%;
  padding-top: 0px !important;
  z-index: 999;
  border: 2px solid #00965e;
  margin-top: -2px;

  &[data-placement^=top] {
    padding-bottom: 0px;
    margin-bottom: -2px;

  }

  .react-datepicker {
    &__navigation {
      height: 62px;
      width: 55px;

      &:focus {
        outline: none;
      }

      span::before {
        border-color: #00965e;
        height: 12px;
        width: 12px;
      }
    }

    &__navigation-icon--previous {
      right: -10px;
    }

    &__navigation-icon--next {
      left: 2px;
    }

    &__month-container {
      float: initial;
    }

    &__current-month {
      margin-top: 15px;
    }

    &__header {
      border: none;
      background-color: #fff;
    }

    &__day-names {
      margin: 20px 0 0px 0;

      &>div {
        color: #979797;
        width: 2.3rem;
      }
    }

    &__month {
      margin: 0;
      padding: 0 0 15px 0;
      background-color: #fff;
    }

    &__day {
      padding: 4px 0;
      width: 2.3rem;

      &--selected,
      &--keyboard-selected {
        border-radius: 50%;
        background-color: #00965e;
      }

      &:hover {
        border-radius: 50%;
        background-color: #c5c5c5;
      }
    }
  }
}
