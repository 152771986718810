.cft-table {
  width: 100%;
  font-size: 0.75rem;

  thead {
    tr {
      th {
        padding: 0.75rem 0 0.75rem 0.75rem;
        text-transform: uppercase;
        &:first-child {
          padding-left: 2.5rem;
          width: 230px;
        }
        &:last-child {
          padding-right: 2.5rem;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        color: #00965e;
        padding: 0.75rem 0 0.75rem 0.75rem;

        &:first-child {
          padding-left: 2.5rem;
          color: #666f85;
          width: 230px;
          text-transform: capitalize;
        }
        &:last-child {
          padding-right: 2.5rem;
        }
      }

      &:nth-child(odd) {
        background: #ececec;
      }
    }
  }
}
