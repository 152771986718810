@import "src/styles/_breakpoints";
@import "src/styles/_colors.scss";


.Sections {
  $this: &;

  &--dark {
    background-color: #F7F7F7;
  }

  &__articles {
    display: grid;
    gap: 20px;
    padding: 0 11px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

    &--no-padding {
      padding: 0;
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 32px;
    @include respond-below('lg'){
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
    }
    &.has-categories {
      justify-content: space-between;
    }
  }

  &__more {
    color: #00965E;
    font-size: 13px;
    line-height: 1em;
    text-transform: none;
    font-family: "Sans", Arial, sans-serif;
    font-weight: 700;
    text-decoration: underline;
    min-width: max-content;
    transition: all 200ms ease;
    @include respond-below('lg'){
      position: absolute;
      right:0;
      top: 32px;
      margin-top: 0.5em;
    }
    &--higher {
      position: absolute;
      right:0;
      top: 32px;
      margin-top: 0.5em;
    }
    &:hover {
      text-decoration: underline;
      color: $brand-color-yellow !important;
      text-decoration-color: $brand-color-yellow !important;
    }
  }

  &__tags {
    color: #6d6e70;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    font-family: "Sans", Arial, sans-serif;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    @include respond-below('lg'){
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-y: auto;
      // margin-right: 2rem;
    }

    #{$this}__tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 12px 10px;
      background: #EAE9EB;
      border-radius: 3px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1em;
      cursor: pointer;
      min-width: max-content;
      &:hover, &.is-active {
        background: #2E3033;
        color: white;
      }
    }
  }

  &__tags__title {
    max-width: 100px;

    &__title--big {
      text-transform: uppercase;
      max-width: 210px;
    }
  }

  &__subtitle {
    color: #808080;
    font-family: "Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 12px;
    position: relative;
  }

  &_show-off {
    button.section_show-off__add-photo {
      font-family: "Sans", Arial, sans-serif;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #00965e;
      font-size: 14px;
      color: white;
      font-weight: 300;
      text-transform: uppercase;
      border: 2px solid #00965e;
      padding: 12px 40px 10px 20px;
      line-height: 14px;
      &:hover {
        background-color: white;
        color: #00965e;
      }
      &::after {
        content: "+";
        font-size: 40px;
        position: absolute;
        right: 8px;
        top: 13px;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }
  }
}
