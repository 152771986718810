.cft-chicken-broiler-report-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .custom-tooltip {
    background-color: white;
    padding: 4px 8px;
    border-radius: 4px;

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}
