.gallery-row {
  &__filter {
    padding: 15px 0;
    margin-bottom: 15px;
    color: rgba(46, 48, 51, 0.4);
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    align-items: center;
    align-content: center;
    display: flex;
    border-bottom: 2px solid #e9e9e9;

    .c-radio-group {
      display: inline-block;
      color: #2e3033;
      font-size: 14px;
      text-transform: lowercase;
      font-weight: 300;
      margin-left: 15px;
      margin-bottom: 0;
    }
  }

  .ShowOffField__rating {
    margin-top: -10px;
  }

  .img-uploaded {
    display: flex;
    background-color: rgba(0, 150, 94, 0.2);
    border: 2px solid #00965e;
    border-radius: 4px;
    font-size: 14px;
    align-items: center;
    width: 100%;
    margin: 30px 0 8px 0;

    polygon {
      fill: #00965e;
    }

    svg {
      width: 72px;
      height: 72px;
    }

    p {
      margin: 0;
      a {
        text-decoration: underline;
      }
    }
  }
}

.gallery-tags {
  background-color: #e9e9e9;
  padding: 10px;
  h3 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    margin: 6px 0 24px;
    font-weight: 600;
  }
}

.gallery-top3 {
  .ShowOffFieldContainer > .row {
    display: flex;
    flex-direction: column;

    .col-md-4 {
      width: 100%;
      flex: 1;
      max-width: unset;
    }
  }

  background-color: #00965e;
  color: white;
  padding: 10px;

  h3 {
    text-transform: uppercase;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    margin: 6px 0 24px;
    font-weight: 600;
  }

  .ShowOffField__author {
    color: white;

    span {
      color: white;
    }
  }

  .ShowOffField {
    border-bottom: 2px solid rgba(233, 233, 233, 0.6);
  }

  .c-photos-viewer {
    margin: 0 !important;
  }
}

.gallery-select-tag {
  margin-top: 30px;
  max-width: 384px;
  color: #2e3033;
  .gallery-select-tags__indicators {
    .gallery-select-tags__indicator-separator,
    .gallery-select-tags__dropdown-indicator {
      display: none;
    }
  }

  .gallery-select-tags__control {
    border: 2px solid #00965e !important;
    border-radius: 0;

    &.gallery-select-tags__control--is-focused {
      border-color: #00965e !important;
      outline-color: #00965e !important;
      box-shadow: 0 0 0 1px #00965e !important;
    }
  }
}

.events-page-header {
  background-size: cover;
  background-position: center center;
  .btn-add-photo {
    background-color: #ffa203;
    color: #2e3033;
    border-color: #ffa203;
  }
}

.gallery-tags__show-all {
  font-size: 12px;
  cursor: pointer;
  display: block;
  margin-top: 12px;

  &:hover {
    opacity: 0.6;
  }
}
