.real-estate-wow-it-work {
  position: relative;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    background-color: #00965e;
    top: 0;
    left: -100vw;
    width: 200vw;
    height: 100%;
  }

  header {
    h2 {
      color: #ffffff;
    }
  }

  .real-estate-wow-it-work__note {
    color: white;
    font-weight: 500;
    a {
      text-decoration: underline;
    }
  }

  .icon {
    display: inline-flex;
    width: 100px;
    height: 100px;
    background-color: #ffa203;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 2rem 0;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    color: #ffffff;
    margin: 1.5rem 0 1rem 0;
    font-weight: 300;
  }
}
