.cft-graph {
  display: flex;
  width: 100%;
  min-height: 300px;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (max-width: 767px) {
    min-width: 720px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  & > div {
    display: flex;
    width: 100%;
    justify-content: space-around;
    min-height: 68px;
    max-height: 68px;
    position: relative;
  }

  &.alternative {
    .cft-graph__bar {
      &--isNegative {
        .cft-graph__bar__filed {
          border-radius: 20px;
          width: 26px;
        }  
      }
      &__filed {
        border-radius: 20px;
        width: 26px;
      }
      &__tip > span, &__title {
        left: 40%;
      }
      &__title {
        font-size: 0.6rem;
        font-weight: bold;
        color: #2E3033;
        word-break: normal;
      }
      &__tip > span {
        font-size: 0.7rem;
      }
    }
  }
}

.cft-graph__bar {
  position: relative;
  width: 34px;
  z-index: 10;

  &__filed {
    position: absolute;
    width: 34px;
    bottom: 0;
    background-color: #00965e;
    border-radius: 4px 4px 0 0;
  }

  &__title {
    width: 60px;
    font-size: 0.5rem;
    color: #666f85;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: -4.25rem;
    left: 50%;
    transform: translate(-50%, 0);
    right: 0;
    height: 4rem;
    word-break: break-word;
  }

  &__tip {
    position: relative;
    width: 34px;
    display: block;
    & > span {
      display: block;
      font-size: 0.625rem;
      font-weight: bold;
      color: #00965e;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0 auto;
      height: 14px;
    }
  }

  &.cft-graph__bar--isNegative {
    .cft-graph__bar__filed {
      bottom: -100px;
      border-radius: 0 0 4px 4px;
    }

    .cft-graph__bar__title {
      bottom: 0.5rem;
      align-items: flex-end;
    }
  }
}

.cft-graph__scale {
  width: 100%;
  height: 1px;
  background-color: #979797;
  opacity: 0.5;
  position: absolute;
}

.cft-graph__legend {
  position: absolute;
  left: -0.5rem;
  width: 0;
  display: flex;
  justify-content: flex-end;

  span {
    display: block;
    position: absolute;
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #666f85;
    top: 50%;
    transform: translate(0, -50%);
  }
}
