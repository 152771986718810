.c-heading {
  &--tertiary {
    font-family: "Sans", Arial,sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  &--report {
    font-family: "Sans", Arial,sans-serif;
    font-weight: bold;
    font-size: 64px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
