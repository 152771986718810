@import "src/styles/_colors.scss";

.button {
  &--small {
    min-width: 70px;
    display: inline-block;

    p {
      padding: 8px 12px;
      margin: 0;
      font-family: "Sans", Arial, sans-serif;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0;
      text-transform: uppercase;
    }
  }

  &--big {
    min-width: 100px;
    display: inline-block;

    p {
      padding: 12px 24px;
      margin: 0;
      color: $black;
      font-family: "Sans", Arial, sans-serif;
      color: #000000;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0;
      text-transform: uppercase;
    }
  }

  &--yellow {
    background-color: $brand-color-yellow;
    border: 2px solid $brand-color-yellow;

    p {
      color: $black;
    }

    &:hover {
      background-color: #ffffff;
      cursor: pointer;

      p {
        color: $brand-color-yellow;
      }
    }
  }
  &--yellow--inactive{
    cursor: not-allowed;
    color: $black;
    background-color: $brand-color-yellow;
    border: 2px solid $brand-color-yellow;
    max-height: 47px;

    &:hover {
      color: $black;
      background-color: $brand-color-yellow;
    }
  }

  &--green {
    background-color: $brand-color-green;
    border: 2px solid $brand-color-green;

    p {
      color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
      cursor: pointer;

      p {
        color: $brand-color-green;
      }
    }
  }

  &--earth {
    background-color: $black;
    border: 2px solid $black;
    color: #fff;
    &:hover {
      color: $black;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  &--underline {
    background-color: transparent;
    p {
      text-transform: none;
      text-decoration: underline;
      color: $brand-color-green;
      padding: 0;
    }

    &:hover {
      cursor: pointer;

      p {
        color: $brand-color-green;
      }
    }
  }

  &--red {
    background-color: transparent;
    border: 2px solid $side-color-red;


    p {
      color: $side-color-red;
    }

    &:hover {
      background-color: #ffffff;
      cursor: pointer;
    }
  }

  &--inactive {
    background-color: $side-color-darkgrey;
    border: 2px solid $side-color-darkgrey;

    p {
      color: #ffffff;
    }

    :hover {
      cursor: pointer;
    }
  }

  &--disabled {
    background-color: $side-color-darkgrey;
    border: 2px solid $side-color-darkgrey;
    pointer-events: none;

    p {
      color: #ffffff;
    }
  }
}

.bttn {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  //   padding: map-get($space, xs) map-get($space, l);
  min-width: 160px;
  font-size: 14px;
  line-height: 16px;
  min-height: 32px;
  padding: 6px;
  text-decoration: none;
  text-align: center;
  position: relative;
  outline: 0;
  //   border-radius: 2px;
  border: 2px solid currentColor;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  //   background-color: color-var(bg, 3);
  &--yellow {
    color: $black;
    background-color: $brand-color-yellow;
    border: 2px solid $brand-color-yellow;
    &:hover {
      color: $brand-color-yellow;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  &--yellow--inactive{
    cursor: not-allowed;
    color: $black;
    background-color: $brand-color-yellow;
    border: 2px solid $brand-color-yellow;
    max-height: 47px;

    &:hover {
      color: $black;
      background-color: $brand-color-yellow;
    }
  }
  &--ghost{
    opacity: .8;
    cursor: not-allowed !important;

      p {
        color: #ffffff;
      }

      :hover {
        cursor: not-allowed;
    }
  }
  &--green {
    background-color: $brand-color-green;
    border: 2px solid $brand-color-green;
    color: #fff;
    &:hover {
      color: $brand-color-green;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  &--earth {
    background-color: $black;
    border: 2px solid $black;
    color: #fff;
    &:hover {
      color: $black;
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
  &--grey {
    background-color: #EAE9EB;
    border: 2px solid #EAE9EB;
    color: $black;
    &:hover {
      //color: $black;
      //background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  &--abs {
    position: absolute;
    &-bttm {
      bottom: 30px;
    }
  }
  &--small {
    min-width: 70px;
    display: inline-block;
    width: 170px;
    &-2 {
      padding: 8px 12px;
      margin: 0;
      width: auto;
      font-size: 12px;
      min-width: 1px;
    }
  }
  &--higher {
    padding: 12px;
    padding-bottom: 9px;
    font-size: 16px;
    line-height: 19px;
  }
  &--center {
    display: block;
    margin-right: auto;
    margin-left: auto;
    &.bttn {
      &--abs {
        left: 0;
        right: 0;
      }
    }
  }
  &--link {
    display: block;
    color: currentColor;
    text-transform: uppercase;
    min-height: 1px;
    &:hover {
      color: currentColor;
    }
  }
  &--big {
    padding: 12px 24px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
}
