@import "src/styles/_colors.scss";

.profil-menu-container {
  position: absolute;
  z-index: 200;
  width: max-content;
  min-width: 170px;
  right: 0;
  top: 4rem;
  z-index: 0;
  @media screen and (max-width: 991px) {
    right: -20px !important;
  }

  .menu-decoration {
    display: none !important;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-left: 1px solid $side-color-darkgrey;
    border-top: 1px solid $side-color-darkgrey;
    margin-bottom: -30px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(45deg);
    z-index: 10;
  }

  .profil-menu-list {
    border: 1px solid $side-color-darkgrey;
    color: $side-color-darkgrey;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    padding: 8px;
    width: 100%;

    a {
      color: #000000;
      display: inline-block;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a:hover,
    a:active {
      color: $brand-color-green;
      text-decoration-color: $brand-color-green;
    }

    li {
      list-style-type: none;
      margin-top: 8px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      a > span {
        flex: 1;
      }

      .arrowIcon {
        // position: absolute;
        // right: 0;
        width: 32px;
        transform: rotate(-90deg);
      }
    }
    li:nth-of-type(2) {
      border-bottom: 1px solid $side-color-darkgrey;
      border-top: 1px solid $side-color-darkgrey;
      padding-top: 12px;
      padding-bottom: 8px;
    }

    li:nth-of-type(3) {
      padding-top: 8px;
      color: $side-color-red;
    }
  }
}
