.cft-progress-circle {
  position: relative;
  width: 70px;
  height: 70px;
  .cft-progress-circle__progress {
    transform: rotate(-90deg);
    width: 70px;
    height: 70px;
  }
  .cft-progress-circle__progress__value {
    transition: stroke-dashoffset 0.5s ease-in-out;
  }
  .cft-progress-circle__value {
    display: flex;
    position: absolute;
    left: 0;
    top: 29px;
    height: 1rem;
    width: 100%;
    font-weight: 600;
    justify-content: center;
    align-items: center;
  }
}
