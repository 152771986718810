@import "src/styles/_colors.scss";
@import "src/styles/_breakpoints.scss";

.c-poll-slider {
  background-color: #ececec;
  padding: 19px 0;

  .typography--h1 {
    color: $brand-color-green;
  }
  .articles-slider {
    width: 100%;
    // width: 90%;
  }

  &__wrap {
    @include respond-above(md) {
      margin: 0 -40px;
    }
    @include respond-below(md) {
      margin: 0 -15px;
    }
  }

  &__slide {
    background-color: #ffffff;
    min-height: 660px;
    display: flex;
    flex-direction: column;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: 660px;
    }
    .c-poll {
      flex: 1 1 auto;
      background-color: transparent;
      border: none;
    }
  }

  &__more {
    padding: 12px 0;
    margin: 0 12px;
    min-height: 81px;
    border-top: 2px solid #ececec;
    flex-shrink: 0;
    p {
      margin-bottom: 5px;
    }
    a {
      display: block;
      font-size: 16px;
      line-height: 16px;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: $brand-color-green;
      }
    }
  }

  .slider {
    padding: 10px 40px 0 !important;
    @include respond-below(md) {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }
    &-frame {
    }
    &-control {
      &-centerleft {
        .buttonAdditional--yellow {
          transform: rotate(90deg);
          cursor: pointer;
        }
      }
      &-centerright {
        .buttonAdditional--yellow {
          transform: rotate(-90deg);
          cursor: pointer;
        }
      }
    }
  }
}
