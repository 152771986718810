.create-herd-field {
  &__custom-option {
    padding: 16px;
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      transition: all 0.3s;
      background-color: rgba(0, 150, 94, 0.2);
      cursor: pointer;
    }

    span {
      display: inline-flex;
      min-width: 24px;
      min-height: 24px;
      border-radius: 3px;
      border: 1px solid #d6d6d6;
      margin-right: 16px;
      position: relative;

      &.is-active {
        background-color: #00965e;

        &::after {
          content: "";
          position: absolute;
          left: 7px;
          top: 4px;
          width: 7px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}
