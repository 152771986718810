@import 'src/styles/_colors.scss';

.calendar-weather {
    height: 500px;
    width: 100%;
    max-width: 580px;
    background-color: #ffffff;
    margin-left: 10%;

    .weather-adjust {
        height: 150px;
        width: 100%;
        background-color: $brand-color-green;
        padding: 24px 32px;
 
        .menu-contition-adjust {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: 60%;
            
            p {
                color: #FFFFFF;	
                font-family: "SansCond", Arial, sans-serif;
                font-size: 28px;	
                font-weight: bold;	
                line-height: 32px;
                width: 75%;
            }

            .adjustIcon {
                background-color: $brand-color-yellow;
                width: 25%;
                min-width: 100px;
                display: flex;
                flex-direction: row;
                align-items: center;

                span { 
                    margin-top: 2px;
                    margin-left: 10px;
                    font-size: 13px;
                }
            }
        }

        .buttons-date-adjust {
            .button--yellow, .button--inactive {
                margin-right: 8px;
                width: 96px;
            }
        }
    }

    .weather-description {
        padding: 30px 10px 10px 20px;
        height: 150px;
        
        p:first-of-type {
            margin: 0;
            margin-bottom: 5px;
            color: $black;	
            font-family: "SansCond", Arial, sans-serif;	
            font-size: 40px;	
            font-weight: bold;	
            line-height: 40px;
        }

        p:nth-of-type(2) {
            margin: 0;
            margin-bottom: 5px;
            color: $black;	
            font-family: "SansCond", Arial, sans-serif;	
            font-size: 24px;	
            line-height: 16px;
        }

        p:nth-of-type(3){
            margin: 0;
            margin-top: 25px;
            color: $black;	
            font-family: "Sans", Arial,sans-serif;	
            font-size: 13px;	
            font-weight: bold;
        }

        p:nth-of-type(4){
            color: $black;	
            font-family: "Sans", Arial,sans-serif;	
            font-size: 13px;	
            line-height: 16px;
        }
    }

    .weather-icons {
        padding: 20px 10px 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-items: center;
        height: 200px;

        .weather-icon {
            width: 15%;
            display: flex;
            flex-direction: column;
            align-content: space-around;
            align-items: flex-start;
            
            :first-child {
                color: $black;	
                font-family: "SansCond", Arial, sans-serif;	
                font-size: 23px;	
                font-weight: bold;	
                line-height: 29px;	
                text-align: center;
            }

            :nth-child(2){
                margin: 24px 0;
            }

            :nth-child(3) {
                color: $black;	
                font-family: "SansCond", Arial, sans-serif;	
                font-size: 23px;
                line-height: 29px;	
                text-align: center;
            }
        }

        .weather-icon--current {
            width: 20%;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-content: space-around;
            align-items: flex-start;

            :first-child {
                color: $brand-color-green;	
            }

            :nth-child(2){
                margin: 24px 0px 40px 0px;
                display: block;
                transform: scale(2);
            }

            :nth-child(3) {
                color: $brand-color-green;	
                font-family: "SansCond", Arial, sans-serif;	
                font-size: 64px;
                line-height: 29px;	
                text-align: center;
            }
        }   
    }
}