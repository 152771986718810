.cft-clear-form {
  width: 100%;
  max-width: 570px;
  min-width: 570px;
  padding-top: 2rem;
  margin: 0 auto;
  @media (max-width: 767px) {
    min-width: 90vw;
  }
  button {
    border: none;
    text-align: right;
    text-decoration-line: underline;
    color: #828385;
    font-weight: 400;
    font-size: 13px;
    background-color: transparent;
  }
}
