.VideoCover {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &__cover {
    position: relative;
    width: 70px;
    height: 40px;
    background-size: cover;
    margin-right: 16px;

    svg {
      height: 17px;
      width: 23px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -13.5px;
      margin-top: -11.5px;

      path:nth-of-type(2) {
        fill: rgba(59, 55, 56, 0.9);
      }
    }
  }

  &__title {
    width: calc(100% - 86px);

    h3 {
      color: #373a3c;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      margin: 0;
    }
  }

  &__preview {
    width: 100%;
    height: auto;
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }

  &.VideoCover--isBig {
    display: block;

    .VideoCover__cover {
      width: 100%;
      height: auto;
      padding-top: 56.25%;
      position: relative;
      background-size: cover;

      svg {
        height: 34px;
        width: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -23px;
        margin-top: -17px;
        path:nth-of-type(2) {
          fill: rgba(59, 55, 56, 0.9);
        }
      }
    }

    .VideoCover__title {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 1rem;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.75) 65%,
        rgba(0, 0, 0, 1) 100%
      );

      h3 {
        color: #ffffff;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        text-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__modal {
    position: relative;
    padding: 5rem 1rem 3rem 1rem;
    max-width: 90%;
    margin: 0 auto;

    @media (min-width: 992px) {
      padding: 5rem 5rem 3rem 5rem;
    }

    &__close {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: 0;

      svg {
        height: 78px;
        width: 78px;
        path {
          fill: white;
        }
      }

      &:focus {
        outline: none;
      }
      &:hover {
        svg {
          path {
            fill: #00965e;
          }
        }
      }
    }

    h3 {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 1rem 0;
    }

    p {
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }
  }

  &:hover {
    .VideoCover__cover {
      svg {
        path:nth-of-type(2) {
          fill: #00965e;
        }
      }
    }

    .VideoCover__title {
      h3 {
        color: #00965e;
      }
    }
  }
}

.VideoCover__modal-container {
  max-width: 90% !important;
  @media (min-width: 992px) {
    max-width: 920px !important;
    position: relative;
    top: 20vh;
  }
}
