.PageHeader {
  background-color: rgba($color: #000000, $alpha: 0.5);
  padding: 1rem;
  min-height: 260px;
  color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.PageHeader--black {
    color: #000000;
    .breadcrumbs {
      a {
        color: rgba($color: #000, $alpha: 0.6);
        &:hover {
          color: rgba($color: #000, $alpha: 1);
        }
      }
    }
  }

  .breadcrumbs {
    a {
      color: rgba($color: #ffffff, $alpha: 0.6);
      &:hover {
        color: rgba($color: #ffffff, $alpha: 1);
      }
    }
  }

  h1 {
    font-family: "SansCond", Arial, sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 53px;
    margin-bottom: 13px;
    text-transform: uppercase;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    background-position: 90% center;
  }
}
