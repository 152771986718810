.expert {
  &-space {
    margin-bottom: 30px;
  }
  &-card {
    background: #fff;
    padding: 26px 21px;
    min-height: 390px;
    display: flex;
    flex-direction: column;
    border: 2px solid #e9e9e9;
    height: 100%;
    &--short {
      min-height: 390px;
      height: auto;
      max-height: 460px;
    }
    &__top {
      flex: 1;
    }
    &__img {
      display: block;
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: 0;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
      background-size: cover;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__p {
      font-size: 14px;
      color: #2e3033;
      line-height: 19px;
      font-weight: 300;
      &--bigger {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    &__name {
      font-family: "Sans", Arial,sans-serif;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 8px;
    }
    &__links {
      flex: 0 0 48px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &--full {
        .bttn {
          width: 100% !important;
          margin-right: 0 !important;
        }
      }
      .bttn {
        min-width: 100px;
        width: 150px;
        max-width: 100%;
        &:nth-child(1) {
          margin-right: 7px;
        }
        &:nth-child(2) {
          margin-left: 7px;
        }
      }
    }
  }
}
