input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 0;
  background-color: #00965e;
  color: white;
  text-transform: uppercase;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0;
  min-width: 137px;
  text-align: center;
}

.file-input {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  &-body {
    flex: 1;
  }
  &-preview {
    flex: 0 0 100px;
    height: 100px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-warning {
    color: #808080;
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 16px;
    margin-top: 11px;
  }
  &-heading {
    font-size: 24px;
    line-height: 26px;
    color: #2e3033;
    // margin-top: 46px;
    margin-bottom: 30px;
  }
}
