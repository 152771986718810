@import "src/styles/_colors.scss";

%basic-text-style {
  font-family: "Sans", Arial,sans-serif;
  margin: 5px;
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 16px;
}

.buttonAdditional {
  height: 16px;
  display: inline-block;

  &--green {
    color: $brand-color-green;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      @extend %basic-text-style;
    }

    .arrowIcon--selected {
      transform: scaleY(-1);
      margin-bottom: 5px;
    }

    :hover {
      cursor: pointer;
    }
    svg {
      polygon {
        fill: #00965e;
      }
    }
  }

  &--grey {
    color: $side-color-darkgrey;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    min-width: 250px;
    text-align: center;

    p {
      @extend %basic-text-style;
    }

    .arrowIcon--selected {
      transform: scaleY(-1);
      margin-bottom: 5px;
    }

    :hover {
      cursor: pointer;
    }
  }
}

.events-calendar,
.events-calendar-container,
.events-block {
  .buttonAdditional {
    &--green,
    &--grey {
      display: flex;
      justify-content: center;
      align-self: center;
      width: auto;
      margin-right: auto;
      margin-left: auto;
      max-width: 300px;
      margin-top: 10px;
      margin-bottom: 10px;
      //   margin-top: -10px;
      //   height: 0;
      p {
        margin-bottom: 0;
      }
      div {
        margin-bottom: 0;
      }
    }
  }
}
