@import "src/styles/_colors.scss";

.request-access {
  &__title {
    color: #00965e;
    align-self: center;
    display: block;
  }

  &__input-title {
    font-family: "Sans", Arial,sans-serif;
    font-weight: 700;
    color: $black;
  }

  &__text-info {
    color: $black;
    font-size: 14px;
    font-family: Sans;
    text-align: center;
  }

  &__file-wrapper {
    padding-bottom: 50px;
  }

  .contact-cards-row {
    > * {
      margin-bottom: 30px;
    }
  }

  background-color: #ececec;
  padding-top: 60px;
  padding-bottom: 60px;

  .input {
    height: auto;
  }

  &__submit-button {
    background: #ffa203;
    cursor: pointer;
    width: 178px;
    height: 45px;
    border: 0;
    text-transform: uppercase;

    font-family: Sans;
    font-size: 14px;
    color: #2e3033;
    letter-spacing: 0;
    margin-top: 23px;
    border: 2px solid #ffa203;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      background: white;
    }
  }

  &__checkbox-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
    &__link {
      color: $brand-color-green;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
        color: $brand-color-green;
      }
    }
  }

  &__checkbox-text {
    margin: 0;
    font-family: Sans;
    font-size: 13px;
    color: #2e3033;
    letter-spacing: -0.08px;
  }

  &__captcha-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    transform: scale(0.9);
    display: flex;
    justify-content: center;

    @media (min-width: 576px) {
      margin-top: 15px;
      transform: scale(1);
      justify-content: flex-start;
    }
  }

  &__input-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__input-group {
    padding: 15px 0;
  }

  &__centered {
    &--full-width {
      width: 100%;
    }
    &--horizontal {
      justify-content: center;
      display: flex;
    }
    &--vertical {
      align-items: center;
      display: flex;
    }
  }

  &__typography--h1 {
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 40px;
    color: #00965e;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 40px;
    margin-bottom: 38.5px;
  }

  &__title {
    font-family: Sans;
    font-size: 16px;
    color: #2e3033;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__marketing-consents {
    font-family: "Sans", Arial,sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #2e3033;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 23px;
    margin-bottom: 19px;
  }

  &__file-status {
    margin-left: 12px;
  }

  &__text-bold {
    font-family: "Sans", Arial,sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #2e3033;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  &__text-thin {
    font-family: "Sans", Arial,sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #2e3033;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.contact-section {
  &__typography--h1 {
    text-transform: uppercase;
    color: #00965e;
    display: inline-block;
    font-family: "SansCond", Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 70px;
  }
}

.contact-card {
  padding: 16px;
  border: 1px solid #979797;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__button--big {
    width: fit-content;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00965e;
    color: white;
    text-transform: uppercase;
    padding: 10px;
    font-family: Sans;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    cursor: pointer;
    border: 2px solid #00965e;

    &:hover {
      background: white;
      color: #00965e;
      border: 2px solid #00965e;
      // border: 2px solid #00965e;
    }

    > p {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &__info-wrapper {
    &__title {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 16px;
      color: #2e3033;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0;
      margin-bottom: 39px;
    }

    &__subtitle {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: #2e3033;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0;
      line-height: normal;
      margin-bottom: 21px;
    }

    &__description {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: #2e3033;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0;

      &__paragraph {
        margin-bottom: 18px;
      }

      &__email {
        font-family: "Sans", Arial,sans-serif;
        font-weight: 300;
        font-size: 14px;
        color: #00965e;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}
