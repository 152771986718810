.cft-inputs {
  border: 2px solid green;
  background-color: #fff;
  padding: .6rem .8rem;
  min-width: 23.2rem;
  font-weight: 300;
  line-height: 1.4rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &.cft-inputs--short {
    min-width: 11rem;
    display: flex;
    flex-direction: row;
  }

  &.cft-inputs--center {
    text-align: center;
  }

  &.cft-inputs--isActive {
    transition: all 0.3s;
    border-color: #00965e;
    //background-color: #00965e;
    //color: #fff;
  }
}

.cft-inputs--label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: .3rem;
}

.cft-inputs--right-icon {
  position: absolute;
  top: 20%;
  &.unit{
    background-color: #D7D7D7;
    border-radius: 15%;
    min-width: 1.5rem;
    color:#606060;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 30px;
    height:30px;
  }
  .cft-inputs--right-icon-active{
    cursor: pointer;
    color:#FFFFFF;
    background-color: #00965E;
  }
  &:hover, &:active{
    cursor: pointer;
    color:#FFFFFF;
    background-color: #00965E;
  }

}

.cft-inputs--left-icon {
  position: absolute;
  left: 5%;
  top: 25%;
}

.cft-inputs--container {
  //position: relative;
  //width: 100%;
  //height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //height: 2rem;
  //z-index: 1000;
}

.cft-inputs--icon--container{
  position: relative;
}
