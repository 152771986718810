.error-page-wrapper {
  display: flex;
  align-items: center;
  background: url("../../../assets/img/bg-404.jpg") center center/cover
    no-repeat;
  min-height: 940px;
  padding-bottom: 100px;

  p {
    margin: 1rem auto 2rem;
    max-width: 250px;
    line-height: 2;
  }
  h1 {
    max-width: 250px;
    // margin-left: auto;
    // margin-right: auto;
  }
}
