.real-estate-video {
  .real-estate-video__playback {
    margin-top: 1rem;
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
