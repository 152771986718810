.cft-info-box {
  display: flex;
  background-color: #cceadf;
  border-left: 2px solid #00965e;
  align-items: center;
  p {
    color: #00965e;
    text-align: center;
    font-size: 0.95rem;
    font-weight: bold;
    margin: 0 auto;
    padding: 1rem;
    white-space: pre;
  }

  .cft-info-box__icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #00965e;
    color: #fff;
    font-size: 0.76rem;
    font-family: Arial, Helvetica, sans-serif;
    margin: 1rem 1.5rem;
  }
}
