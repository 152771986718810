.cft-warning-box {
  background: #feebcd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: #f59a00;
  font-size: 12px;
  margin: 16px auto;
  text-align: center;

  svg {
    margin-bottom: 8px;
  }
}
