.articles-categories {
  background-color: #e9e9e9;
  padding: 8px 12px;

  h3 {
    color: #2e3033;
    font-family: "SansCond", Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 40px;
  }

  &.articles-categories--small {
    a {
      font-size: 11px;
    }
  }

  a {
    text-transform: uppercase;
    font-family: "Sans";
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    color: white;
    background-color: #808080;
    padding: 6px;
    margin-right: 6px;
    margin-bottom: 0.5rem;
    font-weight: 300;
    border-radius: 4px;

    &.active {
      background-color: #00965e;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &--top-bar {
    @media (max-width: 767px) {
      overflow-x: scroll;
      padding: 1rem 0;
    }
    background-color: white;
    padding: 31px 0;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;

    a {
      background-color: transparent;
      color: #000000;
      font-size: 14px;
      padding: 0;
      margin: auto;
      font-weight: 400;

      @media (max-width: 767px) {
        padding: 0 15px;
        white-space: nowrap;
      }
      &.category-69,
      &.category-70,
      &.category-71,
      &.category-68 {
        &.active {
          color: #e92b5c;
        }
      }

      &.active {
        background-color: white;
        color: #00965e;
      }
    }
  }
}

.articles-subcategories {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 16px;
  &-el {
    color: #00965E;
    text-decoration: underline;
    margin-right: 16px;
    padding: 8px 0;
    font-weight: bold;
    cursor: pointer;
    &.is-active {
      color: #2E3033;
    }
  }
}
