@import "src/styles/_colors.scss";

$checkmark-width: 27.538px;
$checkmark-original-width: 64px;
$checkmark-offset: calc(
  11px + calc(#{$checkmark-original-width} - #{$checkmark-width}) / 2
);

.static {
  padding-top: 44px;
  margin: 0 auto;

  .breadcrumbs {
    margin-bottom: 1rem;
  }

  .typography--h1 {
    color: $brand-color-green;
    margin-bottom: 50px;
    text-transform: uppercase;
  }

  &__paragraph {
    color: #2e3033;
    letter-spacing: 0;
    margin: 0;

    &--bigger {
      font-family: "Sans", Arial,sans-serif;
      font-weight: 700;
      line-height: 34px;
      font-size: 24px;
    }

    &--big {
      font-family: "Sans", Arial,sans-serif;
      line-height: 28px;
      font-size: 16px;
    }

    &--first {
      margin-bottom: 30px;
    }
    &--second {
      margin-bottom: 60px;
    }
    a {
      color: $brand-color-yellow;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #000;
      }
    }
  }

  &__img {
    object-fit: contain;
    max-width: 320px;
    max-height: 100%;
    width: auto;
    height: auto;

    @media (min-width: 992px) {
      max-width: 100%;
    }
  }
}

.centered {
  &--horizontal {
    justify-content: center;
    display: flex;
  }
  &--vertical {
    align-items: center;
    display: flex;
  }
}
