.cft-cost-structure {
  .cft-report__title {
    padding: 0 0.75rem;
    margin-bottom: 2rem;
  }
  .cft-cost-structure__breakdown {
    padding: 0 0.75rem;
    h4 {
      font-size: 0.688rem;
      text-transform: uppercase;
      font-weight: bold;
    }

    .cft-progress {
      margin-bottom: 1.5rem;
    }
  }
}

.cft-cost-structure-table {
  @media (max-width: 767px) {
    min-width: 720px;
  }
  .cft-report__title {
    padding: 2.5rem;
  }

  .cft-card {
    padding-bottom: 2.5rem;
  }

  .cft-button {
    margin: 2.5rem 2.5rem 0 2.5rem;
    @media (max-width: 767px) {
      width: auto;
    }
  }

  .cft-text-field {
    max-width: 127px;
  }

  .cft-cost-structure-table__action {
    display: flex;
    align-items: center;
    margin-left: -1.5rem;
    & > svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      cursor: pointer;
      &:hover {
        transition: 0.6s opacity;
        opacity: 0.6;
      }
    }
  }
}

.cft-unit-switch {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 44px;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      .label {
        color: white;
        &.label--tonne {
          color: #606060;
        }
      }
      &::before {
        transform: translateX(100px);
      }
    }
  }

  .label {
    transition: 0.4s;
    position: absolute;
    display: flex;
    z-index: 10;
    color: #606060;
    width: 100px;
    text-align: center;
    left: 100px;
    height: 44px;
    justify-content: center;
    align-items: center;
    top: 0;

    &.label--tonne {
      left: 0;
      color: white;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d7d7d7;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 4px;

    &:before {
      position: absolute;
      content: "";
      height: 44px;
      width: 100px;
      left: 0;
      bottom: 0;
      background-color: #00965e;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 4px;
    }
  }

  &.cft-unit-switch--isDisabled {
    opacity: 0.6;
    .slider {
      cursor: not-allowed;
    }
  }
}
.cft-text-field__input__number {
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
}
