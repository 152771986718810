.real-estate__location-input {
  position: relative;
  width: 100%;
  width: 100%;
  font-size: 14px;
  border: solid 2px #00965e;
  background-color: #ffffff;
  min-height: 45px;
  cursor: pointer;
  display: flex;

  .estate-select {
    width: 100% !important;
  }
  
  @media (max-width: 767px) {
    .estate-select {
      margin-top: 2rem;
      
    }

    .real-estate-select__menu-list {
      max-height: calc(100vh - 100px);
    }
  }

  .real-estate__location-custom-input {
    display: block;
    width: 100%;
    border-bottom: solid 2px #979797;
    margin-bottom: 8px;
    input {
      font-weight: 300;
    }
  }

  .real-estate__location-icon {
    display: inline-flex;
    align-items: center;
    padding: 0 14px 0 21px;
    svg {
      height: 20.7px;
      width: auto;
      path {
        fill: #2e3033;
      }
    }
  }

  .real-estate__location-wrapper {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .real-estate__location-selected {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2e3033;
    font-weight: 300;
    padding-top: 12px;
  }

  .real-estate__location-placeholder {
    color: hsl(0, 0%, 50%);
  }
}

.real-estate__location-input__content {
  border: solid 1px #979797;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  background-color: white;

  .estate-select .real-estate-select__menu {
    position: relative;
    box-shadow: none;
    margin: 0;
  }

  .real-estate-select__dropdown-indicator {
    display: none;
  }

  .estate-select .real-estate-select__control {
    border: none;
    box-shadow: none;
  }

  .real-estate-select__indicators {
    position: absolute;
    right: 0;
    top: 1rem;
  }

  .real-estate__location-input__close {
    display: none;
    background-color: #00965e;
    float: right;
    svg {
      path {
        fill: white;
      }
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    height: 100vh;

    .real-estate__location-input__close {
      display: inline-block;
    }
  }
}

.real-estate-inputs {
  &__error {
    margin: 0 0 0.5rem 0;
    font-size: 12px;
    font-weight: 300;
    color: red;
    text-align: start;
  }
  &__text {
    position: relative;
    width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;

    & > input {
      width: 100%;
      border: none;
      padding: 15px 20px 10px 20px;
      font-size: 14px;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 300;
      border: solid 2px #00965e;
      background-color: #ffffff;
      &.real-estate-inputs__text--has_value {
        padding-right: 2.5rem;
      }
    }

    &__pre {
      margin-right: 12px;
    }

    & > textarea {
      width: 100%;
      border: none;
      padding: 15px 20px 10px 20px;
      font-size: 14px;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 300;
      border: solid 2px #00965e;
      background-color: #ffffff;
    }
  }

  &__button {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #2e3033;
    background-color: #ffa203;
    border: 2px solid #ffa203;
    padding: 11px 1.2rem 8px 1.2rem;

    &:hover {
      background-color: #ffffff;
    }
  }

  &__clear {
    position: absolute;
    right: 8px;
    cursor: pointer;
    svg {
      path {
        fill: #2e3033;
      }
    }
  }
}

.estate-select {
  .real-estate-select {
    &__format-group-label {
      &__count {
        background-color: #ffa203;
        color: #212529;
        display: inline-block;
        padding: 4px 8px 2px 8px;
        border-radius: 1rem;
        font-size: 10px;
        font-weight: 300;
        margin-left: 8px;
      }
    }
    &__control {
      border: solid 2px #00965e;
      background-color: #ffffff;
      border-radius: 0;
      padding: 6px 0 3px 0;
    }

    &__value-container {
      padding: 0 20px 0 20px;
    }

    &__indicator-separator {
      display: none;
    }
    &__indicator {
      padding: 0 8px;
      svg {
        width: 32px;
        height: 32px;
        fill: #2e3033;
      }
    }

    &__option {
      color: #212529;
      &--is-selected {
        color: white;
        background-color: #2684ff;
      }
    }

    &__placeholder {
      font-size: 14px;
      font-family: "Sans", Arial, sans-serif;
      font-weight: 300;
    }

    &__menu {
      border-radius: 0;
    }

    &__multi-value__label {
      font-size: 14px;
      padding: 0;
    }

    &__multi-value {
      background-color: white;
      align-items: center;
      font-weight: 300;
      font-size: 14px;

      &__remove {
        svg {
          path {
            fill: black;
          }
        }
      }
    }
  }
}

.real-estate-inputs__checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.08px;
  text-align: left;
  color: #2e3033;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.real-estate-inputs__checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.real-estate-inputs__checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: white;
  border: solid 2px #00965e;
  margin-top: -3px;
}

.real-estate-inputs__checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.real-estate-inputs__checkbox
  input:checked
  ~ .real-estate-inputs__checkbox__checkmark:after {
  display: block;
}

.real-estate-inputs__checkbox .real-estate-inputs__checkbox__checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 11px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.estate-select__custom_option {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 13px;
  margin: 1rem;
  &--is-selected {
    .checkmark_option {
      &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 1px;
        width: 5px;
        height: 11px;
        border: solid black;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .checkmark_option {
    width: 20px;
    display: inline-block;
    height: 20px;
    border-radius: 2px;
    border: 2px solid #00965e;
    position: relative;
    margin-right: 1rem;
  }
}
.estate-select__custom_option--boroughs_list,
.estate-select__custom_option--towns_list,
.estate-select__custom_option--provinces_list {
  text-transform: capitalize;
}
