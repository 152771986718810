.m-login-form {
  max-width: 468px;
  margin: auto;

  .bttn {
    font-family: "Sans", Arial, sans-serif;
    background-color: #ffa203;
    font-size: 14px;
    color: black;
    font-weight: 300;
    text-transform: uppercase;
    border: 2px solid #ffa203;
    padding: 12px 20px 10px 20px;
    line-height: 14px;

    &:hover {
      background-color: white;
    }
  }

  .forgot-password-link {
    font-weight: 300;
    text-decoration: underline;
    margin-top: -0.5rem;
  }

  .logIn-description {
    padding: 32px 0 !important;
  }

  .user-register__modal {
    text-align: center;
    margin-top: 32px;

    a {
      color: #00965e;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    .user-logIn-page {
      padding-bottom: 18px;
    }
  }
}
